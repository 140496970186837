import React, { useState } from 'react'
import Modal from '../../components/Modal';
// import 'reactjs-popup/dist/index.css';
import { LiaToggleOnSolid } from "react-icons/lia";
import { LiaToggleOffSolid } from "react-icons/lia";
function OutletOnline() {
    const [outletdta, setOutletdta] = useState({
        toggle: false,
        isOpen: false
    })
    return (
        <div className='grid justify-items-center my-9'>
            <div className='w-[90%]'>
                <div >
                    <img src="/helpcenter_outlet.webp" className='' alt="" />
                </div>
                <p className='text-rose-600 text-lg px-6 my-3'>your outlet is currently off line Due to high number of rejections</p>
                <p className='text-[17px] px-6 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio veniam maxime delectus, ullam a, accusantium cumque voluptate, consectetur odit minus dolores optio dolorum. Eaque pariatur suscipit impedit at, officiis nulla.</p>
                <button className='m-5 bg-green text-white p-2 rounded' type='button' onClick={() => setOutletdta(prev => ({
                    ...prev,
                    isOpen: !outletdta.isOpen
                }))}>Turn On The Outlet</button>
                <Modal isOpen={outletdta.isOpen} width={500} close={true} onClose={() => setOutletdta(prev => ({
                    ...prev,
                    isOpen: !outletdta.isOpen
                }))} >
                    <p className='font-semibold'>Manage Outlet Status</p>
                    <div className='flex justify-between mt-2'>
                        <p className='font-semibold'>outlet info</p>
                        <p className='font-semibold'>delivary</p>
                    </div>
                    <div className='flex  justify-between mt-4'>
                        <div className='flex'>
                            <div><img src="https://www.indianhealthyrecipes.com/wp-content/uploads/2020/12/poori-puri-recipe-500x500.webp" width={100} height={100} className='rounded' alt="" /></div>
                            <div className='px-2 space-y-2 text-gray-500'>
                                <p>Happy Bytes</p>
                                <p>id:123456 | Alagapuram Pudur</p>
                            </div>
                        </div>
                        <div className='block' >
                            <button type='button' onClick={() => setOutletdta(prev => ({
                                ...prev,
                                toggle: !outletdta.toggle
                            }))} className='text-red rounded-xl'>{outletdta.toggle ? <LiaToggleOnSolid size={30} /> : <LiaToggleOffSolid size={30} className='' />}</button>
                            <p className='text-blue-600'>view details</p>
                        </div>

                    </div>
                </Modal>

            </div>
        </div>
    )
}

export default OutletOnline