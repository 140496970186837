import React, { useState, useEffect, useRef } from 'react';
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Logoimg from '../assets/logo1.webp';
import { refreshTokenAPI, verifyOtp } from '../API/Api'; // Ensure this path is correct

const OTPVerification = ({ onVerify }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(30);
  const [errorMessage, setErrorMessage] = useState("");
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleOtpChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== '' && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);

      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  
  const refreshTimeRef = useRef(null);
  const hasRefreshedToken = useRef(false);

  const handleVerifyOtp = async () => {
    const otpString = otp.join('');
    if (otpString.length === 6) {
      try {
        const phoneNumber = sessionStorage.getItem("phoneNumber");
        console.log("PhoneNumber", phoneNumber);
        console.log("Sending OTP Verification Request with Payload:", {
          phoneNumber,
          otp: otpString,
          deviceType: 'React_Onboarding_Merchant',
          appVersion: '1.0.1'
        });

        const response = await verifyOtp(phoneNumber, otpString);
        console.log('OTP Verified:', response);

        const { accessToken, refreshToken, accessTokenExpiry, restaurant_Id } = response;

        // Store tokens and restaurant ID in sessionStorage
        sessionStorage.setItem("accessToken", accessToken);
        sessionStorage.setItem("refreshToken", refreshToken);
        sessionStorage.setItem("restaurant_Id", restaurant_Id);

        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("restaurantId", response.restaurants[0].restaurantId);
        localStorage.setItem("userId", response.user.id);

        // Extract expiry time and calculate the refresh time only once
        const expiryTime = new Date(accessTokenExpiry);
        const refreshTime = new Date(expiryTime.getTime() - 60 * 1000); // One minute before expiry
        refreshTimeRef.current = refreshTime;
        console.log(`Token Expiry Time: ${expiryTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`);
        console.log(`Expected Refresh Time: ${refreshTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`);

        onVerify(response.accessToken);

        // Set up token refresh
        const checkRefreshTime = async () => {
          const currentTime = new Date();

          if (currentTime >= refreshTimeRef.current && !hasRefreshedToken.current) {
            hasRefreshedToken.current = true; // Ensure refresh only happens once
            console.log('Triggering Refresh Token API');
            try {
              const refreshResponse = await refreshTokenAPI(refreshToken);
              console.log('Token Refreshed:', refreshResponse);

              // Update the access token and refresh token in sessionStorage
              localStorage.setItem("accessToken", refreshResponse.accessToken);
              sessionStorage.setItem("refreshToken", refreshResponse.refreshToken);

              // Clear the interval after refreshing the token
              clearInterval(refreshTokenInterval);
            } catch (error) {
              console.error("Error refreshing token:", error);
              // handleError(error);
              navigate('/login'); // Redirect to login on error
            }
          }
        };

        // Set up an interval to check the refresh time every minute
        const refreshTokenInterval = setInterval(checkRefreshTime, 60 * 1000);

        // Close OTP modal
        // onClose();

      } catch (error) {
        console.error("Error in OTP Verification:", error);
        // handleError(error);
      }
    } else {
      alert('Please enter the full 6-digit OTP');
    }
  };

  const [refreshTokenInterval, setRefreshTokenInterval] = useState(null);

  useEffect(() => {
    return () => {
      if (refreshTokenInterval) {
        clearInterval(refreshTokenInterval);
      }
    };
  }, [refreshTokenInterval]);

  return (
    <>
      <div className=' px-6 py-6'>
        <img src={Logoimg} alt="logo1" />
      </div>

      <div className="min-h-96 flex flex-col items-center justify-center">
        <div className=" bg-gray-100 p-8 rounded shadow-md w-96 relative">
          <button className="absolute top-4 right-4 text-gray-500" onClick={() => navigate("/login")}>
            <IoClose size={24} />
          </button>
          <h2 className="text-center text-2xl font-semibold mb-4">OTP Verification</h2>
          <p className="text-center mb-4">Check text messages for your OTP</p>
          <div className="flex justify-center mb-4">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="w-12 h-12 m-1 text-center text-xl border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={(el) => inputRefs.current[index] = el}
              />
            ))}
          </div>
          <div className="text-center text-xl font-semibold mb-4">
            {timer > 0 ? `00:${timer.toString().padStart(2, "0")}` : "Time's up"}
          </div>
          <div className="text-center">
            {timer === 0 ? (
              <button className="text-blue-500" onClick={() => setTimer(30)}>
                Resend Now
              </button>
            ) : (
              <p className="text-gray-500">Not received OTP? Resend Now</p>
            )}
          </div>
          {errorMessage && <p className="text-red-500 text-center mt-2">{errorMessage}</p>}
          <button
            className="bg-green text-white py-2 rounded w-full mt-4"
            onClick={handleVerifyOtp}
          >
            Verify OTP
          </button>
        </div>
      </div>
    </>
  );
};

export default OTPVerification;
