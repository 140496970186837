import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./TrackOffer.css";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import Createoffer from "./Createoffer";
import { useNavigate } from "react-router-dom";

const TrackOffer = () => {
    //(1) Initailisation for createOffer and trackOffer
    const [createOffer, setCreateOffer] = useState(false);
    const [trackOffer, setTrackOffer] = useState(false);
    // To underline create offer
    function createOfferDetails() {
        setCreateOffer(true);
        setTrackOffer(false);
    }
    //To underline and display the tracking contents after clicking the track offers
    function trackOfferDetails() {
        setTrackOffer(true);
        setCreateOffer(false);
    }

    //(2)To dispay content based on button
    const [activeButton, setActiveButton] = useState("Active");
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    // (3)Normal offer
    const [percentage, setPercentage] = useState(50);
    const [price, setPrice] = useState(100);
    const [date, setDate] = useState(5);
    const [month, setMonth] = useState("May");
    const [year, setYear] = useState(2024);

    // (4) Offer full info
    const [sales, setSales] = useState("3,07,847");
    const [orders, setOrders] = useState(145);
    const [disPrice, setDisPrice] = useState("");
    const [offerVisible, setOfferVisible] = useState(true);
    const [perfomanceDay, setPerformanceDay] = useState("yesterday");
    const [minPrice, setMinPrice] = useState(149);
    const [hotel, setHotel] = useState("Happy Bites");
    const [area, setArea] = useState("Alagapuram Pudur");
    const [stopOfferBtn, setStopOfferBtn] = useState(false);

    // To display the offer details
    function offerDisplaySymbolBtn() {
        if (offerVisible) {
            setOfferVisible(false);
        } else {
            setOfferVisible(true);
        }
    }

    //To close the offer content
    function stopOfferPopup() {
        setStopOfferBtn(true);
    }

    // Close stop offer popup
    function closeOfferPopup() {
        setStopOfferBtn(false);
    }

    return (
        <div className="trackOffer">
            <div className="border-b-2 border-gray-300 shadow">
                <h3 className="heading text-xl font-bold p-3">Offers</h3>
            </div>
            <hr className="divider" />
            <div className="offer_headings flex items-center overflow-hidden">
                <p className={`heading font-semibold ms-10 me-14 mt-7 mb-2 ${createOffer && "createOffer_decoration"}`} onClick={createOfferDetails}>Create Offers</p>
                <p className={`heading font-semibold mt-7 mb-2 ${trackOffer && "track_decoration"}`} onClick={trackOfferDetails}>Track Offers</p>
            </div>
            <hr className="divider" />
            {trackOffer ? (
                <>
                    <div className="trackOffer_btns ms-10 mt-7">
                        <button className={`rounded-md px-14 py-2 mr-12 ${activeButton === "Active" && "active-btn"}`} onClick={() => handleButtonClick("Active")}>Active</button>
                        <button className={`rounded-md px-14 py-2 mr-12 ${activeButton === "Scheduled" && "active-btn"}`} onClick={() => handleButtonClick("Scheduled")}>Scheduled</button>
                        <button className={`rounded-md px-14 py-2 mr-12 ${activeButton === "InActive" && "active-btn"}`} onClick={() => handleButtonClick("InActive")}>InActive</button>
                        <button className={`rounded-md px-14 py-2 mr-12 ${activeButton === "All" && "active-btn"}`} onClick={() => handleButtonClick("All")}>
                            All
                        </button>
                    </div>
                    {trackOffer && activeButton === "Active" && (
                        <div className="normalOffer bg-white rounded-md flex justify-between mt-4 mx-10 p-4">
                            <div className="left text-black font-normal">
                                <p>
                                    {percentage}% OFF Upto ₹{price}
                                </p>
                                <p className="date mt-2">
                                    Start Date: {date}th {month} {year}
                                </p>
                            </div>
                            <div className="right font-bold">
                                <button className="new_user rounded-md text-white px-5 p-1.5 me-2">
                                    New Users Only
                                </button>
                                <button className="active rounded-md px-5 py-1.5">
                                    Active
                                </button>
                            </div>
                        </div>
                    )}

                    {/*Active Offer Details */}
                    {trackOffer && activeButton === "Active" && (
                        <div className="offerInfo bg-white rounded-md mx-10 mt-4">
                            <div className="offer_boxes flex justify-between mx-3 mt-2">
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹{sales}</p>
                                    <p className="font-normal count_head mt-1.5">Gross Sales</p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">{orders}</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Orders Delivered
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹1,23,567</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Discount Given
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">67%</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Effective Discount %
                                    </p>
                                </div>
                            </div>
                            {offerVisible && (
                                <div className="offerDetails" id="ds">
                                    <p className="mt-5 ms-5">
                                        Performance updated till {perfomanceDay}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer applicable for:New users on all menu items,excluding
                                        MRP items
                                    </p>
                                    <p className="mt-5 ms-5">Minimum order value:₹{minPrice}</p>
                                    <p className="mt-5 ms-5">
                                        Valid at: {hotel}, {area}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer sharing:100% of the discount value is being funded by
                                        you
                                    </p>
                                    <button
                                        className="rounded-md w-full text-center mt-5 ms-5 py-1"
                                        onClick={stopOfferPopup}
                                    >
                                        Stop Offer
                                    </button>
                                </div>
                            )}
                            <hr className="divider my-2 " />
                            <div className="flex items-center justify-center mb-2">
                                {offerVisible ? (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
                                    </button>
                                ) : (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    {/* Stop  */}

                    {stopOfferBtn && (
                        <div className="flex items-center justify-center fixed top-0 left-0 right-0 bottom-0 backdrop-blur-3xl ">
                            <div className="stopOffer bg-white rounded-md text-center">
                                <div className="px-6 pt-5">
                                    <FontAwesomeIcon
                                        icon={faExclamation}
                                        className="exclamation"
                                    ></FontAwesomeIcon>
                                    <h2 className="font-bold mt-2">
                                        Are you sure you want to stop this Offer?
                                    </h2>
                                    <p className="mt-2">
                                        You have earned ₹32,253 of revenue with 110 order from this
                                        offer. If you want to stop this offer, it will stop at 19th
                                        June, 2024.
                                    </p>
                                    <p className="mt-2 mb-3">
                                        We will send deactivation confirmation on your email and
                                        phone number
                                    </p>
                                </div>
                                <div className="mt-3">
                                    <hr className="divider" />
                                    <button className="text-sky-500 my-3">Yes, Stop</button>
                                    <hr className="divider" />
                                    <button className="my-3" onClick={closeOfferPopup}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {trackOffer && activeButton === "Scheduled" && (
                        <div className="normalOffer bg-white rounded-md flex justify-between mt-4 mx-10 p-4">
                            <div className="left text-black font-normal">
                                <p>
                                    {percentage}% OFF Upto ₹{price}
                                </p>
                                <p className="date mt-2">
                                    Start Date: {date}th {month} {year}
                                </p>
                            </div>
                            <div className="right font-bold">
                                <button className="new_user rounded-md text-white px-5 p-1.5 me-2">
                                    New Users Only
                                </button>
                                <button className="active rounded-md px-5 py-1.5">
                                    Active
                                </button>
                            </div>
                        </div>
                    )}

                    {trackOffer && activeButton === "Scheduled" && (
                        <div className="offerInfo bg-white rounded-md mx-10 mt-4">
                            <div className="offer_boxes flex justify-between mx-3 mt-2">
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹{sales}</p>
                                    <p className="font-normal count_head mt-1.5">Gross Sales</p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">{orders}</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Orders Delivered
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹1,23,567</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Discount Given
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">67%</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Effective Discount %
                                    </p>
                                </div>
                            </div>
                            {offerVisible && (
                                <div className="offerDetails" id="ds">
                                    <p className="mt-5 ms-5">
                                        Performance updated till {perfomanceDay}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer applicable for:New users on all menu items,excluding
                                        MRP items
                                    </p>
                                    <p className="mt-5 ms-5">Minimum order value:₹{minPrice}</p>
                                    <p className="mt-5 ms-5">
                                        Valid at: {hotel}, {area}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer sharing:100% of the discount value is being funded by
                                        you
                                    </p>
                                    <button
                                        className="rounded-md w-full text-center mt-5 ms-5 py-1"
                                        onClick={stopOfferPopup}
                                    >
                                        Stop Offer
                                    </button>
                                </div>
                            )}
                            <hr className="divider my-2 " />
                            <div className="flex items-center justify-center mb-2">
                                {offerVisible ? (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
                                    </button>
                                ) : (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}


                    {trackOffer && activeButton === "InActive" && (
                        <div className="normalOffer bg-white rounded-md flex justify-between mt-4 mx-10 p-4">
                            <div className="left text-black font-normal">
                                <p>
                                    {percentage}% OFF Upto ₹{price}
                                </p>
                                <p className="date mt-2">
                                    Start Date: {date}th {month} {year}
                                </p>
                            </div>
                            <div className="right font-bold">
                                <button className="new_user rounded-md text-white px-5 p-1.5 me-2">
                                    New Users Only
                                </button>
                                <button className="active rounded-md px-5 py-1.5">
                                    Active
                                </button>
                            </div>
                        </div>
                    )}

                    {trackOffer && activeButton === "InActive" && (
                        <div className="offerInfo bg-white rounded-md mx-10 mt-4">
                            <div className="offer_boxes flex justify-between mx-3 mt-2">
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹{sales}</p>
                                    <p className="font-normal count_head mt-1.5">Gross Sales</p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">{orders}</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Orders Delivered
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹1,23,567</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Discount Given
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">67%</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Effective Discount %
                                    </p>
                                </div>
                            </div>
                            {offerVisible && (
                                <div className="offerDetails" id="ds">
                                    <p className="mt-5 ms-5">
                                        Performance updated till {perfomanceDay}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer applicable for:New users on all menu items,excluding
                                        MRP items
                                    </p>
                                    <p className="mt-5 ms-5">Minimum order value:₹{minPrice}</p>
                                    <p className="mt-5 ms-5">
                                        Valid at: {hotel}, {area}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer sharing:100% of the discount value is being funded by
                                        you
                                    </p>
                                    <button
                                        className="rounded-md w-full text-center mt-5 ms-5 py-1"
                                        onClick={stopOfferPopup}
                                    >
                                        Stop Offer
                                    </button>
                                </div>
                            )}
                            <hr className="divider my-2 " />
                            <div className="flex items-center justify-center mb-2">
                                {offerVisible ? (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
                                    </button>
                                ) : (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                    {trackOffer && activeButton === "All" && (
                        <div className="normalOffer bg-white rounded-md flex justify-between mt-4 mx-10 p-4">
                            <div className="left text-black font-normal">
                                <p>
                                    {percentage}% OFF Upto ₹{price}
                                </p>
                                <p className="date mt-2">
                                    Start Date: {date}th {month} {year}
                                </p>
                            </div>
                            <div className="right font-bold">
                                <button className="new_user rounded-md text-white px-5 p-1.5 me-2">
                                    New Users Only
                                </button>
                                <button className="active rounded-md px-5 py-1.5">
                                    Active
                                </button>
                            </div>
                        </div>
                    )}

                    {trackOffer && activeButton === "All" && (
                        <div className="offerInfo bg-white rounded-md mx-10 mt-4">
                            <div className="offer_boxes flex justify-between mx-3 mt-2">
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹{sales}</p>
                                    <p className="font-normal count_head mt-1.5">Gross Sales</p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">{orders}</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Orders Delivered
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹1,23,567</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Discount Given
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">67%</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Effective Discount %
                                    </p>
                                </div>
                            </div>
                            {offerVisible && (
                                <div className="offerDetails" id="ds">
                                    <p className="mt-5 ms-5">
                                        Performance updated till {perfomanceDay}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer applicable for:New users on all menu items,excluding
                                        MRP items
                                    </p>
                                    <p className="mt-5 ms-5">Minimum order value:₹{minPrice}</p>
                                    <p className="mt-5 ms-5">
                                        Valid at: {hotel}, {area}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer sharing:100% of the discount value is being funded by
                                        you
                                    </p>
                                    <button
                                        className="rounded-md w-full text-center mt-5 ms-5 py-1"
                                        onClick={stopOfferPopup}
                                    >
                                        Stop Offer
                                    </button>
                                </div>

                            )}
                            <hr className="divider my-2 " />
                            <div className="flex items-center justify-center mb-2">
                                {offerVisible ? (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
                                    </button>
                                ) : (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    <div>

                    </div>

                </>
            ) : (
                <>
                    <Createoffer />
                </>
            )}
        </div>
    );
};

export default TrackOffer;


