import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Createoffer from "./components/Createoffer";
import Sidebar from "./components/Sidebar";
import "normalize.css";
import Head from "./components/Head";
import OutletOffline from "./pages/Helpcenter/needHelp/OutletOffline";
import PriceRevision from "./pages/Helpcenter/needHelp/PriceRevision";
import GrowthIssue from "./pages/Helpcenter/needHelp/GrowthIssue";
import PaymentSettilement from "./pages/Helpcenter/needHelp/PaymentSettilement";
import EditPackaging from "./pages/Helpcenter/needHelp/EditPackaging";
import ChangeName from "./pages/Helpcenter/needHelp/ChangeName";
import ChangeContacts from "./pages/Helpcenter/needHelp/ChangeContacts";
import OrderAndRefound from "./pages/Helpcenter/needHelp/OrderAndRefound";
import RequestOwner from "./pages/Helpcenter/needHelp/RequestOwner";
import StartPromotion from "./pages/Helpcenter/needHelp/StartPromotion";
import { Menu, UpdateDeleveringMenu, UpdatePackagingcharge, OtherMenuRelatedIssue } from "./pages/Helpcenter/Menu";
import Payments from "./pages/Helpcenter/Payments";
import NeedHelp from "./pages/Helpcenter/needHelp";
import TrackOffers from "./components/TrackOffer";
import OrderHistory from "./pages/Orderhistory";
import Reporting from "./pages/Reporting";
import CustomerComplaints from "./components/CustomerComplaints";
import Helpcenter from "./pages/Helpcenter/Helpcenter";
import Tickets from "./pages/Helpcenter/Tickets";
import OutletOnline from "./pages/Helpcenter/OutletOnline";
import OrderRelatedIssue from "./pages/Helpcenter/OrderRelatedIssue";
import { Restarent, RestarentTiming, ContactDetails, UpdateGstlin, DisplayPicture, ReviewsAndRating, DeliveryAreaChanges, NameAddressLocation } from "./pages/Helpcenter/Restarent";
import Address from "./pages/Helpcenter/Address";
import Outlet from "./Outletinfo/Outlet";
import LearningCentre from "./pages/LearningCentre";
import Reviews from "./pages/Reviews";
import Login from "./pages/LogIn";
import OTPVerification from "./pages/OTPVerification";
import Payment from "./pages/Payment";
import Details from "./pages/Details";
import Order from "./pages/Order";
import MenuHead from "./components/Menupages/MenuHead";
import { MenuProvider } from "./Context/MenuContext";
import MainForm from "./components/Menupages/MainForm";
import CreateNewOffer from "./components/CreateNewOffer";

function App() {

  return (
    <Router>
      <div className="App overflow-hidden">
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/otp" element={<OTPVerificationWrapper />} />

          {/* Private Routes */}
          <Route
            path="/"
            element={
              <PrivateRouteWrapper>
                <Head />
                <MenuProvider>
                  <Sidebar />
                </MenuProvider>
              </PrivateRouteWrapper>
            }
          >
            {/* Default route to TrackOffer */}
            <Route index element={<Navigate to="/TrackOffer" replace />} />
            <Route path="/TrackOffer" element={<TrackOffers />} />
               <Route path="/Createoffer" element={<Createoffer />} />
               <Route path="/CreateNewOffer" element={<CreateNewOffer />} />
               <Route path="/orderhistory" element={<OrderHistory />} />
               <Route path="/helpcenter" element={<Helpcenter />}>
                 <Route path="tickets" element={<Tickets />} />
                 <Route path="outlet-online" element={<OutletOnline />} />
                 <Route path="order-releted-issue" element={<OrderRelatedIssue />} />
                 <Route path="restarent" element={<Restarent />} />
                 <Route path="restarent-timing" element={<RestarentTiming />} />
                 <Route path="restarent-contact" element={<ContactDetails />} />
                 <Route path="restarent-update-gst" element={<UpdateGstlin />} />
                 <Route path="restarent-display-picture" element={<DisplayPicture />} />
                 <Route path="restarent-review-rating" element={<ReviewsAndRating />} />
                 <Route path="restarent-delivery-area-changes" element={<DeliveryAreaChanges />} />
                 <Route path="restarent-name-address-location" element={<NameAddressLocation />} />
                 <Route path="needhelp" element={<NeedHelp />} />
                 <Route path="payments" element={<Payments />} />
                 <Route path="menus" element={<Menu />} />
                 <Route path="address" element={<Address />} />
                 <Route path="menus-UpdateDeleveringMenu" element={<UpdateDeleveringMenu />} />
                 <Route path="menus-UpdatePackagingcharge" element={<UpdatePackagingcharge />} />
                 <Route path="menus-OtherMenuRelatedIssue" element={<OtherMenuRelatedIssue />} />
                 <Route path="outlet-offline" element={<OutletOffline />} />
                 <Route path="price-revision" element={<PriceRevision />} />
                 <Route path="growth-related-issue" element={<GrowthIssue />} />
                 <Route path="payemnt-settilement" element={<PaymentSettilement />} />
                 <Route path="packaging-charges" element={<EditPackaging />} />
                 <Route path="change-address" element={<ChangeName />} />
                 <Route path="change-contacts" element={<ChangeContacts />} />
                 <Route path="refund-issue" element={<OrderAndRefound />} />
                 <Route path="request-Owner" element={<RequestOwner />} />
                 <Route path="start-promotion" element={<StartPromotion />} />
               </Route>
               <Route path="/outlet" element={<Outlet />} />
               <Route path="/payment" element={<Payment />} />
               <Route path="/reporting" element={<Reporting />} />
               <Route path="/customercomplaints" element={<CustomerComplaints />} />
               <Route path="/reviews" element={<Reviews />} />
               <Route path="/learningcentre" element={<LearningCentre />} />
               <Route path="/details" element={<Details />} />
               <Route path="/order" element={<Order />} />
               <Route path="/mainForm" element={<MainForm />} />
             </Route>
               <Route path="/menuhead" element={<MenuHead/>}/>
               

             {/* <Route path="/menuhead" element={<MenuHead/>}/> */}
             
          {/* Fallback Route for Unauthenticated Users */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

function OTPVerificationWrapper() {
  const navigate = useNavigate();

  const handleOtpVerification = (token) => {
    // Store the token in localStorage or sessionStorage after OTP verification
    sessionStorage.setItem("accessToken", token);
    navigate("/"); // Redirect to the home page after verification
  };

  return <OTPVerification onVerify={handleOtpVerification} />;
}

function PrivateRouteWrapper({ children }) {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      setIsAuthenticated(true);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return isAuthenticated ? children : null;
}

export default App;
