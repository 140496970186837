import React, { useState } from 'react';

function GstDeclaration() {
    const [gstOption, setGstOption] = useState('yes');

    const handleSaveChanges = () => {
        alert(`You selected: ${gstOption}`);
    };

    return (
        <div>
            <div className="container mx-auto pt-12 px-4">
                <h1 className="text-xl font-medium text-gray-700 mb-4">Do you charge 5% GST as restaurant on all your menu items?</h1>
                <div className="flex items-center mb-6">
                    <label className="flex items-center mr-6">
                        <input
                            type="radio"
                            value="yes"
                            checked={gstOption === 'yes'}
                            onChange={() => setGstOption('yes')}
                            className="form-radio h-5 w-5 text-green  bg-green"
                        />
                        <span className="ml-2 text-gray-700">Yes</span>
                    </label>
                    <label className="flex items-center">
                        <input
                            type="radio"
                            value="no"
                            checked={gstOption === 'no'}
                            onChange={() => setGstOption('no')}
                            className="form-radio h-5 w-5 text-green bg-green"
                        />
                        <span className="ml-2 text-gray-700">No</span>
                    </label>
                </div>
                <button
                    onClick={handleSaveChanges}
                    className="bg-green text-white px-4 py-2 rounded"
                >
                    Save Changes
                </button>
            </div>
        </div>
    )
}

export default GstDeclaration
