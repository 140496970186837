import React, { useState } from "react";
// import 'react-dates/initialize';
import { format } from "date-fns";
import { SlCalender } from "react-icons/sl";
import { DateRangePicker } from "react-date-range";

// import 'react-dates/lib/css/_datepicker.css';
// import { DateRangePicker } from 'react-dates';

function Tickets() {
    const [date, setDate] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    const [isopen, setIsOpen] = useState(false)
    const [option, setOption] = useState('ticket');

    const handleChange = (ranges) => {
        setDate(ranges.selection);

    };
    return (
        <div className="">
            <div className='flex justify-between my-2 mx-2'>
                <div className='flex space-x-5'>
                    <button type='button' onClick={() => setOption('ticket')} className={`text-gray-400 font-semibold ${option.includes('ticket') ? "border-b-2 border-green" : ''}`}>All Tickets (0)</button>
                    <button type='button' onClick={() => setOption('open')} className={`text-gray-400 font-semibold ${option.includes('open') ? "border-b-2 border-green" : ''}`}>Open (0)</button>
                    <button type='button' onClick={() => setOption('response-received')} className={`text-gray-400 font-semibold ${option.includes('response-received') ? "border-b-2 border-green" : ''}`}>Response Received (0)</button>
                </div>
                <div className="relative">
                    <button className="border p-2 rounded flex items-center bg-green text-white" onClick={() => setIsOpen(!isopen)}><SlCalender className="inline mx-1" />{`${format(date.startDate, "MMM dd yyyy")} to ${format(
                        date.endDate,
                        "MMM dd yyyy"
                    )}`} </button>
                    <div className="absolute right-0">

                        {isopen && <DateRangePicker
                            ranges={[date]}
                            onChange={handleChange}
                        // minDate={new Date()} its gives change only from start date
                        />}
                    </div>
                    
            
                </div>
            </div>
            <div className="border border-gray-300 shadow"></div>
            {option === 'ticket' && <AllTicket />}
            {option === 'open' && <Open />}
            {option === 'response-received' && <ResponseReceived />}
        </div>
    );
}
const AllTicket = () => {
    return (
        <div>
            <p className="text-center py-4">All Ticket</p>
            <div className="flex justify-center  my-10">
                
                <img src="https://www.shareicon.net/data/128x128/2016/07/23/800747_food_512x512.webp" alt="" />
            </div>
        </div>
    )
}
const Open = () => {
    return (
        <div>
            <p className="text-center py-4">Open</p>
            <div className="flex justify-center  my-10">
                <img src="https://www.shareicon.net/data/128x128/2016/07/23/800747_food_512x512.webp" alt="" />
            </div>
        </div>
    )
}
const ResponseReceived = () => {
    return (
        <div>
            <p className="text-center py-4">ResponseReceived</p>
            <div className="flex justify-center my-10">

                <img src="https://www.shareicon.net/data/128x128/2016/07/23/800747_food_512x512.webp" alt="" />
            </div>
        </div>
    )
}
export default Tickets;
