import useStore from "../zustand";
import React, { useState, useEffect } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import {
  MdLocalOffer,
  MdOutlineAccessTime,
  MdHelpCenter,
  MdLightbulb,
  MdOutlineRestaurantMenu,
} from "react-icons/md";
import { TbCoinRupee } from "react-icons/tb";
import { VscGraph } from "react-icons/vsc";
import { IoMdOutlet } from "react-icons/io";
import { FaQuestionCircle } from "react-icons/fa";
import SimpleBar from "simplebar-react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import 'simplebar/dist/simplebar.min.css';

const Sidebar = () => {
  const { visible, novisible } = useStore()
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const token = sessionStorage.getItem("accessToken");
  const menuLink = `/menuhead?token=${encodeURIComponent(token)}`;
  const Menus = [

    { title: "Offers", link: "/TrackOffer", icon: <MdLocalOffer /> },
    {
      title: "Order History",
      link: "/orderhistory",
      icon: <MdOutlineAccessTime />,
    },
    { title: "Payment", link: "/payment", icon: <TbCoinRupee /> },
    { title: "Reporting", link: "/reporting", icon: <VscGraph /> },
    { title: "Outlet Info", link: "/outlet", icon: <IoMdOutlet /> },
    { title: "Help Centre", link: "/helpcenter", icon: <MdHelpCenter /> },
    {
      title: "Customer Complaints",
      link: "/customercomplaints",
      icon: <FaQuestionCircle />,
    },
    {
      title: "Learning Centre",
      link: "/learningcentre",
      icon: <MdLightbulb />,
    },
    { title: "Reviews", link: "/reviews", icon: <FaQuestionCircle /> },
    { title: "Menu", link: menuLink, icon: <MdOutlineRestaurantMenu />, target: "_blank" },
  ];
  const height = visible
    ? "calc(100vh - 90px)"
    : novisible
      ? "calc(100vh - 220px)"
      : "calc(100vh - 256px)";

  // Session storage authentication check
  useEffect(() => {
    const token = sessionStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="flex">
      <SimpleBar
        style={{
          height,
          width: open ? "20%" : "5%",
          transition: "width 0.3s",
        }}
        className="p-4 pt-2 border-r border-black relative"
      >
        {open ? (
          <BsArrowLeftShort
            className="bg-green text-white rounded-full text-4xl absolute -right-3 top-96 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        ) : (
          <BsArrowRightShort
            className="bg-green text-white rounded-full text-4xl absolute -right-3 top-96 cursor-pointer"
            onClick={() => setOpen(true)}
          />
        )}

        <ul className="pt-2">
          {Menus.map((menu, index) => (
            <Link to={menu.link} key={index} className="w-full" target={menu.target || "_self"}>
              <li
                className={`group font-medium link text-lg flex items-center gap-x-4 cursor-pointer p-3 rounded-md mt-2 ${location.pathname === menu.link ? "bg-green text-white" : "hover:bg-green hover:text-white"} ${!open ? "justify-center" : ""}`}
              >
                <span className="text-2xl block float-left">{menu.icon}</span>
                <span
                  className={`text-base font-medium flex-1 duration-200 ${!open ? "hidden" : ""}`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      </SimpleBar>
      <SimpleBar
        style={{
          height,
          //  ? "calc(100vh - 100px)" : "calc(100vh - 256px)",
          width: open ? "80%" : "95%",
          transition: "width 0.3s",
        }}
        className="flex-1"
      >
        <Outlet />
      </SimpleBar>
    </div>
  );
};

export default Sidebar;

