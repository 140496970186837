import React from 'react';
import category from "../Menupages/category.webp";
import item from "../Menupages/add items.webp";
import submit from "../Menupages/submit.webp";

function AddOnsContent() {
    return (
        <div className='px-4'>
            <h1 className="text-2xl font-semibold text-gray-700">Add-ons Listing</h1>
            <p className="text-gray-600 mb-4">
                Add-ons are additional items which the user can add while ordering an item. eg. with Paneer Tikka the user
                can add Cream Salad, Sliced Onion, Masala Onion
            </p>
            <button className="bg-green text-white px-4 py-2 rounded mb-4">Create Add-on Group</button>
            <div className="step flex items-center my-5">
                <img src={category} alt="Create add-ons icon" className="w-12 h-12 mr-5" />
                <div>
                    <h2 className="text-xl font-medium text-gray-600">Create add-ons in 3 steps</h2>
                    <p className="text-gray-500">
                        Add new add-ons in a group (eg: extra cheese) or map from existing items in the menu (eg: Beverages,
                        Sides, Desserts)
                    </p>
                </div>
            </div>
            <div className="step flex items-center my-5">
                <img src={item} alt="Pair add-ons icon" className="w-12 h-12 mr-5" />
                <div>
                    <h2 className="text-xl font-medium text-gray-600">Pair the right add-ons to offer with dishes</h2>
                    <p className="text-gray-500">Pair add-on groups to single or multiple dishes for easy selection</p>
                </div>
            </div>
            <div className="step flex items-center my-5">
                <img src={submit} alt="Manage add-ons icon" className="w-12 h-12 mr-5" />
                <div>
                    <h2 className="text-xl font-medium text-gray-600">Manage add-on group</h2>
                    <p className="text-gray-500">
                        * Define minimum & maximum quantity customer can select, <br />
                        * Add veg, non-veg or egg classification <br />
                        * Mark groups or add-on items in & out of stock with ease
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AddOnsContent;
