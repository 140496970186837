import React from 'react';
import { useState } from 'react';

function Help() {
    const faqs = [
        {
            question: 'Change the price of a dish',
            answer: 'To change the price of a dish, go to the menu section, select the dish, and update the price field.',
        },
        {
            question: 'Mark a dish as recommended',
            answer: 'To mark a dish as recommended, navigate to the dish details page and toggle the "Recommended" switch.',
        },
        {
            question: 'Make a menu category available for specific duration',
            answer: 'To make a menu category available for a specific duration, set the availability time range in the category settings.',
        },
        {
            question: 'Mark a dish or menu category as out of stock',
            answer: 'To mark a dish or menu category as out of stock, select the item and toggle the "Out of Stock" switch.',
        },
        {
            question: 'Mark a category (eg. beverages) as Out of Stock',
            answer: 'To mark a category like beverages as out of stock, go to the category settings and select "Out of Stock".',
        },
        {
            question: 'Create a new dish like Dal Makhni',
            answer: 'To create a new dish, go to the menu section, click "Add New Dish", and fill in the required details.',
        },
        {
            question: 'Creating a new dish with half, full variants',
            answer: 'To create a dish with half and full variants, use the variant options when adding the new dish.',
        },
        {
            question: 'Create a new menu category',
            answer: 'To create a new menu category, go to the menu settings, click "Add Category", and fill in the required information.',
        },
        {
            question: 'Add a photo for a dish',
            answer: 'To add a photo for a dish, navigate to the dish details page and upload the desired photo.',
        },
        {
            question: 'Add a photo for a dish',
            answer: 'To add a photo for a dish, navigate to the dish details page and upload the desired photo.',
        },
        {
            question: 'Add a photo for a dish',
            answer: 'To add a photo for a dish, navigate to the dish details page and upload the desired photo.',
        },
        {
            question: 'Add a photo for a dish',
            answer: 'To add a photo for a dish, navigate to the dish details page and upload the desired photo.',
        },
        {
            question: 'Add a photo for a dish',
            answer: 'To add a photo for a dish, navigate to the dish details page and upload the desired photo.',
        },
    ];

    const [openFaqIndex, setOpenFaqIndex] = useState(null);

    const handleFaqToggle = (index) => {
        setOpenFaqIndex(openFaqIndex === index ? null : index);
    };


    return (
        <div>
            <div className="container mx-auto p-6">
                <h1>Help</h1>
                <h2 className="text-2xl font-semibold text-gray-700 mb-4">Frequently asked questions</h2>
                <div className="divide-y divide-gray-200">
                    {faqs.map((faq, index) => (
                        <div key={index}>
                            <div
                                className="flex justify-between items-center py-4 cursor-pointer"
                                onClick={() => handleFaqToggle(index)}
                            >
                                <span className="text-gray-700">{faq.question}</span>
                                <button className="text-gray-500 hover:text-gray-700">
                                    {openFaqIndex === index ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 3a1 1 0 00-.894.553l-6 11A1 1 0 004 16h12a1 1 0 00.894-1.447l-6-11A1 1 0 0010 3z" clipRule="evenodd" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 17a1 1 0 01-.894-.553l-6-11A1 1 0 014 5h12a1 1 0 01.894 1.447l-6 11A1 1 0 0110 17z" clipRule="evenodd" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                            {openFaqIndex === index && (
                                <div className="py-2 pl-4 text-gray-600">
                                    {faq.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Help
