import React from "react";
import banner from "../assets/banner.webp";
import { useState } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

function LearningCentre() {
  const [term, setTerm] = useState("");
  const [videos, setVideos] = useState([]);
  const [noResults, setNoResults] = useState(false);

  const API_KEY = "YOUR_YOUTUBE_API_KEY";

  const onInputChange = (event) => {
    setTerm(event.target.value);
  };

  const onFormSubmit = async (event) => {
    event.preventDefault();
    const response = await axios.get(
      "https://www.googleapis.com/youtube/v3/search",
      {
        params: {
          part: "snippet",
          maxResults: 5,
          q: term,
          key: API_KEY,
        },
      }
    );

    if (response.data.items.length === 0) {
      setNoResults(true);
      setVideos([]);
    } else {
      setNoResults(false);
      setVideos(response.data.items);
    }
  };

  const videoss = [
    { id: uuidv4(), youtubeId: "hfv64eowOSM" }, // Replace with your desired video IDs
    { id: uuidv4(), youtubeId: "ywxHhzQb2k8" },
    { id: uuidv4(), youtubeId: "nsqimBw2H30" },
    { id: uuidv4(), youtubeId: "l__uXGsUIFQ" },
  ];

  return (
    <>
      <img src={banner} className="w-full" alt="banner1" />

      <div className="container mx-auto mt-3">
        <form onSubmit={onFormSubmit} className="flex items-center border-b border-b-2 border-green py-2 p-4">
          <FaSearch className="text-gray-500 mr-2" />
          <input
            type="text"
            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
            placeholder="Search for a video..."
            value={term}
            onChange={onInputChange}
          />
          <button type="submit" className="flex-shrink-0 bg-green hover:bg-green border-green text-sm border-4 text-white py-1 px-2 rounded">
            Search
          </button>
        </form>
        {noResults ? (
          <div className="text-center mt-4 text-red-500">No search found</div>
        ) : (
          <div className="mt-4">
            {videos.map((video) => (
              <div
                key={video.id.videoId}
                className="mb-4 p-4 border rounded-lg shadow-md"
              >
                <a
                  href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={video.snippet.thumbnails.default.url}
                    alt={video.snippet.title}
                    className="w-full h-auto"
                  />
                  <div className="mt-2 text-lg font-semibold">
                    {video.snippet.title}
                  </div>
                  <div className="mt-1 text-gray-600">
                    {video.snippet.description}
                  </div>
                </a>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="px-4 font-bold text-lg">New on zomato</div>

      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {videoss.map((video) => (
            <div key={video.id} className="p-4 border rounded-lg shadow-md">
              <iframe
                className="w-full h-48"
                src={`https://www.youtube.com/embed/${video.youtubeId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={`YouTube video player - ${video.youtubeId}`}
              />
            </div>
          ))}
        </div>
      </div>

      <hr />

      <div className="px-4 font-bold text-lg mt-3">Most Popular On Genie</div>

      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {videoss.map((video) => (
            <div key={video.id} className="p-4 border rounded-lg shadow-md">
              <iframe
                className="w-full h-48"
                src={`https://www.youtube.com/embed/${video.youtubeId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={`YouTube video player - ${video.youtubeId}`}
              />
            </div>
          ))}
        </div>
      </div>

      <hr />

      <div className="px-4 font-bold text-lg mt-3">Getting More Orders</div>

      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {videoss.map((video) => (
            <div key={video.id} className="p-4 border rounded-lg shadow-md">
              <iframe
                className="w-full h-48"
                src={`https://www.youtube.com/embed/${video.youtubeId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={`YouTube video player - ${video.youtubeId}`}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default LearningCentre;
