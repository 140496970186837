import React from 'react'

function OrderRelatedIssue() {
    return (

        <div>
            <p className="text-center py-4">order related issue</p>
            <div className="flex justify-center  my-10">
                <img src="https://www.shareicon.net/data/128x128/2016/07/23/800747_food_512x512.webp" alt="" />
            </div>
        </div>

    )
}

export default OrderRelatedIssue