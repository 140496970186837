import React, { useState } from 'react'
import './Outlet.css';
import Aboutoutlet from './Aboutoutlet';
import ContactDetails from './ContactDetails';
import OutletTiming from './OutletTiming';
import Outletname from './Outletname';
const Outlet = () => {
  const [selected, setSelected] = useState('About outlet');

  const handleItemClick = (content) => {
    setSelected(content);
  };
  const renderContent = () => {
    switch (selected) {
      case 'About outlet':
        return <Aboutoutlet />;
      case 'Contact details':
        return <ContactDetails />;
      case 'Outlet timing':
        return <OutletTiming />;
      case 'Outletname':
        return <Outletname />;

      default:
        return <Aboutoutlet />;
    }
  };
  return (
    <>

      <div className="outlet">
        <div className="container border-b-2 border-slate-300">
          <div className="row flex justify-between p-3">
            <h3 className="text-xl font-bold">Outlet Infromation</h3>
           
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12">
        <ul className="col-span-3">
          <li onClick={() => handleItemClick('About outlet')} className="flex justify-between items-center p-4 cursor-pointer hover:text-green hover:bg-lime-100 hover:border-r-4 border-green">
            About outlet <span className="ml-1 justify-end text-base">&gt;</span>
          </li>
          <li onClick={() => handleItemClick('Contact details')} className="flex justify-between items-center p-4 cursor-pointer hover:text-green hover:bg-lime-100 hover:border-r-4 border-green">
            Contact details <span className="ml-1">&gt;</span>
          </li>
          <li onClick={() => handleItemClick('Outlet timing')} className="flex justify-between items-center p-4 cursor-pointer hover:text-green hover:bg-lime-100 hover:border-r-4 border-green">
            Outlet timings <span className="ml-1">&gt;</span>
          </li>
          <li onClick={() => handleItemClick('Outletname')} className="flex justify-between items-center p-4 cursor-pointer hover:text-green hover:bg-lime-100 hover:border-r-4 border-green">
            Outlet name, address & location <span className="ml-1">&gt;</span>
          </li>

        </ul>
        <div className="col-span-9 border-l-2 h-lvh border-gray-400">
          {renderContent()}
        </div>
      </div>
    </>
  )
}

export default Outlet