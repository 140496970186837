import React from 'react'
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
// import { FaArrowLeftLong } from "react-icons/fa6";
import { TiTicket } from "react-icons/ti";
import { useState } from 'react';
import Modal from '../../components/Modal';
import { CiImageOn } from "react-icons/ci";
import { FaRegFileAlt } from "react-icons/fa";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";



export function Menu() {
    const nevigate = useNavigate()
    return (
        <div>
            <div className='m-5 border rounded-lg overflow-hidden space-y-4'>
                <div className='p-3  bg-gray-100 '>Select An Option To Proceed</div>
                <div onClick={() => nevigate('/helpcenter/menus-UpdateDeleveringMenu')} className='cursor-pointer flex justify-between items-center border-b p-3'><span>Update Delevering Menu</span><FaAngleRight /></div>
                <div onClick={() => nevigate('/helpcenter/menus-UpdatePackagingcharge')} className='cursor-pointer flex justify-between items-center border-b p-3'><span>Update Packaging charge</span> <FaAngleRight /></div>
                <div onClick={() => nevigate('/helpcenter/menus-OtherMenuRelatedIssue')} className='cursor-pointer flex justify-between items-center border-b p-3'><span>Other Menu Related Issue</span> <FaAngleRight /></div>
            </div>
        </div>
    )
}

export function UpdateDeleveringMenu() {
    const nevigate = useNavigate()
    return (
        <div>
            <div className='flex space-x-3 bg-gray-100 py-3 p-2'><FaArrowLeftLong onClick={() => nevigate('/helpcenter/menus')} /> <span className='font-semibold'>Update Delevering Menu</span></div>
            <div className='flex items-center m-4 bg-blue-100 rounded'><p className='text-lg p-2 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio doloremque vitae quam saepe blanditiis labore laborum dicta eveniet ducimus, amet sit? Ipsa necessitatibus fugit et laborum rerum molestiae aspernatur. Rem?</p><img src="https://cdn-icons-png.flaticon.com/512/2289/2289389.webp" width={200} height={200} alt="" /></div>
        </div>
    )
}

export function UpdatePackagingcharge() {
    const nevigate = useNavigate()
    return (
        <div>
            <div className='flex space-x-3 bg-gray-100 p-2 py-4'><FaArrowLeftLong onClick={() => nevigate('/helpcenter/menus')} /> <span className='font-semibold'>Update Packaging charge</span></div>
            <div className='flex items-center m-4 bg-blue-100 rounded'><p className='text-lg p-2 font-semibold'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio doloremque vitae quam saepe blanditiis labore laborum dicta eveniet ducimus, amet sit? Ipsa necessitatibus fugit et laborum rerum molestiae aspernatur. Rem?</p><img src="https://cdn-icons-png.flaticon.com/512/2289/2289389.webp" width={200} height={200} alt="" /></div>
        </div>
    )
}

export function OtherMenuRelatedIssue() {
    const nevigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div>
            <div className='flex space-x-3 bg-gray-100 py-4 p-2'><FaArrowLeftLong onClick={() => nevigate('/helpcenter/menus')} /> <span className='font-semibold'>Other Menu Related Issue</span></div>
            <p className='px-3 text-lg'>To get Your Restaurent display picture update please write to us</p>
            <button type='button' onClick={() => setIsOpen(!isOpen)} className='space-x-2 px-3 pb-3 text-blue-600'><TiTicket className='inline' /> <span>Rise a ticket</span></button>
            <Modal close={true} isOpen={isOpen} width={400} onClose={() => setIsOpen(!isOpen)}>
                <div className='space-y-5'>
                    <div className='text-lg font-medium'>Please Give Details</div>
                    <div className='text-lg font-semibold p-1 bg-blue-50'>Restaurant : Display Picture</div>
                    <div className='space-y-3'>
                        <p>Describe Your Issue <span className='text-red'>*</span></p>
                        <textarea name="describe" id="describe" className='border w-full p-1 rounded'></textarea>

                    </div>
                    <div className='space-y-3'>
                        <p>Callback Contact Number</p>
                        <textarea name="callback" className='border w-full p-1 rounded' id="callback"></textarea>
                    </div>

                    <p>Attachment</p>
                    <div>
                        <span>
                            <label htmlFor="imageInput">
                                <div className='flex justify-between text-lg border items-center p-1 rounded'>
                                    <span>   <CiImageOn size={30} className='inline' /> upload image or video</span><FaArrowRightLong />
                                </div>
                                <input
                                    id="imageInput"
                                    name="profileImage"
                                    type="file"
                                    accept="image/*,video/*"
                                    className="hidden"
                                    //   onChange={handleChange}
                                    alt="upload your profile"
                                />
                            </label>
                        </span>
                    </div>
                    <div>
                        <span>
                            <label htmlFor="fileInput">
                                <div className='flex justify-between text-lg border items-center p-1 rounded'>
                                    <span>  <FaRegFileAlt className='inline' size={30} /> upload file</span><FaArrowRightLong />
                                </div>
                                <input
                                    id="fileInput"
                                    name="profileImage"
                                    type="file"

                                    className="hidden"
                                    //   onChange={handleChange}
                                    alt="upload your profile"
                                />
                            </label>
                        </span>
                    </div>
                    <p>please make sure everything is correct</p>
                    <button type='button' className='block w-full p-2 rounded bg-gray-200'>Rise Ticket</button>

                </div>
            </Modal>
        </div>
    )
}


export default Menu