// MainForm.js
import React, { useState, useEffect } from 'react';
import NonCustomizable from './NonCustomizable';
import RelationalPricing from './RelationalPricing';
import IndependentPricing from './IndependentPricing';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';

const MainForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    fulfillmentTypes: { takeaway: false, delivery: false },
    isSamePrice: false,
    isCustomizable: null,
    pricingStructure: null,
    description: '',
    price: '',
    image: null,
  });

  useEffect(() => {
    const storedItem = sessionStorage.getItem('selectedItem');
    if (storedItem) {
      const parsedItem = JSON.parse(storedItem);
      setFormData({
        ...formData,
        name: parsedItem.name,
        price: parsedItem.basePrice,
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : value,
    });
  };

  const handleCustomizableChange = (value) => {
    setFormData({ ...formData, isCustomizable: value });
  };

  const handlePricingStructureChange = (structure) => {
    setFormData({ ...formData, pricingStructure: structure });
  };

  const handleFulfillmentChange = (type) => {
    setFormData({
      ...formData,
      fulfillmentTypes: { ...formData.fulfillmentTypes, [type]: !formData.fulfillmentTypes[type] },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem('formData', JSON.stringify(formData));
    toast.success('Item added successfully!');
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Set Your Delivery Pricing</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Name of your item</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Full Fulfillment Types</label>
          <div>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name="takeaway"
                checked={formData.fulfillmentTypes.takeaway}
                onChange={() => handleFulfillmentChange('takeaway')}
                className="form-checkbox"
              /> Takeaway
            </label>
            <label className="inline-flex items-center ml-4">
              <input
                type="checkbox"
                name="delivery"
                checked={formData.fulfillmentTypes.delivery}
                onChange={() => handleFulfillmentChange('delivery')}
                className="form-checkbox"
              /> Delivery
            </label>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Is Same Price?</label>
          <input
            type="checkbox"
            name="isSamePrice"
            checked={formData.isSamePrice}
            onChange={handleInputChange}
            className="toggle-checkbox"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Is Customizable?</label>
          <div>
            <button
              type="button"
              onClick={() => handleCustomizableChange(true)}
              className={`mr-2 ${formData.isCustomizable ? 'bg-green-500' : 'bg-gray-300'} p-2 rounded`}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => handleCustomizableChange(false)}
              className={`${formData.isCustomizable === false ? 'bg-green-500' : 'bg-gray-300'} p-2 rounded`}
            >
              No
            </button>
          </div>
        </div>

        {formData.isCustomizable === false && (
          <NonCustomizable formData={formData} handleInputChange={handleInputChange} />
        )}
        {formData.isCustomizable && (
          <div className="mb-4">
            <label className="block text-gray-700">Pricing Structure</label>
            <div>
              <button
                type="button"
                onClick={() => handlePricingStructureChange('relational')}
                className={`mr-2 ${formData.pricingStructure === 'relational' ? 'bg-green-500' : 'bg-gray-300'} p-2 rounded`}
              >
                Relational
              </button>
              <button
                type="button"
                onClick={() => handlePricingStructureChange('independent')}
                className={`${formData.pricingStructure === 'independent' ? 'bg-green-500' : 'bg-gray-300'} p-2 rounded`}
              >
                Independent
              </button>
            </div>
          </div>
        )}

        {formData.pricingStructure === 'relational' && (
          <RelationalPricing formData={formData} setFormData={setFormData} />
        )}
        {formData.pricingStructure === 'independent' && (
          <IndependentPricing formData={formData} setFormData={setFormData} />
        )}

        {formData.isCustomizable === false && (
          <div className="mb-4">
            <label className="block text-gray-700 mt-4">Item Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            ></textarea>
            <label className="block text-gray-700 mt-4">Image Upload</label>
            <input
              type="file"
              name="image"
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {formData.image && (
              <div className="mt-2">
                <img src={URL.createObjectURL(formData.image)} alt="Uploaded" className="w-32 h-32 object-cover" />
              </div>
            )}
          </div>
        )}

        <button type="submit" className="mt-4 bg-green-500 text-white p-2 rounded">Submit</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default MainForm;
