import React, { useState } from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import Accordion from '../../assets/accordion-1.webp';
import Accordion2 from '../../assets/accordion-2.webp'

const ManageImage = () => {
    const [activeAccordion, setActiveAccordion] = useState(null);

    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };
    return (
        <div className="container mx-auto">
            <div>
                {/* Accordion */}
                <div>
                    {/* Accordion Item 1 */}
                    <div className="border-b p-4">
                        <div className="flex items-center cursor-pointer" onClick={() => toggleAccordion(1)}>
                            <h6 className="text-lg font-semibold">Dining out food images (1)</h6>
                            {/* Custom Icon */}
                            <span className='px-5'>
                                {activeAccordion === 1 ? <FaCaretUp /> : <FaCaretDown />}
                            </span>
                        </div>
                        <p className='text-gray-400 text-sm pt-1'>Select from your restaurant food images to map them to relevant items</p>
                        {activeAccordion === 1 && (
                            <div className="mt-4">
                                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                    {/* Sample Manage Image Card */}
                                    <div className="">
                                        <img src={Accordion} alt="Accordion-1" className="w-56 h-44 object-cover rounded" />
                                    </div>
                                    {/* Repeat for more images */}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Accordion Item 2 */}
                    <div className="border-b p-4">
                        <div className="flex items-center cursor-pointer" onClick={() => toggleAccordion(2)}>
                            <h6 className="text-lg font-semibold">New images</h6>
                            {/* Custom Icon */}
                            <span className='px-5'>
                                {activeAccordion === 2 ? <FaCaretUp /> : <FaCaretDown />}
                            </span>
                        </div>
                        <p className='text-gray-400 text-sm pt-1'>Select images to map them to relevant items</p>
                        {activeAccordion === 2 && (
                            <div className="mt-4 flex flex-col items-center">
                                <img src={Accordion2} alt="Accordion2" className="w-36 h-32 object-cover rounded" />
                                <p className="text-base text-gray-500 mt-2">Images you upload can be mapped to items from here</p>
                            </div>
                        )}
                    </div>

                    {/* Accordion Item 3 */}
                    <div className="border-b p-4">
                        <div className="flex items-center cursor-pointer" onClick={() => toggleAccordion(3)}>
                            <h6 className="text-lg font-semibold">Unmapped approved images</h6>
                            {/* Custom Icon */}
                            <span className='px-5'>
                                {activeAccordion === 3 ? <FaCaretUp /> : <FaCaretDown />}
                            </span>
                        </div>
                        <p className='text-gray-400 text-sm pt-1'>Select images to map them to relevant items</p>
                        {activeAccordion === 3 && (
                            <div className="mt-4 flex flex-col items-center">
                                <img src={Accordion2} alt="Accordion2" className="w-36 h-32 object-cover rounded" />
                                <p className="text-base text-gray-500 mt-2">Images you upload can be mapped to items from here</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageImage
