import React, { useState } from 'react';
import MenuImage from './MenuImage';
import ManageImage from './ManageImage';
import { FaSearch, FaUpload, FaLink, FaSort, FaCaretDown, FaTrashAlt } from 'react-icons/fa';


const ImageGalleryContent = () => {
  const [activeTab, setActiveTab] = useState('menuImage');
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedImage(URL.createObjectURL(file));
    }
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    all: false,
    approved: false,
    pending: false,
    rejected: false,
  });

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };
  const isApplyEnabled = Object.values(selectedOptions).some((value) => value);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center border-b-4 border-gray-200 mb-3">
        <div className="flex items-center">
          <div className={`px-4 py-5 cursor-pointer font-semibold ${activeTab === 'menuImage' ? 'border-b-4 border-green text-green' : 'text-gray-500'}`} onClick={() => setActiveTab('menuImage')}>
            Menu Image
          </div>
          <div className={`px-4 py-5 cursor-pointer font-semibold ${activeTab === 'manageImage' ? 'border-b-4 border-green text-green' : 'text-gray-500'}`} onClick={() => setActiveTab('manageImage')} >
            Manage Image
          </div>
        </div>
        <div className="flex items-center space-x-4 px-4">
          {activeTab === 'menuImage' && (
            <>
              <div className="flex items-center border border-green rounded-md px-3 py-2 bg-white text-green">
                <input type="text" placeholder="Search..." className="outline-none text-green" />
                <FaSearch className="ml-2 text-green" />
              </div>
              <label className="flex items-center px-4 py-2 bg-green text-white rounded-md cursor-pointer">
                <FaUpload className="mr-2" /> Add Image
                <input type="file" onChange={handleFileUpload} className="hidden" />
              </label>
              <button className="flex items-center px-4 py-2 border border-green text-green rounded-md">
                <FaLink className="mr-2" /> Unmap
              </button>
              <div className='relative'>
                <button className="flex items-center px-4 py-2 bg-green text-white rounded-md" onClick={toggleDropdown}>
                  <FaSort className="mr-2" /> Filter
                  <FaCaretDown className="ml-2" />
                </button>
                {dropdownOpen && (
                  <div className="absolute bg-white border mt-2 rounded-md shadow-lg p-4 w-80 right-0">
                    <div className="flex flex-col leading-8">
                      <label className="flex items-center">
                        <input type="checkbox" checked={selectedOptions.all} onChange={() => handleCheckboxChange('all')} className="mr-2" />
                        All
                      </label>
                      <label className="flex items-center">
                        <input type="checkbox" checked={selectedOptions.approved} onChange={() => handleCheckboxChange('approved')} className="mr-2" />
                        Approved
                      </label>
                      <label className="flex items-center">
                        <input type="checkbox" checked={selectedOptions.pending} onChange={() => handleCheckboxChange('pending')} className="mr-2" />
                        Pending for moderation
                      </label>
                      <label className="flex items-center">
                        <input type="checkbox" checked={selectedOptions.rejected} onChange={() => handleCheckboxChange('rejected')} className="mr-2" />
                        Rejected
                      </label>
                    </div>
                    <div className="flex justify-end mt-4 space-x-2">
                      <button className="px-4 py-2 bg-gray-500 text-white rounded-md" onClick={() => setDropdownOpen(false)} >
                        Cancel
                      </button>
                      <button className={`px-4 py-2 rounded-md text-white ${isApplyEnabled ? 'bg-green' : 'bg-gray-300 cursor-not-allowed'}`} disabled={!isApplyEnabled} onClick={() => { setDropdownOpen(false); }} >
                        Apply Changes
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {/* Display Uploaded Image */}
              {/* {uploadedImage && (
        <div className="mt-4">
          <img src={uploadedImage} alt="Uploaded" className="w-full h-auto object-cover rounded-md" />
        </div>
      )} */}
            </>

          )}
          {activeTab === 'manageImage' && (
            <>
              <button className="flex items-center px-4 py-2 bg-green text-white rounded-md">
                <FaLink className="mr-2"/> Map Image
              </button>
              <button className="flex items-center px-4 py-2 bg-red text-white rounded-md" onClick={toggleModal}>
                <FaTrashAlt className="mr-2" /> Delete Image
              </button>
            </>
          )}
        </div>

      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black flex justify-center items-center">
          <div className="bg-white rounded-md shadow-lg w-96">
            {/* Modal Header */}
            <div className="flex justify-between items-center p-4">
              <h2 className="text-lg font-semibold">Delete images from menu</h2>
              <button onClick={toggleModal} className="text-gray-600 font-bold text-2xl hover:text-gray-800">
                &times;
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-4">
              <p className="text-gray-700">Are you sure you want to delete this image?</p>
            </div>

            {/* Modal Actions */}
            <div className="flex justify-end p-4 border-t space-x-2">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded-md"
                onClick={toggleModal}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-green text-white rounded-md"
                onClick={() => {
                  // Handle Delete Image action
                  setIsModalOpen(false);
                }}
              >
                Delete Image
              </button>
            </div>
          </div>
        </div>
      )}
      <div>
        {activeTab === 'menuImage' && <MenuImage />}
        {activeTab === 'manageImage' && <ManageImage />}
      </div>
    </div>
  );
}

export default ImageGalleryContent
