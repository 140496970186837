import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, subDays } from "date-fns";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { FaTimes } from 'react-icons/fa';

const CustomerComplaints = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [buttonText, setButtonText] = useState("Last 15 days");

  const today = new Date();
  const startLimit = subDays(today, 15);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setButtonText(
        `${format(start, "yyyy-MM-dd")} to ${format(end, "yyyy-MM-dd")}`
      );
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [activeFilter, setActiveFilter] = useState("issueTypes");

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedCheckboxes((prevSelected) => {
      if (checked) {
        return [...prevSelected, name];
      } else {
        return prevSelected.filter((checkbox) => checkbox !== name);
      }
    });
  };

  const [activeTab, setActiveTab] = useState('Issue Type');
  const [issueType, setIssueType] = useState({
    open: false,
    dismissed: false,
    resolved: false,
    winback: false,
    expired: false,
  });
  const [reasons, setReasons] = useState({
    "Poor taste or quality": false,
    "Wrong items delivered": false,
    "Poor packing or spillage": false,
    "Items missing or not delivered": false,
    "Order delay": false
  });

  const handleReasonsChange = (event) => {
    const { name, checked } = event.target;
    setReasons((prevReasons) => ({
      ...prevReasons,
      [name]: checked,
    }));
  };

  const handleIssueTypeChange = (e) => {
    setIssueType({ ...issueType, [e.target.name]: e.target.checked });
  };



  const handleClearAll = () => {
    setIssueType({
      open: false,
      dismissed: false,
      resolved: false,
      winback: false,
      expired: false,
    });
    setReasons({
      "Poor taste or quality": false,
      "Wrong items delivered": false,
      "Poor packing or spillage": false,
      "Items missing or not delivered": false,
      "Order delay": false
    });
  };

  const handleApply = () => {
    console.log('Applied Filters', { issueType, reasons });
  };


  return (
    <div className="">
      <div className="row border-b-2 border-gray-300 shadow-md flex flex-row justify-between items-center">
        <h3 className="p-3 font-bold text-xl">Customer Complaints</h3>

        <div className="flex items-center space-x-4 mx-4">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-green rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              {buttonText}
              <FaChevronDown
                className="w-5 h-5 ml-2 -mr-1 text-white"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="right-0 mt-2 origin-top-right absolute bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    minDate={startLimit}
                    maxDate={today}
                  />
                </div>
              </Menu.Items>
            </Transition>

          </Menu>

          <button
            onClick={togglePopup}
            className="px-4 py-2 text-white bg-green rounded-md focus:outline-none"
          >
            Filter
          </button>
        </div>

      </div>

      {startDate && endDate && (
        <div className="mt-4 text-xl text-green">
          No complaints reported. Keep it up!
        </div>
      )}


      


      <Transition
        show={isOpen}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white rounded shadow-md w-1/3 relative">
            <div className="flex items-center p-3 justify-between border-b-2 border-gray-300">
              <h2 className="text-lg font-semibold">Filter</h2>
              <button
                onClick={togglePopup}
                className="p-2 rounded-full hover:bg-gray-200"
              >
                <FaTimes className="text-gray-500" />
              </button>
            </div>
            <div className="flex flex-col">
              <div className="grid grid-cols-[35%,1fr] gap-4">
                {/* Column 1: Tabs */}
                <div className="border-r-2 border-gray-300 bg-gray-200">
                  <div className="mx-2 my-3">
                    <button
                      className={`px-4 py-2 mb-2 w-full rounded  ${activeTab === 'Issue Type' ? 'bg-green , text-white' : ''}`}
                      onClick={() => setActiveTab('Issue Type')}
                    >
                      Issue Type
                    </button>
                  </div>
                  <div className="mx-2 my-3">
                    <button
                      className={`px-4 py-2 mb-2 w-full rounded ${activeTab === 'Reasons' ? 'bg-green , text-white' : ''}`}
                      onClick={() => setActiveTab('Reasons')}
                    >
                      Reasons
                    </button>
                  </div>
                </div>

                {/* Column 2: Options */}
                <div>
                  {activeTab === 'Issue Type' && (
                    <div className="mt-2 leading-7">
                      {Object.keys(issueType).map((key) => (
                        <div key={key} className="flex items-center mb-2">
                          <input
                            type="checkbox"
                            name={key}
                            checked={issueType[key]}
                            onChange={handleIssueTypeChange}
                            className="mr-2"
                          />
                          <label className="capitalize">{key}</label>
                        </div>
                      ))}
                    </div>
                  )}

                  {activeTab === 'Reasons' && (
                    <div className="mt-2 leading-7">
                      {Object.keys(reasons).map((key) => (
                        <div key={key} className="flex items-center mb-2">
                          <input
                            type="checkbox"
                            name={key}
                            checked={reasons[key]}
                            onChange={handleReasonsChange}
                            className="mr-2"
                          />
                          <label className="capitalize">{key}</label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end pt-4 p-3 border-t-2 border-gray-300">
              <button onClick={handleClearAll} className="text-red mr-4">Clear all</button>
              <button onClick={handleApply} className="bg-green text-white px-4 py-2 rounded">Apply</button>
            </div>
          </div>
        </div>
      </Transition>
    </div>

  );
};

export default CustomerComplaints;
