import React, { useState } from 'react';
import Chef from '../assets/chef.webp';
import { useNavigate } from 'react-router-dom';
import { FaGoogle, FaEnvelope } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { sendOtp } from '../API/Api';
function LogIn() {
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [isGoogleSignedIn, setIsGoogleSignedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleGoogleSignIn = () => {
        setIsGoogleSignedIn(true);
    };

    const logIn = async () => {
        setLoading(true);
        setErrorMessage('');

        try {
            const phoneNumber = `+91${phone}`;
            sessionStorage.setItem("phoneNumber", phoneNumber)
            const response = await sendOtp(phoneNumber);
            console.log(response);


            if (response && response.message === "OTP sent successfully") {
                alert('OTP sent successfully');
                navigate("/otp"); // Navigate to OTP verification page
            } else {
                setErrorMessage(response?.message || 'Failed to send OTP. Please try again.');
            }
        } catch (error) {
            console.error('Error details:', error.message);
            setErrorMessage('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen grid grid-cols-2">
            <div className="bg-gray-100 flex flex-col p-10">
                <h1 className="text-3xl font-bold mb-2 text-center">Genie Restaurant Application</h1>
                <p className="mb-8 text-center">Your Restaurant’s Success, Our Mission</p>
                <div className="flex justify-center items-center">
                    <img src={Chef} alt="Illustration" className="w-full rounded justify-items-center" style={{ height: '33rem', width: '33rem' }} />
                </div>
            </div>
            <div className="flex items-center justify-center bg-gray-100 p-14">
                <div className="bg-white p-8 rounded shadow-md w-full h-96 mt-5 max-w-md relative">
                    <button className="absolute top-4 right-4 text-gray-500" onClick={() => alert('Close')}>
                        <IoClose size={24} />
                    </button>
                    <h2 className="text-center text-2xl font-semibold mb-4">Login</h2>
                    <div className="flex items-center border rounded mb-4">
                        <span className="px-3 py-2 border-r">+91</span>
                        <input
                            type="tel"
                            value={phone}
                            onChange={handlePhoneChange}
                            className="w-full py-2 px-3 focus:outline-none"
                            placeholder="Phone"
                        />
                    </div>
                    <button
                        className={`bg-green text-white py-2 rounded w-full mb-4 ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green'}`}
                        onClick={logIn}
                        disabled={loading}
                    >
                        {loading ? 'Sending OTP...' : 'Send OTP'}
                    </button>
                    {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
                    <div className="text-center mb-4">or</div>
                    <button className="flex items-center justify-center bg-white text-red border border-red py-2 rounded w-full mb-4 hover:bg-red-50">
                        <FaEnvelope className="mr-2" /> Continue with Email
                    </button>
                    {isGoogleSignedIn ? (
                        <div className="flex items-center justify-center bg-gray-100 py-2 rounded w-full mb-4">
                            <button
                                onClick={handleGoogleSignIn}
                                className="flex items-center justify-center bg-green text-white border border-gray-300 py-2 rounded w-full mb-4"
                            >
                                <FaGoogle className="mr-2" /> Sign in with Google
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={handleGoogleSignIn}
                            className="flex items-center justify-center bg-green text-white border border-gray-300 py-2 rounded w-full mb-4"
                        >
                            <FaGoogle className="mr-2" /> Sign in with Google
                        </button>
                    )}
                    <div className="text-center mt-4">
                        New to Genie? <a href="#" className="text-green">Create account</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogIn;