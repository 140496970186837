import React, { useState, useRef, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { createCampaign } from '../API/Api';

const CreateNewOffer = ({ show, onHide }) => {
  const [campaignStartDate, setCampaignStartDate] = useState('');
  const [campaignEndDate, setCampaignEndDate] = useState('');
  const [userType, setUserType] = useState(0); // Default to New Customer
  const [discountTypeCode, setDiscountTypeCode] = useState(0); // Default to Percentage Discount
  const [discountDescription, setDiscountDescription] = useState({});
  const [discountWindows, setDiscountWindows] = useState([{ campaign_day_code: 0, discount_time_windows: [{ start_time: '', end_time: '' }] }]);

  const offerCanvasRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (offerCanvasRef.current && !offerCanvasRef.current.contains(event.target)) {
        onHide();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onHide]);

  const handleDiscountTypeChange = (e) => {
    const selectedType = parseInt(e.target.value);
    setDiscountTypeCode(selectedType);
    setDiscountDescription({});
  };

  const handleDiscountDescriptionChange = (field, value) => {
    setDiscountDescription((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDiscountWindowChange = (index, timeIndex, field, value) => {
    const updatedWindows = [...discountWindows];
    updatedWindows[index].discount_time_windows[timeIndex][field] = value;
    setDiscountWindows(updatedWindows);
  };

  const handleCreateCampaign = async () => {
    const payload = {
      campaign_start_date: campaignStartDate,
      campaign_end_date: campaignEndDate,
      created_by: 'clxydzwnw000013sckg2u8o0x',
      user_code: userType,
      max_redumptions_per_user: 1,
      redeem_every: 2,
      res_id: 'cly8ckqph1mqunn2glt6t00bu',
      discount_window: discountWindows,
      discount_details: {
        discount_type_code: discountTypeCode,
        discount_description: discountDescription,
      },
    };

    try {
      const response = await createCampaign(payload);
      console.log('Campaign created:', response);
      alert('Campaign created successfully!');
      onHide();
    } catch (error) {
      console.error('Error creating campaign:', error);
      alert('Failed to create campaign.');
    }
  };

  return (
    <div className={`fixed inset-0 z-50 offer ${show ? 'block' : 'hidden'}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div ref={offerCanvasRef} className="fixed right-0 top-0 h-full overflow-y-auto bg-white w-1/3 p-4 shadow-lg">
        <div className="flex justify-between items-center mb-2">
          <h5 className="text-lg font-bold">Create New Offer</h5>
          <button type="button" className="text-gray-600" onClick={onHide}><FaTimes /></button>
        </div>
        <div className="flex flex-col gap-4">
          {/* Campaign Start Date */}
          <div className='border-b-2 border-gray-300 pb-3'>
            <h6 className="font-semibold">Campaign Start Date</h6>
            <input type="date" value={campaignStartDate} onChange={(e) => setCampaignStartDate(e.target.value)} className="w-full px-3 py-2 border rounded mt-2" />
          </div>

          {/* Campaign End Date */}
          <div className='border-b-2 border-gray-300 pb-3'>
            <h6 className="font-semibold">Campaign End Date</h6>
            <input type="date" value={campaignEndDate} onChange={(e) => setCampaignEndDate(e.target.value)} className="w-full px-3 py-2 border rounded mt-2" />
          </div>

          {/* User Type */}
          <div className='border-b-2 border-gray-300 pb-3'>
            <h6 className="font-semibold">User Type</h6>
            <select value={userType} onChange={(e) => setUserType(parseInt(e.target.value))} className="w-full px-3 py-2 border rounded mt-2">
              <option value={0}>New Customer</option>
              <option value={1}>Regular Customer</option>
              <option value={2}>Loyal Customer</option>
              <option value={3}>Returning Customer</option>
              <option value={4}>Infrequent Customer</option>
            </select>
          </div>

          {/* Discount Type */}
          <div className='border-b-2 border-gray-300 pb-3'>
            <h6 className="font-semibold">Discount Type</h6>
            <select value={discountTypeCode} onChange={handleDiscountTypeChange} className="w-full px-3 py-2 border rounded mt-2">
              <option value={0}>Percentage Discount</option>
              <option value={1}>Flat Discount</option>
              <option value={2}>Flat Percentage Discount</option>
              <option value={3}>Predefined Discount</option>
              <option value={4}>Delivery Discount</option>
              <option value={5}>Custom Coupon Discount</option>
              <option value={6}>FreeBie Discount</option>
              <option value={7}>BOGO Discount</option>
            </select>
          </div>

          {/* Discount Description */}
          {discountTypeCode === 0 && (
            <>
              <div className='border-b-2 border-gray-300 pb-3'>
                <h6 className="font-semibold">Percentage</h6>
                <input type="number" value={discountDescription.percentage || ''} onChange={(e) => handleDiscountDescriptionChange('percentage', e.target.value)} className="w-full px-3 py-2 border rounded mt-2" />
              </div>
              <div className='border-b-2 border-gray-300 pb-3'>
                <h6 className="font-semibold">Minimum Order Value</h6>
                <input type="number" value={discountDescription.minimum_order_value || ''} onChange={(e) => handleDiscountDescriptionChange('minimum_order_value', e.target.value)} className="w-full px-3 py-2 border rounded mt-2" />
              </div>
              <div className='border-b-2 border-gray-300 pb-3'>
                <h6 className="font-semibold">Max Discount Value</h6>
                <input type="number" value={discountDescription.max_discount_value || ''} onChange={(e) => handleDiscountDescriptionChange('max_discount_value', e.target.value)} className="w-full px-3 py-2 border rounded mt-2" />
              </div>
            </>
          )}

          {/* Add similar blocks for other discount types based on the discountTypeCode */}

          {/* Discount Time Windows */}
          {discountWindows.map((window, index) => (
            <div key={index} className='border-b-2 border-gray-300 pb-3'>
              <h6 className="font-semibold">Discount Window {index + 1}</h6>
              <select value={window.campaign_day_code} onChange={(e) => handleDiscountWindowChange(index, 0, 'campaign_day_code', e.target.value)} className="w-full px-3 py-2 border rounded mt-2">
                <option value={0}>All Days</option>
                <option value={1}>Sunday</option>
                <option value={2}>Monday</option>
                <option value={3}>Tuesday</option>
                <option value={4}>Wednesday</option>
                <option value={5}>Thursday</option>
                <option value={6}>Friday</option>
                <option value={7}>Saturday</option>
              </select>
              <input type="time" value={window.discount_time_windows[0].start_time} onChange={(e) => handleDiscountWindowChange(index, 0, 'start_time', e.target.value)} className="w-full px-3 py-2 border rounded mt-2" />
              <input type="time" value={window.discount_time_windows[0].end_time} onChange={(e) => handleDiscountWindowChange(index, 0, 'end_time', e.target.value)} className="w-full px-3 py-2 border rounded mt-2" />
            </div>
          ))}

          <button onClick={handleCreateCampaign} className="w-full px-3 py-2 bg-green text-white rounded">
            Create Campaign
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateNewOffer;
