import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faUser, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/logo1.webp"; // Import the custom logo
import chef from "../assets/chef.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useStore from "../zustand";
import { useNavigate } from "react-router-dom";
import { logoutService } from '../API/LogoutService';

const Head = () => {
  const { visible, setVisible, setNoVisible } = useStore()
  const [isOffline, setIsOffline] = useState(true);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [visibleSlides, setVisibleSlides] = useState([true, true, true]);
  const [userName, setUserName] = useState("Kohilam S");
  const [userPhone, setUserPhone] = useState("+91-9442635235");
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [isPhoneEdit, setIsPhoneEdit] = useState(false);
  const [userImage, setUserImage] = useState("https://via.placeholder.com/80");
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const toggleOffline = () => {
    setIsOffline(!isOffline);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleClose = (index) => {
    const newVisibleSlides = [...visibleSlides];
    newVisibleSlides[index] = false;
    setVisibleSlides(newVisibleSlides);

  };
  useEffect(() => {
    if (!visibleSlides[0] && !visibleSlides[1] && !visibleSlides[2]) {
      setVisible(true)
    }
    else {
      setVisible(false)
    }
  }, [visibleSlides])
  // console.log(visible)
  const settings = {
    dots: true,
    infinite: false, // Ensure the slider stops at the end
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false, // Turn off autoplay for better control
    appendDots: (dots) => (
      <div style={{ position: "absolute", bottom: "1px", color: "white" }}>
        <ul> {dots} </ul>
      </div>
    ),
  };

  const allSlidesClosed = visibleSlides.every((slide) => !slide);
  // slider code end
  // notification code start

  const [notificationVisible, setNotificationVisible] = useState(true);

  const handleCloseNotification = () => {
    setNotificationVisible(false);
    setNoVisible(true)
  };

  // profile code start
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setUserImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleNameChange = (event) => setUserName(event.target.value);
  const toggleNameEdit = () => setIsNameEdit(!isNameEdit);

  const handlePhoneChange = (event) => setUserPhone(event.target.value);
  const togglePhoneEdit = () => setIsPhoneEdit(!isPhoneEdit);

  // profile code end

  const logoutFromThisDevice = () => {
    sessionStorage.clear();
    navigate('/')
  }

  const confirmLogout = () => {
    setShowLogoutModal(false);
    logoutService('Session');
  };


  return (
    <>
      <header className="bg-white">
        <div className="container mx-auto px-4 py-2 flex items-center justify-between">
          <div className="flex items-center">
            <img className="h-12 md:h-16" src={logo} alt="Logo" />
          </div>

          <nav className="ml-auto">
            <ul className="flex items-center space-x-4">
              <li className="relative">
                <a href="">
                  <div className="absolute -top-1 right-0 bg-yellow-400 text-xs font-bold px-1 py-0.5 rounded-sm">
                    5
                  </div>
                  <FontAwesomeIcon
                    className="h-5 lg:h-6 p-2 text-black"
                    icon={faBell}
                  />
                </a>
              </li>

              <li className="relative">
                <a href="#">
                  <FontAwesomeIcon
                    className="h-5 lg:h-6 p-2 text-black"
                    icon={faCog}
                  />
                </a>
              </li>
              <li className="relative">
                <button
                  type="button"
                  className={`inline-flex justify-center w-20 md:w-auto rounded-md border border-${isOffline ? "red-600" : "green-600"} shadow-sm px-4 py-2 bg-white text-sm font-medium text-${isOffline ? "red-600" : "green-600"} hover:bg-${isOffline ? "red-700" : "green-700"} focus:outline-none`}
                  onClick={toggleOffline}
                  style={{ borderColor: isOffline ? "#EF4444" : "#34D399" }} // Adjusted border color
                >
                  {isOffline ? "Offline" : "Online"}
                </button>
              </li>
              <li className="relative">
                <div className="user-login inline-block text-left">
                  <button
                    type="button"
                    className={`inline-flex justify-center w-20 md:w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-${isOffline ? "white" : "green-600"} text-sm font-medium text-${isOffline ? "gray-700" : "white"} hover:bg-${isOffline ? "gray-50" : "red-700"} focus:outline-none`}
                    onClick={toggleUserMenu}
                    style={{ backgroundColor: "#2CA721" }} // Background color
                  >
                    <FontAwesomeIcon className="h-6 text-white" icon={faUser} />
                    <span className="ml-2 text-white">User Login</span>
                  </button>
                  {isUserMenuOpen && (
                    <div className="origin-top-right absolute right-0 mt-2 w-72 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="p-4 text-center">
                        <div className="flex justify-center mb-2">
                          <div className="w-20 h-20 rounded-full border border-gray-300 overflow-hidden relative">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageUpload}
                              className="opacity-0 w-full h-full absolute inset-0 cursor-pointer"
                            />
                            <img
                              src={userImage}
                              alt="User"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </div>
                        <div className="font-bold text-lg">
                          {isNameEdit ? (
                            <input
                              type="text"
                              value={userName}
                              onChange={handleNameChange}
                              className="text-center focus:outline-none"
                            />
                          ) : (
                            <span>{userName}</span>
                          )}
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="text-blue-500 ml-2 cursor-pointer"
                            onClick={toggleNameEdit}
                          />
                        </div>
                        <div className="text-gray-500 mb-4">
                          {isPhoneEdit ? (
                            <input
                              type="tel"
                              value={userPhone}
                              onChange={handlePhoneChange}
                              className="text-center focus:outline-none"
                            />
                          ) : (
                            <span>{userPhone}</span>
                          )}
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="text-blue-500 ml-2 cursor-pointer"
                            onClick={togglePhoneEdit}
                          />
                        </div>
                        <button className="w-full bg-red text-white py-2 rounded-md mb-2" onClick={confirmLogout}>
                          Logout
                        </button>
                        <button className="w-full border border-red-500 text-red-500 py-2 rounded-md" onClick={logoutFromThisDevice}>
                          Logout from all devices
                        </button>
                        <div className="mt-4 text-gray-500 text-sm">
                          <a href="#" className="hover:underline">
                            Terms of Service
                          </a>{" "}
                          |
                          <a href="#" className="hover:underline">
                            {" "}
                            Privacy Policy
                          </a>{" "}
                          |
                          <a href="#" className="hover:underline">
                            {" "}
                            Code of Conduct
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {!allSlidesClosed && (
        <section className="slider-section py-2 overflow-hidden">
          <div className="container mx-auto">
            <Slider {...settings}>
              {visibleSlides.map((visible, index) =>
                visible ? (
                  <div
                    key={index}
                    className="slider1"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <img src={chef} alt="Chef" className="h-28 mr-4" />
                    </div>
                    <div className="content-center">
                      <h3 className="text-white text-lg font-bold">
                        Introducing restaurant services
                      </h3>
                      <p className="text-white text-sm ">
                        Fulfill your staffing FSSAI license and trademark needs
                      </p>
                    </div>
                    <div className="flex items-center">
                      <button className="bg-white text-black px-4 py-2 rounded mr-2">
                        Explore
                      </button>
                      <button
                        onClick={() => handleClose(index)}
                        className="text-white text-2xl mx-8"
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                ) : null
              )}
            </Slider>
          </div>
        </section>
      )}

      <section>
        {notificationVisible && (
          <div className="notifi text-white px-4 py-2 flex items-center justify-between bg-red-600">
            <div className="flex-1 text-center">
              <FontAwesomeIcon icon={faBell} className="mr-2" />
              <span className="">
                Please enable notifications for a better experience.{" "}
                <a href="#" className="underline">
                  Click here to know more
                </a>
              </span>
            </div>
            <div className="">
              <button
                onClick={handleCloseNotification}
                className="text-white text-xl mx-8"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Head;
