import React, { useState, useEffect, useRef } from 'react';
import { FaCaretRight, FaCaretDown, FaRupeeSign, FaBackward } from 'react-icons/fa';
import { useMenu } from '../Context/MenuContext';
import { createCampaign } from '../API/Api'; // Import API function

const Freebies = ({ onClose }) => {
  const { menuData: contextMenuData } = useMenu();
  const [freebie, setFreebie] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [minOrderValue, setMinOrderValue] = useState(349);
  const [customerType, setCustomerType] = useState('all');
  const [startDate, setStartDate] = useState('');
  const [runofferButton, setRunofferButton] = useState('allDays');
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(new Set());

  const handleQuantityChange = (amount) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  const handleCategoryClick = (categoryId) => {
    setExpandedCategories(prev => {
      const newSet = new Set(prev);
      newSet.has(categoryId) ? newSet.delete(categoryId) : newSet.add(categoryId);
      return newSet;
    });
  };

  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setFreebie(item.name);
  };

  const freebiesRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (freebiesRef.current && !freebiesRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleActivateFreebie = async () => {
    // Function to activate the selected freebie
    try {
      const payload = {
        campaign_start_date: startDate,
        campaign_end_date: '2024-08-10T00:00:00.000Z',
        created_by: 'your_user_id_here',
        user_code: customerType === 'all' ? 0 : 1, // Example user code based on customer type
        max_redumptions_per_user: 1,
        redeem_every: 2,
        res_id: 'your_restaurant_id_here',
        discount_window: [
          {
            campaign_day_code: 0, // Assuming all days
            discount_time_windows: [
              {
                start_time: '09:00',
                end_time: '11:00',
              },
              {
                start_time: '14:00',
                end_time: '16:00',
              },
            ],
          },
        ],
        discount_details: {
          discount_type_code: 6, // Freebie discount
          discount_description: {
            minimum_order_value: minOrderValue,
            offer_items: [selectedItem.id],
            max_items: quantity,
            order_item_id: [], // Example empty array
            ineligible_cart_item: [] // Example empty array
          }
        }
      };
      const response = await createCampaign(payload);
      console.log('Freebie created:', response);
    } catch (error) {
      console.error('Error creating freebie:', error);
    }
  };

  if (!contextMenuData || !Array.isArray(contextMenuData)) {
    return (
      <div className="fixed inset-0 z-50 offer block" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div ref={freebiesRef} className="fixed right-0 top-0 h-full bg-white w-1/3 p-4 overflow-y-auto shadow-lg">
          <div className="flex justify-between items-center mb-2 border-b-2 border-gray-400">
            <h5 className="text-lg font-bold">Create new freebie offer</h5>
            <button type="button" className="text-gray-600" onClick={onClose}>
              &times;
            </button>
          </div>
          <p className="text-red-500">Error: Menu data is not available.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-50 offer block" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div ref={freebiesRef} className="fixed right-0 top-0 h-full bg-white w-1/3 p-4 overflow-y-auto shadow-lg">
        <div className="flex justify-between items-center mb-2 border-b-2 border-gray-400">
          <h5 className="text-lg font-bold">Create new freebie offer</h5>
          <button type="button" className="text-gray-600" onClick={onClose}>
            &times;
          </button>
        </div>
        {!showAdditionalContent ? (
          <div className="flex flex-col gap-4">
            <div className="relative">
              <label className="block font-semibold mb-1">Choose a freebie</label>
              <p className='text-slate-400 mt-2 text-sm'>Select from eligible freebies in your menu</p>
              <div className="relative w-full mt-2">
                <input
                  type="text"
                  placeholder="Type or search freebie item from menu"
                  className="w-full px-3 py-2 border rounded"
                  value={freebie}
                  onChange={(e) => setFreebie(e.target.value)}
                />
                <FaCaretRight
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-800 cursor-pointer"
                  onClick={() => setShowAdditionalContent(true)}
                />
              </div>
            </div>
            <div className='flex justify-between border-b-2 border-gray-400 pb-3'>
              <label className="block font-semibold mb-1 content-center">Quantity of freebie</label>
              <div className="flex items-center gap-2 border border-green rounded-md p-1">
                <button onClick={() => handleQuantityChange(-1)}>
                  -
                </button>
                <span className="px-1">{quantity}</span>
                <button onClick={() => handleQuantityChange(1)}>
                  +
                </button>
              </div>
            </div>
            <div className='border-b-2 border-gray-400 pb-3'>
              <label className="block font-semibold mb-1">Minimum order value</label>
              <input
                type="number"
                className="w-full px-3 py-2 mt-2 border rounded"
                value={minOrderValue}
                onChange={(e) => setMinOrderValue(e.target.value)}
              />
              <p className='text-slate-400 mt-2 text-sm'>Orders below this amount will not be eligible for this offer. Excludes taxes and charges</p>
            </div>
            <div className="flex flex-col">
              <label className="block font-semibold mb-1">Who will see this?</label>
              <div>
                <input
                  type="radio"
                  id="all-customers"
                  name="customerType"
                  value="all"
                  checked={customerType === 'all'}
                  onChange={(e) => setCustomerType(e.target.value)}
                  className="mr-2"
                />
                <label htmlFor="all-customers" className="mr-4">All customers</label>
                <p className='text-slate-400 mt-1 px-6 text-sm'>All customers can see and apply this promo code</p>
              </div>
              <div>
                <input
                  type="radio"
                  id="new-customers"
                  name="customerType"
                  value="new"
                  checked={customerType === 'new'}
                  onChange={(e) => setCustomerType(e.target.value)}
                  className="mr-2"
                />
                <label htmlFor="new-customers">New customers</label>
                <p className='text-slate-400 mt-1 px-6 text-sm'>Only customers ordering for the first time from your restaurant will be able to see and use this</p>
              </div>
            </div>
            <div>
              <label className="block font-semibold mb-1">Promo visibility</label>
              <h4 className='font-semibold mt-2 mb-2'>Start Time</h4>
              <input type="date" className="w-full px-3 py-2 border rounded" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </div>
            <p className='font-sm text-gray-400 mt-2 px-2 border-b-2 border-gray-200 pb-3'>Your offer will start on {startDate ? new Date(startDate).toLocaleDateString() : 'not set'}. You can stop this offer at any time</p>
            <p className='font-sm font-bold'>Run this offer</p>
            <div className="flex space-x-4 w-full border-b-4 border-gray-200 pb-3">
              <button onClick={() => setRunofferButton('allDays')} className={`px-4 py-2 font-semibold rounded ${runofferButton === 'allDays' ? 'bg-green text-white' : 'outline outline-2 outline-gray-300 text-black'}`}>
                All 7 Days
              </button>
              <button onClick={() => setRunofferButton('monThu')} className={`px-4 py-2 font-semibold rounded ${runofferButton === 'monThu' ? 'bg-green text-white' : 'outline outline-2 outline-gray-300 text-black'}`}>
                Mon - Thu
              </button>
              <button onClick={() => setRunofferButton('friSun')} className={`px-4 py-2 font-semibold rounded ${runofferButton === 'friSun' ? 'bg-green text-white' : 'outline outline-2 outline-gray-300 text-black'}`}>
                Fri - Sun
              </button>
            </div>
            <button onClick={handleActivateFreebie} className="w-full px-3 py-2 bg-green text-white rounded">
              Activate Freebie
            </button>
          </div>
        ) : (
          <div className='flex flex-col gap-4'>
            <div className='mt-4'>
              <button onClick={() => setShowAdditionalContent(false)} className="px-4 py-2 bg-gray-500 text-white rounded"><FaBackward /></button>
            </div>
            {contextMenuData.map((category) => (
              <div key={category.id}>
                <div className='flex items-center p-3 hover:bg-gray-100'>
                  <button
                    onClick={() => handleCategoryClick(category.id)}
                    aria-expanded={expandedCategories.has(category.id)}
                    className="text-left flex items-center py-2 px-1 w-full"
                  >
                    {expandedCategories.has(category.id) ?
                      <FaCaretDown className='text-2xl' /> :
                      <FaCaretRight className='text-2xl' />
                    }
                    {category.name}
                    <p className="ml-auto">{category.menuSubCategories.reduce((acc, subCat) => acc + (subCat.menuItems ? subCat.menuItems.length : 0), 0)} items</p>
                  </button>
                </div>
                {expandedCategories.has(category.id) && category.menuSubCategories.map((subCategory) => (
                  <div key={subCategory.id} className='pl-4'>
                    {subCategory.menuItems.map((item) => (
                      <div key={item.id} className='flex items-center p-3 hover:bg-gray-100'>
                        <label className="flex items-center cursor-pointer w-full">
                          <div className='flex-grow'>
                            <span className='block font-medium'>{item.name}</span>
                            <span className='block text-gray-600 flex mt-2'><FaRupeeSign /> {item.basePrice}</span>
                          </div>
                          <input
                            type="radio"
                            name="menuItem"
                            checked={selectedItem?.id === item.id}
                            onChange={() => handleItemSelect(item)}
                            className="mr-2"
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Freebies;
