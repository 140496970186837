import React, { useState } from 'react';
import { FaLeaf, FaTimes, FaLink } from 'react-icons/fa';


const MenuImage = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [checkedItemId, setCheckedItemId] = useState(null);
    const [checkboxChecked1, setCheckboxChecked1] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleItemClick = (item) => {
        setSelectedItem(item);
        setCheckedItemId(item.id);
        setCheckboxChecked1(false); // Reset checkbox state on item click
        // Reset checkbox state on item click
    };

    const handleCheckboxChange1 = () => {
        setCheckboxChecked1(!checkboxChecked1);
    };
    const handleCloseClick = () => {
        setSelectedItem(null);
        setCheckedItemId(null);
        setCheckboxChecked1(false); // Reset checkbox state on close
        // Reset checkbox state on close
    };

    const sampleMenuItems = [
        { id: 1, title: 'Paneer Tikka', moderatedOn: '2024-07-25', isVeg: true, imageUrl: 'https://via.placeholder.com/150' },
        { id: 2, title: 'Chicken Biryani', moderatedOn: '2024-07-24', isVeg: false, imageUrl: 'https://via.placeholder.com/150' },
        { id: 3, title: 'Veg Burger', moderatedOn: '2024-07-23', isVeg: true, imageUrl: 'https://via.placeholder.com/150' },
        { id: 4, title: 'Fish Fry', moderatedOn: '2024-07-22', isVeg: false, imageUrl: 'https://via.placeholder.com/150' },
        { id: 5, title: 'Mushroom Masala', moderatedOn: '2024-07-21', isVeg: true, imageUrl: 'https://via.placeholder.com/150' },
        { id: 6, title: 'Beef Steak', moderatedOn: '2024-07-20', isVeg: false, imageUrl: 'https://via.placeholder.com/150' },

    ];

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    //   const vegItems = sampleMenuItems.filter(item => item.isVeg);
    //   const nonVegItems = sampleMenuItems.filter(item => !item.isVeg);
    //   const relatedItems = selectedItem?.isVeg ? vegItems : nonVegItems;

    return (
        <div className="container mx-auto p-4">
            <div className="flex flex-col lg:flex-row">
                {/* First Column: Image and Details */}
                <div className="w-full lg:w-2/4 p-2">
                    <div className="flex flex-col gap-4">
                        {sampleMenuItems.map((item) => (
                            <div
                                key={item.id}
                                className="border p-4 rounded-lg shadow flex items-start cursor-pointer"
                                onClick={() => handleItemClick(item)}
                            >
                                <input
                                    type="checkbox"
                                    checked={checkedItemId === item.id}
                                    onChange={() => setCheckedItemId(item.id)}
                                    className="mr-2"
                                />
                                <img src={item.imageUrl} alt={item.title} className="w-20 h-20 object-cover rounded mr-4" />
                                <div>
                                    <div className="flex items-center">
                                        {item.isVeg ? (
                                            // <FaLeaf className="text-green mr-1" />
                                            <img src="https://www.pngkey.com/png/detail/261-2619381_chitr-veg-symbol-svg-veg-and-non-veg.png" alt="" width={20} height={20} />
                                        ) : (
                                            <FaLeaf className="text-red mr-1" />
                                        )}
                                        <span className='px-2'>{item.title}</span>
                                    </div>
                                    <h3 className="text-lg font-semibold mt-2">{item.title}</h3>
                                    <p className="text-sm text-gray-600">Moderated on: {item.moderatedOn}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Second Column: Item Details */}
                {selectedItem && (
                    <div className="w-full lg:w-2/4">
                        <div className="border rounded-lg shadow pb-4">
                            <div className="flex justify-between items-center p-4 border-b border-gray-300 shadow">
                                <h2 className="text-xl font-bold">Item Details</h2>
                                <button
                                    className="text-gray-600 hover:text-gray-900"
                                    onClick={handleCloseClick}
                                >
                                    <FaTimes size={20} />
                                </button>
                            </div>
                            <div className='border-b border-gray-300 shadow pb-2'>
                                <div className="flex items-start mt-4 px-4">
                                    <input
                                        type="checkbox"
                                        checked={checkboxChecked1}
                                        onChange={handleCheckboxChange1}
                                        className="mr-2"
                                    />
                                    <img
                                        src={selectedItem.imageUrl}
                                        alt={selectedItem.title}
                                        className="w-60 h-40 object-cover rounded mb-4"
                                    />
                                </div>
                                <h3 className="text-lg font-semibold px-8">{selectedItem.title}</h3>
                                <p className="text-sm text-gray-600 px-8">Moderated on: {selectedItem.moderatedOn}</p>
                            </div>
                            {/* <div className="flex items-start mb-4 p-4 border-b border-gray-300">
                <input
                  type="checkbox"
                  checked={checkboxChecked2}
                  onChange={handleCheckboxChange2}
                  className="mr-2"
                />
                <img
                  src={selectedItem.imageUrl}
                  alt={selectedItem.title}
                  className="w-60 h-40 object-cover rounded mr-4"
                />
                <div>
                  <p className="text-sm text-gray-600 mb-2">Showing on:</p>
                  <div className="flex flex-col gap-2">
                  {relatedItems.map((item) => (
                    <div key={item.id} className="flex items-center">
                      <FaLeaf className={`text-${item.isVeg ? 'green' : 'red'} mr-2`} />
                      <span>{item.title}</span>
                    </div>
                  ))}
                </div>
                </div>
              </div> */}
                            <div className="flex mt-4 space-x-2 px-4">
                                <button className="border border-green text-green px-4 py-2 rounded-md flex items-center">
                                    <FaLink className="mr-2" />
                                    Map to Other Item
                                </button>
                                <button
                                    className={`px-4 py-2 rounded-md flex items-center ${checkboxChecked1 ? 'bg-green text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                                    disabled={!checkboxChecked1} onClick={handleModalOpen}
                                >
                                    <FaLink className="mr-2" />
                                    Unmap Image
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {isModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black">
                        <div className="bg-white rounded-lg p-6 w-full max-w-md">
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl font-bold">Unmap image(s) from the item</h2>
                                <button
                                    className="text-gray-600 hover:text-gray-900"
                                    onClick={handleModalClose}
                                >
                                    <FaTimes size={20} />
                                </button>
                            </div>
                            <p className="mt-4 text-gray-700">Are you sure you want to unmap the selected image(s) from this item?</p>
                            <div className="flex justify-end mt-6 space-x-2">
                                <button
                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
                                    onClick={handleModalClose}
                                >
                                    Cancel
                                </button>
                                <button className="bg-green text-white px-4 py-2 rounded-md">
                                    Remove Image from Item
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};


export default MenuImage;
