import React from 'react';

function Payments() {
    return (
        <div>
            <div className='m-5 border rounded-lg overflow-hidden'>
                <div className='bg-gray-100 px-3 py-3 text-lg font-semibold'>Payments</div>
                <div className='m-4 flex space-x-3'>
                    <video width="300" height="300" className='rounded-lg overflow-hidden' controls>
                        <source src="https://i.ytimg.com/vi/k8IGHY4fzok/hq720.webp?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB3JPI0Q-rBiqxMHtNegt9cjgwNvA" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className='p-1'>                    <p className='py-2 font-semibold'>How to Check Weekly Payout Thought statement of Account</p>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem reprehenderit ipsam fuga eius vel dolor corporis officia, doloribus possimus saepe</p></div>
                </div>
                <hr className='m-2' />
                <div className='mx-4 my-7 space-y-5'>
                    <p className='text-gray-600 py-4'>view Your Payment Details And Invoices And Business History</p>
                    <button className='bg-blue-600 text-white p-2 px-5  text-lg rounded-lg'>view payout details</button>
                </div>
            </div>

        </div>
    )
}

export default Payments