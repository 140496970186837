import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

   const IndependentPricing = ({ formData, setFormData }) => {
     const [options, setOptions] = useState([{ value: '', additionalPrice: '' }]);
     const [variants, setVariants] = useState([{ value: '', additionalPrice: '' }]);
     const [addons, setAddons] = useState([{ value: '', additionalPrice: '' }]);

     const handleInputChange = (e, index, type) => {
       const { name, value } = e.target;
       const list = type === 'options' ? options : type === 'variants' ? variants : addons;
       list[index][name] = value;
       type === 'options' ? setOptions([...list]) : type === 'variants' ? setVariants([...list]) : setAddons([...list]);
     };

     const handleAddField = (type) => {
       const newField = { value: '', additionalPrice: '' };
       type === 'options' ? setOptions([...options, newField])
         : type === 'variants' ? setVariants([...variants, newField])
         : setAddons([...addons, newField]);
     };

     const handleSubmit = (e) => {
       e.preventDefault();
       setFormData({ ...formData, options, variants, addons });
       toast.success('Independent Pricing data submitted!');
     };

     return (
       <div className="mb-4">
         <h2 className="text-xl font-bold">Independent Pricing Structure</h2>

         <label className="block text-gray-700">Create Options</label>
         {options.map((option, index) => (
           <div key={index} className="flex mb-2">
             <input
               type="text"
               name="value"
               value={option.value}
               onChange={(e) => handleInputChange(e, index, 'options')}
               className="w-full p-2 border border-gray-300 rounded mr-2"
               placeholder="Option"
               required
             />
             <input
               type="number"
               name="additionalPrice"
               value={option.additionalPrice}
               onChange={(e) => handleInputChange(e, index, 'options')}
               className="w-full p-2 border border-gray-300 rounded"
               placeholder="Additional Price"
               required
             />
           </div>
         ))}
         <button
           type="button"
           onClick={() => handleAddField('options')}
           className="bg-blue-500 text-white p-2 rounded mb-4"
         >
           Add Another Option
         </button>

         <label className="block text-gray-700">Create Variants</label>
         {variants.map((variant, index) => (
           <div key={index} className="flex mb-2">
             <input
               type="text"
               name="value"
               value={variant.value}
               onChange={(e) => handleInputChange(e, index, 'variants')}
               className="w-full p-2 border border-gray-300 rounded mr-2"
               placeholder="Variant"
               required
             />
             <input
               type="number"
               name="additionalPrice"
               value={variant.additionalPrice}
               onChange={(e) => handleInputChange(e, index, 'variants')}
               className="w-full p-2 border border-gray-300 rounded"
               placeholder="Additional Price"
               required
             />
           </div>
         ))}
         <button
           type="button"
           onClick={() => handleAddField('variants')}
           className="bg-blue-500 text-white p-2 rounded mb-4"
         >
           Add Another Variant
         </button>

         <label className="block text-gray-700">Create Add-Ons</label>
         {addons.map((addon, index) => (
           <div key={index} className="flex mb-2">
             <input
               type="text"
               name="value"
               value={addon.value}
               onChange={(e) => handleInputChange(e, index, 'addons')}
               className="w-full p-2 border border-gray-300 rounded mr-2"
               placeholder="Add-On"
               required
             />
             <input
               type="number"
               name="additionalPrice"
               value={addon.additionalPrice}
               onChange={(e) => handleInputChange(e, index, 'addons')}
               className="w-full p-2 border border-gray-300 rounded"
               placeholder="Additional Price"
               required
             />
           </div>
         ))}
         <button
           type="button"
           onClick={() => handleAddField('addons')}
           className="bg-blue-500 text-white p-2 rounded"
         >
           Add Another Add-On
         </button>

         <button
           type="button"
           onClick={handleSubmit}
           className="mt-4 bg-green-500 text-white p-2 rounded"
         >
           Complete
         </button>
       </div>
     );
   };

   export default IndependentPricing;