// src/store.js
import create from 'zustand';

const useStore = create(set => ({
visible:false,
setVisible:(data)=>set({visible:data}),
novisible:false,
setNoVisible:(data)=>set({novisible:data})
}));

export default useStore;
