import React, { useState } from 'react';
import { FaDownload, FaSignal, FaInfoCircle, FaArrowCircleRight, FaUser, FaCaretUp, FaArrowDown } from 'react-icons/fa';
import { AiFillCaretDown } from 'react-icons/ai';
import { FaFilter } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import { Line, Bar } from 'react-chartjs-2';
import { FaThLarge, FaChartLine } from 'react-icons/fa';
import { Chart as ChartJS, LineElement, BarElement, PointElement, LinearScale, Title, CategoryScale, Legend, Tooltip, BarController } from 'chart.js';

ChartJS.register( LineElement, BarElement, PointElement, LinearScale, Title, CategoryScale, Legend, Tooltip, BarController  );

const sections = [
  { text: 'Impressions', bgClass: 'bg-lime-50', percentage: '+50%', icon: <FaCaretUp className="text-green" />, fontBold: '3' },
  { text: 'Impressions to menu', bgClass: 'bg-gray-200', percentage: '0%', icon: null, fontBold: '0.0%' },
  { text: 'Menu to cart', bgClass: 'bg-gray-200', percentage: '0%', icon: null, fontBold: '0.0%' },
  { text: 'Cart to order', bgClass: 'bg-gray-200', percentage: '0%', icon: null, fontBold: '0.0%' },
];
const filterOptions = {
  date: ["Today", "Yesterday", "This week", "Last week", "This month", "Last month"],
  legalEntity: ["Legal Entity 1", "Legal Entity 2"],
  chain: ["Chain 1", "Chain 2"],
  city: ["City 1", "City 2"],
  zone: ["Zone 1", "Zone 2"],
  subzone: ["Subzone 1", "Subzone 2"],
  outlet: ["Outlet 1", "Outlet 2"],
};

function Reporting() {
  // const [activeTab, setActiveTab] = useState('liveTracking');
  const [activeTab, setActiveTab] = useState('liveTracking');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('date');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedDate, setSelectedDate] = useState('');
  const [fathLarge, setFathLarge] = useState('grid');
  const [expandedMetrics, setExpandedMetrics] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);


  const handleFilterChange = (filter, value) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [filter]: value,
    }));
  };

  const handleApplyFilters = () => {
    // Handle the logic to show the content based on the selected filters
    console.log(selectedOptions);
    setSelectedDate(selectedOptions.date || '');
    setIsFilterOpen(false);
  };

  const csvData = [
    ["header1", "header2", "header3"],
    ["data1", "data2", "data3"],
    ["data4", "data5", "data6"]
  ];

  const handleViewBusinessReports = () => {
    setActiveTab('businessReports');
  };

 
  const details = () => {
    navigate("/details")
  }

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    datasets: [
      {
        label: 'Sales',
        data: [0, 0, 0, 0, 0, 0, 0],
        borderColor: '#2ca721',
        backgroundColor: '#2ca721',
        borderWidth: 2,
        pointRadius: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const chartData = {
    labels: ['', '', '', '', '', '', ''],
    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0],
        borderColor: '#2ca721',
        borderWidth: 2,
        pointRadius: 2,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0, // Disable bezier curves
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const customerFunnel = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0, // Disable bezier curves
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data1 = {
    labels: ['Week 21', 'Week 22', 'Week 23', 'Week 24', 'Week 25', 'Week 26', 'Week 27', 'Week 28'],
    datasets: [
      {
        label: 'Trend',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        fill: true,
        backgroundColor: '#2ca721',
        borderColor: '#2ca721',
      },
    ],
  };

  const options1 = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const metricsData = [
    { metric: 'Average rating', data: [0, 0, 0, 0, 0, 1.2, 0] },
    { metric: 'Bad orders', data: [0, 0, 0, 0, 0, 100, 0] },
    { metric: 'Lost sales', data: [0, 0, 0, 0, 0, 554, 0] },
    { metric: 'Online %', data: [0, 0, 0, 0, 0.1, 11.9, 0] },
    { metric: 'Kitchen preparation time', data: [0, 0, 0, 0, 0, 0, 0] }
  ];

  const options2 = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const handleRowClick = (metric) => {
    const isExpanded = expandedRows.includes(metric);
    if (isExpanded) {
      setExpandedRows(expandedRows.filter((row) => row !== metric));
    } else {
      setExpandedRows([...expandedRows, metric]);
    }
  };

  const toggleMetricExpansion = (metricName) => {
    if (expandedMetrics.includes(metricName)) {
      setExpandedMetrics(expandedMetrics.filter((name) => name !== metricName));
    } else {
      setExpandedMetrics([...expandedMetrics, metricName]);
    }
  };
  // Sample data
  const metricsData1 = [
    {
      title: 'Impressions',
      chartValues: [140, 149, 154, 139, 117, 104, 259, 110],
      weeklyValues: [140, 149, 154, 139, 117, 104, 259, 110],
    },
    {
      title: 'Impressions to menu',
      chartValues: [0, 2.7, 1.3, 1.4, 0, 1, 6.2, 0],
      weeklyValues: [0, 2.7, 1.3, 1.4, 0, 1, 6.2, 0],
    },
    {
      title: 'Menu to order',
      chartValues: [0, 0, 0, 0, 0, 12.5, 0, 0],
      weeklyValues: [0, 0, 0, 0, 0, 12.5, 0, 0],
    },
    {
      title: 'New users',
      chartValues: [0, 0, 0, 0, 0, 100, 0, 0],
      weeklyValues: [0, 0, 0, 0, 0, 100, 0, 0],
    },
  ];
  const chartConfig = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const handleExpansionToggle = (metricTitle) => {
    if (expandedItems.includes(metricTitle)) {
      setExpandedItems(expandedItems.filter((item) => item !== metricTitle));
    } else {
      setExpandedItems([...expandedItems, metricTitle]);
    }
  };
  const reportData = [
    {
      name: 'Views',
      chartData: [140, 149, 154, 139, 117, 104, 259, 110],
      weeklyValues: [140, 149, 154, 139, 117, 104, 259, 110],
    },
    {
      name: 'Clicks to menu',
      chartData: [0, 2.7, 1.3, 1.4, 0, 1, 6.2, 0],
      weeklyValues: [0, 2.7, 1.3, 1.4, 0, 1, 6.2, 0],
    },
    {
      name: 'Menu to purchase',
      chartData: [0, 0, 0, 0, 0, 12.5, 0, 0],
      weeklyValues: [0, 0, 0, 0, 0, 12.5, 0, 0],
    },
    {
      name: 'New customers',
      chartData: [0, 0, 0, 0, 0, 100, 0, 0],
      weeklyValues: [0, 0, 0, 0, 0, 100, 0, 0],
    },
  ];
  const chartOptions3 = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const chartOptions4 = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };
  const averageOrderValueData = {
    labels: ['Week 21', 'Week 22', 'Week 23', 'Week 24', 'Week 25', 'Week 26', 'Week 27', 'Week 28'],
    datasets: [
      {
        label: 'Average order value',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: '#2ca721',
        backgroundColor: '#2ca721',
        tension: 0.1,
      },
    ],
  };
  const salesOrdersData = {
    labels: ['Week 21', 'Week 22', 'Week 23', 'Week 24', 'Week 25', 'Week 26', 'Week 27', 'Week 28'],
    datasets: [
      {
        label: 'Sales',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.1,
      },
      {
        label: 'Orders',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: 'rgba(54, 162, 235, 0.2)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        tension: 0.1,
      },
    ],
  };
  const ratedOrdersData = {
    labels: ['Week 21', 'Week 22', 'Week 23', 'Week 24', 'Week 25', 'Week 26', 'Week 27', 'Week 28'],
    datasets: [
      {
        label: 'Rated orders',
        data: [0, 0, 0, 0, 0, 0, 1.21, 0],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 1,
      },
      {
        label: 'Average rating',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 0.2)',
        type: 'line',
      },
    ],
  };
  const badOrdersData = {
    labels: ['Week 21', 'Week 22', 'Week 23', 'Week 24', 'Week 25', 'Week 26', 'Week 27', 'Week 28'],
    datasets: [
      {
        label: 'Bad orders',
        data: [0, 0, 0, 0, 0, 0, 100, 0],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: false,
        tension: 0.1,
      },
      {
        label: 'Delayed orders',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: 'rgba(255, 159, 64, 0.2)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        fill: false,
        tension: 0.1,
      },
      {
        label: 'Poor rated orders',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: 'rgba(255, 205, 86, 0.2)',
        backgroundColor: 'rgba(255, 205, 86, 0.2)',
        fill: false,
        tension: 0.1,
      },
      {
        label: 'Rejected orders',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: 'rgba(75, 192, 192, 0.2)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: false,
        tension: 0.1,
      },
      {
        label: 'Complaints',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: 'rgba(153, 102, 255, 0.2)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        fill: false,
        tension: 0.1,
      },
    ],
  };
  const chartOptions5 = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };
  const chartOptions6 = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };
  const impressionsData = {
    labels: ['Week 21', 'Week 22', 'Week 23', 'Week 24', 'Week 25', 'Week 26', 'Week 27', 'Week 28'],
    datasets: [
      {
        label: 'Impressions',
        data: [140, 149, 154, 139, 117, 104, 259, 110],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 1,
        fill: false,
        tension: 0.1,
      },
    ],
  };
  const impressionsToMenuData = {
    labels: ['Week 21', 'Week 22', 'Week 23', 'Week 24', 'Week 25', 'Week 26', 'Week 27', 'Week 28'],
    datasets: [
      {
        label: 'Impressions to menu',
        data: [0, 2.7, 1.3, 1.4, 0, 1, 6.2, 0],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1,
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const navigate = useNavigate();
  const Details = () => {
    navigate("/details")
  }

  return (
    <>
      <div className="min-h-screen">
        <div className="flex items-center justify-between border-b-2  p-3">
          <div className="flex items-center space-x-4">
            <button onClick={() => setActiveTab('liveTracking')} className={`text-lg font-semibold ${activeTab === 'liveTracking' ? 'text-green' : 'text-gray-400'}`}>
              Live tracking
            </button>
            <button onClick={() => setActiveTab('businessReports')} className={`text-lg font-semibold ${activeTab === 'businessReports' ? 'text-green' : 'text-gray-400'}`}>
              Business reports
            </button>
          </div>
          {activeTab === 'liveTracking' ? (
            <button onClick={() => setIsFilterOpen(true)} className="flex items-center px-4 py-2 bg-green text-white rounded">
              <span className="mr-2 text-white"><FaFilter /></span>
              All outlets
              <AiFillCaretDown className="ml-2 text-white" />
            </button>
          ) : (
            <div className="flex items-center space-x-4">
              <CSVLink data={csvData} filename="report.csv">
                <button className="flex items-center px-4 py-2 bg-green text-white rounded">
                  <span className="mr-2 text-white"><FaDownload /></span>
                  Download
                </button>
              </CSVLink>
              <button onClick={() => setIsFilterOpen(true)} className="px-4 py-2 bg-green flex items-center text-white rounded">
                Happy Bites <AiFillCaretDown className="ml-2 text-white" />
              </button>
            </div>
          )}
        </div>
        <div>
          {activeTab === 'liveTracking' ? (
            <div>
              <div className="p-4">
                <div className="bg-white shadow rounded-lg mb-6">
                  <div className="flex justify-between items-center mb-4 border-b border-gray-300 p-3">
                    <div className="flex items-center">
                      <FaSignal className="text-green mr-2" />
                      <h2 className="text-lg font-semibold">Sales overview</h2>
                      <FaInfoCircle className="text-gray-500 ml-2" />
                    </div>
                    <button className="text-green flex items-center" onClick={Details}>
                      View Details <FaArrowCircleRight className="ml-2" />
                    </button>
                  </div>
                  <div className="grid grid-cols-5 items-center p-2 border-b border-gray-300 mb-2">
                    <span className="col-span-1">Sales</span>
                    <div className="col-span-4 flex items-center justify-between space-x-2">
                      <div className="flex-grow flex items-center justify-center">
                        <div className="w-60 h-6">
                          <Line data={data} options={{ ...options, elements: { line: { borderColor: 'green' } } }} />
                        </div>
                      </div>
                      <div className="flex items-center space-x-8">
                        <span className="font-bold">₹0</span>
                        <span className="bg-gray-200 px-6 py-1 rounded">0%</span>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-5 items-center p-2 border-b border-gray-300 mb-2">
                    <span className="col-span-1">Delivered Order</span>
                    <div className="col-span-4 flex items-center justify-between space-x-2">
                      <div className="flex-grow flex items-center justify-center">
                        <div className="w-60 h-6">
                          <Line data={data} options={{ ...options, elements: { line: { borderColor: 'green' } } }} />
                        </div>
                      </div>
                      <div className="flex items-center space-x-8">
                        <span className="font-bold">₹0</span>
                        <span className="bg-gray-200 px-6 py-1 rounded">0%</span>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-5 items-center p-2 border-b border-gray-300 mb-2">
                    <span className="col-span-1">AOV</span>
                    <div className="col-span-4 flex items-center justify-between space-x-2">
                      <div className="flex-grow flex items-center justify-center">
                        <div className="w-60 h-6">
                          <Line data={data} options={{ ...options, elements: { line: { borderColor: 'green' } } }} />
                        </div>
                      </div>
                      <div className="flex items-center space-x-8">
                        <span className="font-bold">₹0</span>
                        <span className="bg-gray-200 px-6 py-1 rounded">0%</span>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-5 items-center p-2 border-b border-gray-300 mb-2">
                    <span className="col-span-1">Market Share</span>
                    <div className="col-span-4 flex items-center justify-between space-x-4">
                      <div className="flex-grow flex items-center justify-center">
                        <div className="w-60 h-6 ml-12">
                          <Line data={data} options={{ ...options, elements: { line: { borderColor: 'green' } } }} />
                        </div>
                      </div>
                      <div className="flex items-center space-x-8">
                        <button className="text-green"  onClick={handleViewBusinessReports}>View business reports</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <div className="bg-white shadow rounded mb-2">
                  <div className="border-b border-gray-300 p-3">
                    <div className="flex items-center">
                      <FaUser className="text-green mr-2" />
                      <h2 className="text-lg font-semibold">Customer Experience</h2>
                      <FaInfoCircle className="text-gray-500 ml-2" />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="grid grid-cols-5 items-center p-3 border-b border-gray-300 mb-2">
                      <span className="col-span-1">Ratings</span>
                      <div className="col-span-4 flex items-center justify-between space-x-2">
                        <div className="flex-grow flex items-center justify-center">
                          <div className="w-60 h-6">
                            <Line data={chartData} options={chartOptions} height={30} />
                          </div>
                        </div>
                        <div className="flex items-center space-x-8">
                          <span className="font-bold">₹0</span>
                          <span className="bg-gray-200 px-6 py-1 rounded">0%</span>
                        </div>
                      </div>
                    </div>
                    <h3 className="text-md font-medium mb-2 px-3">Bad orders</h3>
                    <div className="grid grid-cols-5 items-center p-3">
                      <span className="col-span-1 px-6">Rejected Order</span>
                      <div className="col-span-4 flex items-center justify-between space-x-2">
                        <div className="flex-grow flex items-center justify-center">
                          <div className="w-60 h-6">
                            <Line data={chartData} options={chartOptions} height={30} />
                          </div>
                        </div>
                        <div className="flex items-center space-x-8">
                          <span className="font-bold">0.0%</span>
                          <span className="bg-gray-200 px-6 py-1 rounded">0%</span>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-5 items-center p-3">
                      <span className="col-span-1 px-6">Delayed Order</span>
                      <div className="col-span-4 flex items-center justify-between space-x-2">
                        <div className="flex-grow flex items-center justify-center">
                          <div className="w-60 h-6">
                            <Line data={chartData} options={chartOptions} height={30} />
                          </div>
                        </div>
                        <div className="flex items-center space-x-8">
                          <span className="font-bold">0.0%</span>
                          <span className="bg-gray-200 px-6 py-1 rounded">0%</span>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-5 items-center p-3">
                      <span className="col-span-1 px-6">Poor rate Order</span>
                      <div className="col-span-4 flex items-center justify-between space-x-2">
                        <div className="flex-grow flex items-center justify-center">
                          <div className="w-60 h-6">
                            <Line data={chartData} options={chartOptions} height={30} />
                          </div>
                        </div>
                        <div className="flex items-center space-x-8">
                          <span className="font-bold">0.0%</span>
                          <span className="bg-gray-200 px-6 py-1 rounded">0%</span>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-5 items-center p-3">
                      <span className="col-span-1 px-6">Complaints</span>
                      <div className="col-span-4 flex items-center justify-between space-x-2">
                        <div className="flex-grow flex items-center justify-center">
                          <div className="w-60 h-6">
                            <Line data={chartData} options={chartOptions} height={30} />
                          </div>
                        </div>
                        <div className="flex items-center space-x-8">
                          <button className="text-green" onClick={handleViewBusinessReports}>
                            View business reports
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-5 items-center p-3">
                      <span className="col-span-1 px-6">Lost sales</span>
                      <div className="col-span-4 flex items-center justify-between space-x-2">
                        <div className="flex-grow flex items-center justify-center">
                          <div className="w-60 h-6">
                            <Line data={chartData} options={chartOptions} height={30} />
                          </div>
                        </div>
                        <div className="flex items-center space-x-8">
                          <span className="font-bold">0.0%</span>
                          <span className="bg-gray-200 px-6 py-1 rounded">0%</span>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-5 items-center p-3">
                      <span className="col-span-1 px-6">Online %</span>
                      <div className="col-span-4 flex items-center justify-between space-x-2">
                        <div className="flex-grow flex items-center justify-center">
                          <div className="w-60 h-6">
                            <Line data={chartData} options={chartOptions} height={30} />
                          </div>
                        </div>
                        <div className="flex items-center space-x-8">
                          <button className="text-green" onClick={handleViewBusinessReports}>
                            View business reports
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4">
                <div className="bg-white shadow rounded mb-2">
                  <div className="border-b border-gray-300 p-3">
                    <div className="flex items-center">
                      <FaFilter className="text-green mr-2" />
                      <h2 className="text-lg font-semibold">Customer funnel</h2>
                      <FaInfoCircle className="text-gray-500 ml-2" />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="p-3">
                      {sections.map((item, index) => (
                        <div className="grid grid-cols-5 items-center p-3 relative" key={index}>
                          <div className="col-span-1 flex items-center relative">
                            <div className="flex flex-col items-center">
                              <div className="flex items-center justify-center bg-green rounded-full w-8 h-8">
                                <FaArrowDown className="text-white" />
                              </div>
                              {index < sections.length - 1 && (
                                <div className="flex-grow w-px h-full border-dotted border-l-2 border-green absolute top-full"></div>
                              )}
                            </div>
                            <span className="px-2 ml-4">{item.text}</span>
                          </div>
                          <div className="col-span-4 flex items-center justify-between space-x-2">
                            <div className="flex-grow flex items-center justify-center">
                              <div className="w-60 h-6">
                                <Line data={chartData} options={chartOptions} height={30} />
                              </div>
                            </div>
                            <div className="flex items-center space-x-8">
                              <span className="font-bold">{item.fontBold}</span>
                              <span className={`${item.bgClass} px-6 py-1 rounded flex items-center`}>
                                {item.percentage}
                                {item.icon}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="grid grid-cols-5 items-center p-3 border-b border-gray-300">
                      <span className="col-span-1 px-6">New User</span>
                      <div className="col-span-4 flex items-center justify-between space-x-2">
                        <div className="flex-grow flex items-center justify-center">
                          <div className="w-60 h-6">
                            <Line data={chartData} options={chartOptions} height={30} />
                          </div>
                        </div>
                        <div className="flex items-center space-x-8">
                          <button className="text-green" onClick={handleViewBusinessReports}>
                            View business reports
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-5 items-center p-3 border-b border-gray-300">
                      <span className="col-span-1 px-6">Repeat Users</span>
                      <div className="col-span-4 flex items-center justify-between space-x-2">
                        <div className="flex-grow flex items-center justify-center">
                          <div className="w-60 h-6">
                            <Line data={chartData} options={chartOptions} height={30} />
                          </div>
                        </div>
                        <div className="flex items-center space-x-8">
                          <button className="text-green" onClick={handleViewBusinessReports}>
                            View business reports
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-5 items-center p-3 ">
                      <span className="col-span-1 px-6">Lapsed User</span>
                      <div className="col-span-4 flex items-center justify-between space-x-2">
                        <div className="flex-grow flex items-center justify-center">
                          <div className="w-60 h-6">
                            <Line data={chartData} options={chartOptions} height={30} />
                          </div>
                        </div>
                        <div className="flex items-center space-x-8">
                          <button className="text-green" onClick={handleViewBusinessReports}>
                            View business reports
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          ) : (
            <div>
              {activeTab === 'businessReports' && (
              <div className="p-4">
                <div className="flex">
                  <button
                    className={`flex items-center justify-center w-16 h-10 rounded-l-full ${fathLarge === 'grid' ? 'bg-green' : 'bg-gray-100'} focus:outline-none`}
                    onClick={() => setFathLarge('grid')}>
                    <FaThLarge className={`text-xl ${fathLarge === 'grid' ? 'text-white' : 'text-gray-500'}`} />
                  </button>
                  <button
                    className={`flex items-center justify-center w-16 h-10 rounded-r-full ${fathLarge === 'chart' ? 'bg-green' : 'bg-gray-100'} focus:outline-none`}
                    onClick={() => setFathLarge('chart')}>
                    <FaChartLine className={`text-xl ${fathLarge === 'chart' ? 'text-white' : 'text-gray-500'}`} />
                  </button>
                </div>
                <div>
                  {fathLarge === 'grid' ? (
                    <div>
                      <div className="p-6">
                        <div className="bg-white shadow rounded p-6">
                          <h2 className="text-lg font-semibold mb-4">Sales overview</h2>
                          <div className="overflow-x-auto">
                            <table className="min-w-full bg-white border border-gray-200">
                              <thead>
                                <tr>
                                  <th className="px-4 py-2 border">Metric</th>
                                  <th className="px-4 py-2 border">Week 21 to Week 28 trend</th>
                                  <th className="px-4 py-2 border">Week 21</th>
                                  <th className="px-4 py-2 border">Week 22</th>
                                  <th className="px-4 py-2 border">Week 23</th>
                                  <th className="px-4 py-2 border">Week 24</th>
                                  <th className="px-4 py-2 border">Week 25</th>
                                  <th className="px-4 py-2 border">Week 26</th>
                                  <th className="px-4 py-2 border">Week 27</th>
                                  <th className="px-4 py-2 border">Week 28</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="px-4 py-2 border" colSpan="10">
                                    <div className="flex justify-between items-center">
                                      <span>Sales overview</span>
                                      <button className="text-green">View Details</button>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-4 py-2 border">Sales</td>
                                  <td className="px-4 py-2 border">
                                    <Line data={data1} options={options1} width={100} height={20} />
                                  </td>
                                  <td className="px-4 py-2 border">₹0</td>
                                  <td className="px-4 py-2 border">₹0</td>
                                  <td className="px-4 py-2 border">₹0</td>
                                  <td className="px-4 py-2 border">₹0</td>
                                  <td className="px-4 py-2 border">₹0</td>
                                  <td className="px-4 py-2 border">₹0</td>
                                  <td className="px-4 py-2 border">₹0</td>
                                  <td className="px-4 py-2 border">₹0</td>
                                </tr>
                                <tr>
                                  <td className="px-4 py-2 border">Delivered orders</td>
                                  <td className="px-4 py-2 border">
                                    <Line data={data1} options={options1} width={100} height={20} />
                                  </td>
                                  <td className="px-4 py-2 border">0</td>
                                  <td className="px-4 py-2 border">0</td>
                                  <td className="px-4 py-2 border">0</td>
                                  <td className="px-4 py-2 border">0</td>
                                  <td className="px-4 py-2 border">0</td>
                                  <td className="px-4 py-2 border">0</td>
                                  <td className="px-4 py-2 border">0</td>
                                  <td className="px-4 py-2 border">0</td>
                                </tr>
                                <tr>
                                  <td className="px-4 py-2 border">Average order value</td>
                                  <td className="px-4 py-2 border">
                                    <Line data={data1} options={options1} width={100} height={20} />
                                  </td>
                                  <td className="px-4 py-2 border">₹0</td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">0%</td>
                                  <td className="px-4 py-2 border">0%</td>
                                </tr>
                                <tr>
                                  <td className="px-4 py-2 border">Market share</td>
                                  <td className="px-4 py-2 border">
                                    <Line data={data1} options={options1} width={100} height={20} />
                                  </td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">-</td>
                                  <td className="px-4 py-2 border">0%</td>
                                  <td className="px-4 py-2 border">0%</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>


                      <div className="p-6">
                        <div className="overflow-auto bg-white shadow rounded p-6">
                          <table className="min-w-full bg-white border">
                            <thead>
                              <tr>
                                <th className="p-2 border">Metric</th>
                                <th className="p-2 border">Week 21 to Week 28 trend</th>
                                {[...Array(8).keys()].map((week) => (
                                  <th key={week} className="p-2 border">{`Week ${21 + week}`}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {metricsData.map((metric) => (
                                <React.Fragment key={metric.metric}>
                                  <tr className="border" onClick={() => handleRowClick(metric.metric)}>
                                    <td className="p-2 cursor-pointer">{metric.metric}</td>
                                    <td className="p-2">
                                      <Line
                                        data={{
                                          labels: [...Array(8).keys()].map((week) => `Week ${21 + week}`),
                                          datasets: [
                                            {
                                              label: metric.metric,
                                              data: metric.data,
                                              fill: false,
                                              borderColor: '#2ca721',
                                              tension: 0.1
                                            }
                                          ]
                                        }}
                                        options={options2}
                                        height={40}
                                      />
                                    </td>
                                    {metric.data.map((data, index) => (
                                      <td key={index} className="p-2 border">{data}</td>
                                    ))}
                                  </tr>
                                  {expandedRows.includes(metric.metric) && (
                                    <tr>
                                      <td colSpan="10" className="p-2 bg-gray-100">
                                        <div>Additional details for {metric.metric}</div>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>


                      <div className="p-4">
                        <div className="overflow-auto bg-white shadow rounded p-6">
                          <table className="min-w-full bg-white border">
                            <thead>
                              <tr>
                                <th className="p-2 border">Metric</th>
                                <th className="p-2 border">Week 21 to Week 28 trend</th>
                                {[...Array(8).keys()].map((week) => (
                                  <th key={week} className="p-2 border">{`Week ${21 + week}`}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {reportData.map((metric) => (
                                <React.Fragment key={metric.name}>
                                  <tr className="border" onClick={() => toggleMetricExpansion(metric.name)}>
                                    <td className="p-2 cursor-pointer">{metric.name}</td>
                                    <td className="p-2">
                                      <Line
                                        data={{
                                          labels: [...Array(8).keys()].map((week) => `Week ${21 + week}`),
                                          datasets: [
                                            {
                                              label: metric.name,
                                              data: metric.chartData,
                                              fill: false,
                                              borderColor: '#2ca721',
                                              tension: 0.1,
                                            },
                                          ],
                                        }}
                                        options={chartOptions3}
                                        height={40}
                                      />
                                    </td>
                                    {metric.weeklyValues.map((value, index) => (
                                      <td key={index} className="p-2 border-l">{value}</td>
                                    ))}
                                  </tr>
                                  {expandedMetrics.includes(metric.name) && (
                                    <tr>
                                      <td colSpan="10" className="p-2 bg-gray-100">
                                        <div>Additional details for {metric.name}</div>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="p-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="bg-white p-4 shadow-md rounded">
                            <h2 className="text-lg font-semibold mb-4">Sales & Orders</h2>
                            <Line data={salesOrdersData} options={chartOptions4} />
                          </div>
                          <div className="bg-white p-4 shadow-md rounded">
                            <h2 className="text-lg font-semibold mb-4">Average order value</h2>
                            <Line data={averageOrderValueData} options={chartOptions4} />
                          </div>
                        </div>
                      </div>

                      <div className="p-4">
                        <h2 className="text-xl font-bold mb-4">Customer Experience</h2>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="bg-white p-4 shadow-md rounded">
                            <h3 className="text-lg font-semibold mb-4">Rated orders & Rating</h3>
                            <Bar data={ratedOrdersData} options={chartOptions5} />
                          </div>
                          <div className="bg-white p-4 shadow-md rounded">
                            <h3 className="text-lg font-semibold mb-4">Bad orders</h3>
                            <Line data={badOrdersData} options={chartOptions5} />
                          </div>
                        </div>
                      </div>

                      <div className="p-4">
                        <h2 className="text-xl font-bold mb-4">Customer funnel</h2>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="bg-white p-4 shadow-md rounded">
                            <h3 className="text-lg font-semibold mb-4">Impressions</h3>
                            <Line data={impressionsData} options={chartOptions6} />
                          </div>
                          <div className="bg-white p-4 shadow-md rounded">
                            <h3 className="text-lg font-semibold mb-4">Impressions to menu</h3>
                            <Line data={impressionsToMenuData} options={chartOptions6} />
                          </div>
                        </div>
                      </div>

                    </div>
                  )}
                </div>
              </div>
              )}
            </div>
        
          )}
          {selectedDate && (
            <div className="mt-4">
              <h3 className="text-xl font-semibold">Selected Date: {selectedDate}</h3>
              <p>Display content based on the selected filters here.</p>
            </div>
          )}

        </div>

        {isFilterOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg relative">
              <button
                className="absolute top-4 right-4 text-gray-500"
                onClick={() => setIsFilterOpen(false)}
              >
                &times;
              </button>
              <h2 className="text-center text-2xl font-semibold mb-4">Filter</h2>
              <div className="flex space-x-4 mb-4">
                <div className="w-1/4">
                  {Object.keys(filterOptions).map(filter => (
                    <button
                      key={filter}
                      onClick={() => setSelectedFilter(filter)}
                      className={`block w-full text-left p-2 ${selectedFilter === filter ? 'bg-blue-100' : ''}`}
                    >
                      {filter.charAt(0).toUpperCase() + filter.slice(1)}
                    </button>
                  ))}
                </div>
                <div className="w-3/4">
                  {selectedFilter === 'date' ? (
                    filterOptions.date.map(option => (
                      <div key={option} className="flex items-center mb-2">
                        <input
                          type="radio"
                          name="date"
                          value={option}
                          onChange={() => handleFilterChange('date', option)}
                          className="mr-2"
                        />
                        {option}
                      </div>
                    ))
                  ) : (
                    filterOptions[selectedFilter].map(option => (
                      <div key={option} className="flex items-center mb-2">
                        <input
                          type="checkbox"
                          value={option}
                          onChange={(e) => {
                            const value = e.target.value;
                            handleFilterChange(selectedFilter, e.target.checked ? [...(selectedOptions[selectedFilter] || []), value] : selectedOptions[selectedFilter].filter(opt => opt !== value));
                          }}
                          className="mr-2"
                        />
                        {option}
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                  onClick={() => setSelectedOptions({})}
                >
                  Clear all
                </button>
                <button
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                  onClick={handleApplyFilters}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        )}
      </div>


    </>

  )
}

export default Reporting
