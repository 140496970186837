import React, { useState } from 'react'
import { FaChevronDown } from "react-icons/fa";
function Accordin({ headtext, description }) {
    const [toggle, setToggle] = useState(false)
    return (
        <div className='border-b cursor-pointer my-3'>
            <div onClick={() => setToggle(!toggle)} className='flex justify-between items-center p-2'><span className='text-lg'>{headtext}</span><FaChevronDown className={`${toggle ? 'rotate-180' : ''}`} /></div>
            {toggle && <div className='px-2 text-gray-500 leading-7'>{description}</div>}
        </div>
    )
}

export default Accordin