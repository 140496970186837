import React from 'react';

   const NonCustomizable = ({ formData, handleInputChange }) => {
     return (
       <>
         <label className="block text-gray-700 mt-4">Price of the Item</label>
         <input
           type="number"
           name="price"
           value={formData.price}
           onChange={handleInputChange}
           className="w-full p-2 border border-gray-300 rounded"
           required
         />
       </>
     );
   };

   export default NonCustomizable;