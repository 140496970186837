import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { createCampaign } from '../API/Api'; // Import API function

const Promocard = ({ show, onHide }) => {
  const [selectedDiscount, setSelectedDiscount] = useState(40); // Default to 40%
  const [discountAmount, setDiscountAmount] = useState(selectedDiscount * 2); // Initial amount calculation
  const [customAmount, setCustomAmount] = useState(null);
  const amounts = [120, 220, 450, 600, 800, 1000];
  const [runofferButton, setRunofferButton] = useState('allDays');
  const [minimumOrderValue, setMinimumOrderValue] = useState('₹159');
  const [restaurantId, setRestaurantId] = useState('(ID: 19528561)');

  useEffect(() => {
    // Calculate the discount amount based on the selected discount or custom amount
    if (customAmount !== null) {
      setDiscountAmount(((100 - selectedDiscount) * customAmount) / 100);
    } else {
      setDiscountAmount((selectedDiscount));
    }
  }, [selectedDiscount, customAmount]);

  const handleDiscountChange = (value) => {
    setSelectedDiscount(value);
  };
  
  const handleCustomAmount = (amount) => {
    setCustomAmount(amount);
  };

  const handleActivatePromo = async () => {
    // Function to activate the selected promo
    try {
      const payload = {
        campaign_start_date: '2024-07-10T00:00:00.000Z',
        campaign_end_date: '2024-08-10T00:00:00.000Z',
        created_by: 'your_user_id_here',
        user_code: 0, // Assuming all users
        max_redumptions_per_user: 1,
        redeem_every: 2,
        res_id: 'your_restaurant_id_here',
        discount_window: [
          {
            campaign_day_code: 0, // Assuming all days
            discount_time_windows: [
              {
                start_time: '09:00',
                end_time: '11:00',
              },
              {
                start_time: '14:00',
                end_time: '16:00',
              },
            ],
          },
        ],
        discount_details: {
          discount_type_code: 0, // Assuming percentage discount
          discount_description: {
            percentage: selectedDiscount,
            minimum_order_value: 100, // Example value
            max_discount_value: discountAmount,
            ineligible_cart_item: [] // Example empty array
          }
        }
      };
      const response = await createCampaign(payload);
      console.log('Promo created:', response);
    } catch (error) {
      console.error('Error creating promo:', error);
    }
  };

  const promoCanvasRef = useRef(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (promoCanvasRef.current && !promoCanvasRef.current.contains(event.target)) {
        onHide();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [show]);

  return (
    <div className={`fixed inset-0 z-50 offer ${show ? 'block' : 'hidden'}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div ref={promoCanvasRef} className="fixed right-0 top-0 h-full overflow-y-auto bg-white w-1/3 p-4 shadow-lg">
        <div className="flex justify-between items-center mb-2">
          <h5 className="text-lg font-bold">Offer Details</h5>
          <button type="button" className="text-gray-600" onClick={onHide}>&times;</button>
        </div>
        <div className="flex flex-col gap-4">
          {/* Discount Percentage */}
          <div>
            <h6 className="font-semibold">Select Your Discount</h6>

            <button className="w-full mt-3 px-3 py-2 rounded  text-center outline outline-offset-0 outline-gray-400 mb-4 text-xl font-semibold">
              {selectedDiscount}% OFF up to ₹{discountAmount}
            </button>
            <input
              type="range"
              min="10"
              max="60"
              step="10"
              value={selectedDiscount}
              onChange={(e) => handleDiscountChange(Number(e.target.value))}
              className="w-full"
            />
            <div className="flex justify-between text-gray-600">
              {[10, 20, 30, 40, 50, 60].map(value => (
                <span key={value}>{value}</span>
              ))}
            </div>
          </div>
          {/* No Max Cap and Give Some Amount */}
          <div>
            <div className="flex gap-2 gird gird-cols-6">
              <button className="px-3 bg-green text-white rounded" onClick={() => handleCustomAmount(null)}>No Max Cap</button>
              {amounts.map(amount => (
                <button key={amount} className="px-3 py-1 bg-gray-300 rounded" onClick={() => handleCustomAmount(amount)}>₹{amount}</button>
              ))}
            </div>
          </div>
          {/* Offer information */}
          <div className="flex items-center gap-2 bg-green text-white  rounded p-3">
            <FontAwesomeIcon icon={faLightbulb} />
            <span>This offer is better than 94% of South India restaurants in the area.</span>
          </div>

          {/* Discount Applicable for */}
          <div>
            <h6 className="font-semibold">Discount Applicable for</h6>
            <div className="flex items-center  grid-cols-2 gap-2 mt-2">
              <button className="p-2 bg-green text-white rounded">All User</button>
              <button className="p-2 bg-gray-300 rounded">New User</button>
            </div>
          </div>
          {/* Select meal time */}
          <div className='border-b-2 border-gray-300 pb-3'>
            <h6 className="font-semibold">Select meal time</h6>
            <select className="w-full px-3 py-2 border rounded mt-2">
              <option>All day (24 hours)</option>
              {/* Add other options if needed */}
            </select>
            <p className='text-gray-500 px-2 text-sm mt-2'>All users who place an order in this meal time will be eligible for the offer</p>
          </div>
          {/* Campaign Start date */}
          <div className='border-b-2 border-gray-300 pb-3'>
            <h6 className="font-semibold">Campaign Start date</h6>
            <input type="date" className="w-full px-3 py-2 border rounded mt-2" />
            <p className='text-gray-500 px-2 text-sm mt-2'>Your offer will start on 20th Jul 2024 at 6:00. You can stop this offer at any time.</p>
          </div>
          {/* Activate offer button */}
          <p className='font-sm font-bold'>Run this offer</p>
          <div className="flex space-x-4 w-full border-b-4 border-gray-200 pb-3">
            <button onClick={() => setRunofferButton('allDays')} className={`px-4 py-2 font-semibold rounded ${runofferButton === 'allDays' ? 'bg-green text-white' : 'outline outline-2 outline-gray-300  text-black'}`}>
              All 7 Days
            </button>
            <button onClick={() => setRunofferButton('monThu')} className={`px-4 py-2 font-semibold rounded ${runofferButton === 'monThu' ? 'bg-green text-white' : 'outline outline-2 outline-gray-300 text-black'}`} >
              Mon-Thu
            </button>
            <button onClick={() => setRunofferButton('friSun')} className={`px-4 py-2 font-semibold rounded ${runofferButton === 'friSun' ? 'bg-green text-white' : 'outline outline-2 outline-gray-300 text-black'}`}>
              Fri-Sun
            </button>
          </div>
          <div className=''>
            <h3 className='text-sm font-semibold leading-6 text-gray-900'>Offer Details:</h3>
            <ul className='list-disc list-inside text-sm text-gray-500 pb-2 border-b-4 border-gray-300'>
              <li>Offer applicable for: All users on all menu items, excluding MRP items</li>
              <li>Minimum order value: {minimumOrderValue}</li>
              <li>Valid at: Happy Bites, Alagapuram Pudur {restaurantId}</li>
            </ul>
          </div>
          <div className='mb-2'>
            <input type='checkbox' id='terms' name='terms' className='mr-2' />
            <label htmlFor='terms' className='text-sm text-gray-600'>I have read and accept all the <a href='#' className='text-blue-500 underline'>terms and conditions</a>.</label>
          </div>
          <button onClick={handleActivatePromo} className="w-full px-3 py-2 bg-green text-white rounded">
            Activate Offer
          </button>
        </div>
      </div>
    </div>
  );
};

export default Promocard;
