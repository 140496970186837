import React, { useState } from 'react';
import category from "../Menupages/category.webp";
import item from "../Menupages/add items.webp";
import submit from "../Menupages/submit.webp"
import AddOnsContent from './AddOnsContent';
function Packages() {
    const [activeTab, setActiveTab] = useState('items');

    return (
        <div>
            <div className="container mx-auto pt-12 px-4">
                <div className="flex justify-around border-b border-gray-300 pb-2">
                    <button
                        className={`tab-button ${activeTab === 'items' ? 'active' : ''}`}
                        onClick={() => setActiveTab('items')}
                    >
                        Items
                    </button>
                    <button
                        className="tab-button text-gray-500"
                        onClick={() => setActiveTab('addons')}
                    >
                        Add-ons
                    </button>
                </div>
                <div className="content py-2 p-4">
                    {activeTab === 'items' ? (
                        <>
                            <h1 className="text-2xl font-semibold text-gray-700">Create your menu in 3 steps</h1>
                            <div className="step flex items-center my-5">
                                <img src={category} alt="Category Icon rounded" className="w-12 h-12 mr-5" />
                                <div>
                                    <h2 className="text-xl font-medium text-gray-600">Add Category + Sub Category</h2>
                                    <p className="text-gray-500">Helps to structure your menu for customers</p>
                                </div>
                            </div>
                            <div className="step flex items-center my-5">
                                <img src={item} alt="Item Icon" className="w-12 h-12 mr-5 rounded" />
                                <div>
                                    <h2 className="text-xl font-medium text-gray-600">Add items and their details</h2>
                                    <p className="text-gray-500">The right price and description helps in increasing orders</p>
                                </div>
                            </div>
                            <div className="step flex items-center my-5">
                                <img src={submit} alt="Submit Icon" className="w-12 h-12 mr-5 rounded" />
                                <div>
                                    <h2 className="text-xl font-medium text-gray-600">Submit for review and take it live</h2>
                                    <p className="text-gray-500">New menu items will go live within 24 hours!</p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <AddOnsContent />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Packages
