import React from 'react'
import { FaAngleRight } from "react-icons/fa6";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import '../../Outletinfo/Outlet.css';
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { FaRegFileAlt } from "react-icons/fa";
import Modal from '../../components/Modal';
import { FaCaretRight } from "react-icons/fa";
// import React, { useState,  } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faPhoneVolume, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import user from '../../assets/chef.webp';
import { TiTicket } from "react-icons/ti";
import { CiImageOn } from "react-icons/ci";
import { IoIosStar } from "react-icons/io";
import Accordin from '../../components/Accordin';
export function Restarent() {
    const navigate = useNavigate()
    return (
        <div>
            <div className='border m-4 rounded-lg overflow-hidden '>
                <div className='bg-gray-200 text-lg font-semibold py-2 px-5'>select an option to procced</div>
                <div className='mx-5 mb-5'>
                    <button type='button' onClick={() => navigate('/helpcenter/restarent-timing')} className='flex justify-between border-b w-full hover:bg-gray-50 px-5  py-3'><span>Timings</span><FaAngleRight /></button>
                    <button type='button' onClick={() => navigate('/helpcenter/restarent-contact')} className='flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3'><span>Contacts</span><FaAngleRight /></button>
                    <button type='button' className='flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3'><span>FSSI Food Licence</span><FaAngleRight /></button>
                    <button type='button' onClick={() => navigate('/helpcenter/restarent-update-gst')} className='flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3'><span>Update GSTIN</span><FaAngleRight /></button>
                    <button type='button' onClick={() => navigate('/helpcenter/restarent-display-picture')} className='flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3'><span>Display Picture</span><FaAngleRight /></button>
                    <button type='button' onClick={() => navigate('/helpcenter/restarent-name-address-location')} className='flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3'><span>Name,Address,Location</span><FaAngleRight /></button>
                    <button type='button' onClick={() => navigate('/helpcenter/restarent-review-rating')} className='flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3'><span>Ratings,Reviews</span><FaAngleRight /></button>
                    <button type='button' onClick={() => navigate('/helpcenter/restarent-delivery-area-changes')} className='flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3'><span>Delivery Area Changes</span><FaAngleRight /></button>
                </div>
            </div>

        </div>
    )
}

export function RestarentTiming() {
    const videoSrc = 'https://www.w3schools.com/html/mov_bbb.mp4';
    const initialSlots = [
        { day: 'Monday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
        { day: 'Tuesday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
        { day: 'Wednesday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
        { day: 'Thursday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
        { day: 'Friday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
        { day: 'Saturday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
        { day: 'Sunday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
    ];

    const [slots, setSlots] = useState(initialSlots);
    const [checked, setChecked] = useState(false);
    const [copyChecked, setCopyChecked] = useState(false);


    const handleAccordionToggle = (index) => {
        const newSlots = [...slots];
        newSlots[index].isOpen = !newSlots[index].isOpen;
        setSlots(newSlots);
    };

    const handleTimeChange = (dayIndex, slotIndex, type, time) => {
        const newSlots = [...slots];
        newSlots[dayIndex].timeSlots[slotIndex][type] = time;
        setSlots(newSlots);
    };

    const addTimeSlot = (dayIndex) => {
        const newSlots = [...slots];
        newSlots[dayIndex].timeSlots.push({ startTime: null, endTime: null });
        setSlots(newSlots);
    };

    const deleteTimeSlot = (dayIndex, slotIndex) => {
        const newSlots = [...slots];
        newSlots[dayIndex].timeSlots.splice(slotIndex, 1);
        setSlots(newSlots);
    };

    const handleSave = (index) => {
        console.log('Saving data for', slots[index].day, ':', slots[index]);
    };

    const handleToggle = () => {
        setChecked(!checked);
    };
    const handleCopyToggle = () => {
        setCopyChecked(!copyChecked);
    };


    return (
        <>
            <div className="flex flex-col md:flex-row border-b-2 border-gray-300">
                <div className="p-4 content-center">
                    <video controls className="w-full rounded-md">
                        <source src={videoSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="p-4">
                    <h6 className="text-lg mb-3 font-bold font-sans">
                        How to update online ordering operational Timings | Grow with Genie
                    </h6>
                    <p className="text-base font-sans leading-7 text-gray-600">
                        Want to change the operational timings for online ordering? Watch
                        how you can easily do so using the Restaurant Partner App. Download
                        App Android- hhtps:/{" "}
                    </p>
                </div>
            </div>
            <div className="border-b-2 border-gray-300 pb-3 p-4">
                <h6 className="text-base font-bold font-sans text-green">
                    Genie Delivery
                </h6>
            </div>
            <div className='p-4'>
                <div>
                    {slots.map((slot, dayIndex) => (
                        <div key={dayIndex} className={`mb-4 pb-2 pt-2 px-5 text-base rounded-md ${slot.isOpen ? 'bg-green' : 'bg-zinc-200'}`}>
                            <button className={`p-2 rounded w-full text-left ${slot.isOpen ? 'text-white' : 'text-green'}`} onClick={() => handleAccordionToggle(dayIndex)}>
                                {slot.day}
                            </button>
                            {slot.isOpen && (
                                <div className="p-4 bg-white">
                                    {slot.timeSlots && slot.timeSlots.map((timeSlot, slotIndex) => (
                                        <div key={slotIndex} className="flex items-center space-x-2 mb-2 border-b-2 border-gray-300 pb-4">
                                            <div className="flex items-center space-x-2 ">
                                                <p className="mt-5 font-bold">Slot {slotIndex + 1}</p>
                                                <div>
                                                    <p className="text-center text-gray-700 font-semibold">Start Time</p>
                                                    <DatePicker selected={timeSlot.startTime} onChange={(time) => handleTimeChange(dayIndex, slotIndex, 'startTime', time)} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="h:mm aa" timeCaption="Time" placeholderText="Start Time" className="border p-2 rounded w-32 mt-3" />
                                                </div>
                                                <span className="mt-5">to</span>
                                                <div>
                                                    <p className="text-center text-gray-700 font-semibold">End Time</p>
                                                    <DatePicker selected={timeSlot.endTime} onChange={(time) => handleTimeChange(dayIndex, slotIndex, 'endTime', time)} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="h:mm aa" timeCaption="Time" placeholderText="End Time" className="border p-2 rounded w-32 mt-3" />
                                                </div>
                                            </div>
                                            <button onClick={() => deleteTimeSlot(dayIndex, slotIndex)} className="text-gray-500 mt-5 px-3">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    ))}
                                    <div className="flex justify-end">
                                        <button onClick={() => addTimeSlot(dayIndex)} className="text-green mb-4">+ Add time slot</button>
                                    </div>
                                    <div className="flex items-center space-x-4 mb-4">
                                        <input type="checkbox" className="bg-green" checked={copyChecked} onChange={handleCopyToggle} />
                                        <span>Copy above timings to all days</span>
                                        <label htmlFor="toggle" className="flex items-center space-x-2 cursor-pointer">
                                            <input id="toggle" type="checkbox" className="hidden" checked={checked} onChange={handleToggle} />
                                            <div className={`relative w-12 h-6 rounded-full shadow-inner ring-1 ring-gray-400 ${checked ? 'bg-green' : 'bg-gray-300'}`}>
                                                <div className={`dot absolute w-6 h-6 bg-white rounded-full shadow-md ring-1 ring-gray-400 transition-transform duration-300 ${checked ? 'transform translate-x-full' : 'transform translate-x-0'}`}></div>
                                            </div>
                                            <span>Outlet Open</span>
                                        </label>

                                        <div className="flex justify-end">
                                            <button onClick={() => handleSave(dayIndex)} className={`px-4 py-2 text-white rounded ${copyChecked ? 'bg-green' : 'bg-gray-300'}`} disabled={!copyChecked}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export function ContactDetails() {
    // State management for off-canvas views
    const [isViewPermissionOpen, setViewPermissionOpen] = useState(false);
    const [isViewStaffPermissionOpen, setViewStaffPermissionOpen] = useState(false);
    const [isEditContactOpen, setEditContactOpen] = useState(false);
    const [isManagerPermissionOpen, setManagerPermissionOpen] = useState(false);
    const [currentContact, setCurrentContact] = useState(null);
    const [phoneType, setPhoneType] = useState('Mobile');
    const [ownerProfile, setOwnerProfile] = useState({});
    const [managerProfile, setManagerProfile] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showInvitePanel, setShowInvitePanel] = useState(false);
    const [inputType, setInputType] = useState('phone');


    useEffect(() => {
        if (isViewPermissionOpen || isEditContactOpen || isManagerPermissionOpen || isViewStaffPermissionOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isViewPermissionOpen, isEditContactOpen, isManagerPermissionOpen, isViewStaffPermissionOpen]);

    useEffect(() => {
        // Fetch the owner profile data
        const fetchOwnerProfile = async () => {
            const ownerData = {
                name: 'Koushik',
                phoneNumber: '+91 9600745656',
                email: 'owner@example.com',
                profileImage: (user), // Placeholder image URL
            };

            setOwnerProfile(ownerData);
            // Fetch manager profile data based on owner profile data
            setManagerProfile({
                ...ownerData,
                name: 'Koushik', // Change the name to distinguish between owner and manager
            });
        };

        fetchOwnerProfile();
    }, []);


    const handleOpenViewPermission = () => {
        setViewPermissionOpen(true);
    };

    const handleCloseViewPermission = () => {
        setViewPermissionOpen(false);
    };

    const handleOpenEditContact = (contact) => {
        setCurrentContact(contact);
        setEditContactOpen(true);
    };

    const handleCloseEditContact = () => {
        setCurrentContact(null);
        setEditContactOpen(false);
    };
    const handleOpenManagerPermission = () => {
        setManagerPermissionOpen(true);
    };

    const handleCloseManagerPermission = () => {
        setManagerPermissionOpen(false);
    };
    const handleOpenViewStaff = () => {
        setViewStaffPermissionOpen(true)
    }
    const handleCloseViewStaff = () => {
        setViewStaffPermissionOpen(false);
    };

    // Handlers for opening and closing off-canvas panel
    const handleOpenEditPanel = () => {
        setIsEditing(true);
    };

    const handleCloseEditPanel = () => {
        setIsEditing(false);
    };
    // Handler for updating manager details
    const handleUpdateManager = () => {
        // Implement update logic here (e.g., call API to update manager details)
        console.log('Updating manager profile:', managerProfile);
        handleCloseEditPanel(); // Close the edit panel after updating
    };

    // Handlers for delete confirmation modal
    const handleOpenDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteManager = () => {
        // Implement delete logic here (e.g., call API to delete manager)
        console.log('Deleting manager:', managerProfile.name);
        // Assuming delete logic succeeds, you might redirect or handle state accordingly
        setShowDeleteModal(false); // Close delete modal after deletion
    };
    // invite panel
    const handleOpenInvitePanel = () => {
        setShowInvitePanel(true);
    };

    // Function to handle closing invite panel
    const handleCloseInvitePanel = () => {
        setShowInvitePanel(false);
    };

    const handleInputTypeChange = (type) => {
        setInputType(type);
    };

    // Permission details
    const permissions = [
        { heading: 'Manager Order', paragraph: 'Accept order, mark order, print bill', },
        { heading: 'Manage menu', paragraph: 'Mark item out of stock', },
        { heading: 'Manage outlet settings', paragraph: 'Toggle outlet status ON/OFF', },
        { heading: 'Manage users', paragraph: 'Edit staff contact details', },
        { heading: 'Manage customer complaints', paragraph: 'View or take action on customer complaints', },
        { heading: 'Request support', paragraph: 'View and create tickets on order, ads, promos, tapes, menu etc.', },
    ];

    // Manager permission details
    const managerPermissions = [
        { heading: 'Manage orders', paragraph: 'Accept order, mark order ready, print bill', },
        { heading: 'Manage menu', paragraph: 'Create, edit item details (names, photos, prices etc.), mark item out of stock', },
        { heading: 'Manage outlet settings', paragraph: 'Edit daily timings, toggle outlet status ON/OFF etc.', },
        { heading: 'Manage users', paragraph: 'Edit manager, staff contact details', },
        { heading: 'Manage customer complaints', paragraph: 'View or take action on customer complaints', },
        { heading: 'Request support', paragraph: 'View and create tickets on order, ads, promos, tapes, menu etc.', },
        { heading: 'View business insights', paragraph: 'Track sales/rejection/top dishes etc. metrics', },
        { heading: 'Manage finances', paragraph: 'View account statement, payouts & invoices', },
    ];
    // Staff Permission
    const staff = [
        { heading: 'Manager Order', paragraph: 'Accept order, mark order, print bill', },
        { heading: 'Manage menu', paragraph: 'Mark item out of stock', },
        { heading: 'Manage outlet settings', paragraph: 'Toggle outlet status ON/OFF', },
        { heading: 'Manage users', paragraph: 'Edit staff contact details', },
        { heading: 'Manage customer complaints', paragraph: 'View or take action on customer complaints', },
        { heading: 'Request support', paragraph: 'View and create tickets on order, ads, promos, tapes, menu etc.', },
    ];
    // Contact details
    const contactDetails = [
        {
            id: 1,
            reminder: 'Order reminder number1',
            phoneNumber: '+91 9600745656',
        },
        {
            id: 2,
            reminder: 'Order reminder number2',
            phoneNumber: '+91 9600745678',
        },
    ];

    return (
        <>
            <div className="p-4">
                <div className="flex items-center border border-gray-300 rounded-lg w-full mb-4">
                    <FontAwesomeIcon icon={faSearch} className="text-gray-500 ml-3" />
                    <input type="text" placeholder="Search using name, email or Phone number" className="w-full py-2 px-3 focus:outline-none rounded-r-lg" />
                </div>
            </div>
            <div className="flex items-center justify-between bg-gray-200 text-black p-3 mb-4">
                <span>Order reminder call</span>
                <button onClick={handleOpenViewPermission} className="text-blue-500">
                    View Permission
                </button>
            </div>

            {contactDetails.map((contact) => (
                <div key={contact.id} className="flex items-center p-3 mt-3 border-b-2 border-gray-300">
                    <div className="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full mr-3">
                        <FontAwesomeIcon icon={faPhoneVolume} className="text-gray-600" />
                    </div>
                    <div className="flex-1">
                        <span className="block text-gray-900 font-semibold">{contact.reminder}</span>
                        <span className="block text-gray-700 mt-2">{contact.phoneNumber}</span>
                    </div>
                    <FontAwesomeIcon icon={faEdit} className="text-gray-700 cursor-pointer" onClick={() => handleOpenEditContact(contact)} />
                </div>
            ))}
            {/* Owner Profile */}
            <div className="flex items-center justify-between bg-gray-200 text-black p-3">
                <span>Owner</span>
                <button onClick={handleOpenManagerPermission} className="text-blue-500">
                    View Permission
                </button>
            </div>

            <div className="flex items-center p-4 border-b-2 border-gray-300">
                <div className="w-16 h-16 mr-4">
                    <img src={ownerProfile.profileImage} alt="Profile" className="w-full h-full rounded-full object-cover" />
                </div>
                <div>
                    <span className="block text-gray-900 font-semibold">{ownerProfile.name}</span>
                    <span className="block text-gray-700 mt-2">{ownerProfile.phoneNumber}</span>
                    <span className="block text-gray-700 mt-2">{ownerProfile.email}</span>
                </div>
            </div>
            {/* Manager Profile */}
            <div className="flex items-center justify-between bg-gray-200 text-black p-3">
                <span>Manager</span>
                <button onClick={handleOpenManagerPermission} className="text-blue-500">
                    View Permission
                </button>
            </div>

            <div className="flex items-center justify-between p-4 border-b-2 border-gray-300">
                <div className='flex items-center'>
                    <div className="w-16 h-16 mr-4">
                        <img src={managerProfile.profileImage} alt="Profile" className="w-full h-full rounded-full object-cover" />
                    </div>
                    <div>
                        <span className="block text-gray-900 font-semibold">{managerProfile.name}</span>
                        <span className="block text-gray-700 mt-2">{managerProfile.phoneNumber}</span>
                        <span className="block text-gray-700 mt-2">{managerProfile.email}</span>
                    </div>
                </div>
                <div className='flex items-center'>
                    <button className="text-blue-500 mr-2" onClick={handleOpenEditPanel}><FontAwesomeIcon icon={faEdit} /></button>
                    <button className="text-red" onClick={handleOpenDeleteModal}><FontAwesomeIcon icon={faTrash} /></button>
                </div>
            </div>
            {/* Staff Management */}
            <div className="flex items-center justify-between bg-gray-200 text-black p-3">
                <span>Staff</span>
                <button onClick={handleOpenViewStaff} className="text-blue-500">
                    View Permission
                </button>
            </div>
            <div className="flex items-center justify-between text-black p-3 ">
                <span>No one added as staff yet. <button onClick={handleOpenInvitePanel} className="text-blue-500">
                    Invite Someone now
                </button></span>

            </div>


            {/* Contact details edit off-canvas */}
            {isEditContactOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end index">
                    <div className="bg-white w-1/3 h-full p-4 shadow-lg overflow-y-auto off-canvas-content">
                        <div className="flex justify-between items-center border-b pb-2 mb-2">
                            <h2 className="text-lg font-bold">Edit {currentContact.reminder}</h2>
                            <button onClick={handleCloseEditContact}>
                                <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
                            </button>
                        </div>
                        <div className="mt-4">
                            <label className="block text-gray-700 mb-2">Select type:</label>
                            <div className="flex space-x-4 mb-4">
                                <label className="flex items-center">
                                    <input type="radio" name="phoneType" value="Mobile" checked={phoneType === 'Mobile'} onChange={() => setPhoneType('Mobile')} className="mr-2" />
                                    Mobile
                                </label>
                                <label className="flex items-center">
                                    <input type="radio" name="phoneType" value="Landline" checked={phoneType === 'Landline'} onChange={() => setPhoneType('Landline')} className="mr-2" />
                                    Landline
                                </label>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2">Phone number:</label>
                                <input type="text" defaultValue={currentContact.phoneNumber} className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none" />
                            </div>
                            <div className="gird content-end">
                                <button className="px-4 py-2 bg-green text-white rounded-lg">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* View Permission off-canvas */}
            {isViewPermissionOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 flex justify-end index">
                    <div className="bg-white w-1/3 h-full p-4 shadow-lg overflow-y-auto off-canvas-content">
                        <div className="flex justify-between items-center border-b pb-2 mb-2">
                            <h2 className="text-lg font-bold">Order Reminder Call Permission</h2>
                            <button onClick={handleCloseViewPermission}>
                                <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
                            </button>
                        </div>
                        {permissions.map((permission, index) => (
                            <div key={index} className="border-b pb-3 mb-3 mt-3">
                                <h3 className="font-semibold text-slate-950">{permission.heading}</h3>
                                <p className='mt-3 font-normal'>{permission.paragraph}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Manager Permission off-canvas */}
            {isManagerPermissionOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end index">
                    <div className="bg-white w-1/3 h-full p-4 shadow-lg overflow-y-auto off-canvas-content">
                        <div className="flex justify-between items-center border-b pb-2 mb-2">
                            <h2 className="text-lg font-bold">Manager Permission</h2>
                            <button onClick={handleCloseManagerPermission}>
                                <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
                            </button>
                        </div>
                        {managerPermissions.map((managerPermissions, index) => (
                            <div key={index} className="border-b pb-3 mb-3 mt-3">
                                <h3 className="font-semibold text-slate-950">{managerPermissions.heading}</h3>
                                <p className='mt-3 font-normal'>{managerPermissions.paragraph}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* staff permission */}
            {isViewStaffPermissionOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 flex justify-end index">
                    <div className="bg-white w-1/3 h-full p-4 shadow-lg overflow-y-auto off-canvas-content">
                        <div className="flex justify-between items-center border-b pb-2 mb-2">
                            <h2 className="text-lg font-bold">Staff Permission</h2>
                            <button onClick={handleCloseViewStaff}>
                                <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
                            </button>
                        </div>
                        {staff.map((staff, index) => (
                            <div key={index} className="border-b pb-3 mb-3 mt-3">
                                <h3 className="font-semibold text-slate-950">{staff.heading}</h3>
                                <p className='mt-3 font-normal'>{staff.paragraph}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Off-canvas Edit Panel (Modal) */}
            {isEditing && (
                <div className="fixed inset-0 bg-black bg-opacity-50">
                    <div className="off-canvas-content absolute right-0 top-0 bg-white p-8">
                        <div className="flex justify-between items-center border-b pb-2 mb-2">
                            <h2 className="text-xl font-semibold mb-2">Edit Manager Details</h2>
                            <button onClick={handleCloseEditPanel} className="text-gray-600 px-2 mx-4">
                                <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
                            </button>
                        </div>
                        <form>
                            {/* Input fields for editing manager details */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Name</label>
                                <input type="text" value={managerProfile.name} onChange={(e) => setManagerProfile({ ...managerProfile, name: e.target.value })} className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                                <input type="text" value={managerProfile.phoneNumber} onChange={(e) => setManagerProfile({ ...managerProfile, phoneNumber: e.target.value })} className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Email</label>
                                <input type="email" value={managerProfile.email} onChange={(e) => setManagerProfile({ ...managerProfile, email: e.target.value })} className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" />
                            </div>
                            <button type="button" onClick={handleUpdateManager} className="bg-green w-full hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline">
                                Update
                            </button>
                        </form>
                    </div>
                </div>
            )}
            {/* Delete popup */}
            {showDeleteModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="bg-white p-8 rounded-lg z-10 max-w-md text-center">
                        <div className="text-red text-4xl mb-4">
                            <FontAwesomeIcon icon={faTrash} />
                        </div>
                        <p className="text-lg mb-4">
                            Are you sure you want to delete {managerProfile.name}?
                        </p>
                        <p className="text-sm text-gray-600 mb-4">
                            {managerProfile.name} won't be able to login or receive communication from the outlet.
                        </p>
                        <hr className="border-b-2 border-gray-300 my-4" />
                        <div className="flex justify-center">
                            <button onClick={handleDeleteManager} className="bg-red hover:bg-red text-white font-bold py-2 px-4 rounded-md mr-2 focus:outline-none focus:shadow-outline">
                                Delete
                            </button>
                            <button onClick={handleCloseDeleteModal} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Off-canvas Invite Panel */}
            {showInvitePanel && (
                <div className={`fixed inset-0 z-50 transform ${showInvitePanel ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
                    <div className="fixed inset-0 bg-black opacity-50" onClick={handleCloseInvitePanel}></div>
                    <div className="bg-white h-full w-96 absolute right-0 top-0 overflow-y-auto">
                        <div className="flex justify-between mb-4 border-b-2 gray-400 p-3">
                            <h2 className="text-xl font-semibold">Invite User</h2>
                            <button onClick={handleCloseInvitePanel} className="text-gray-600">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        {/* Invite user form */}
                        <div className="px-4 py-2">
                            <label htmlFor="contactInput" className="block text-gray-700">{inputType === 'phone' ? 'Phone Number' : 'Email'}</label>
                            <input
                                type={inputType === 'phone' ? 'text' : 'email'}
                                id="contactInput"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                placeholder={inputType === 'phone' ? 'Enter Mobile Number' : 'Enter Email Address'}
                            />
                        </div>
                        <div className="px-4 py-2">
                            <p className="text-sm text-gray-600">This user will receive a link by {inputType === 'phone' ? 'SMS' : 'email'} which they need to click on to accept the invite and be added to your outlet.</p>
                        </div>
                        <div className="px-4 py-2">
                            <button onClick={() => handleInputTypeChange(inputType === 'phone' ? 'email' : 'phone')} className="text-blue-500">
                                {inputType === 'phone' ? 'Invite by email' : 'Invite by phone'}
                            </button>
                        </div>
                        <div className="px-4 py-2 bg-gray-200 mt-3">
                            <p className="text-lg font-semibold">User Role</p>
                        </div>
                        <div className="px-4 py-2 mt-3">
                            <div className='mb-3'>
                                <input type="radio" id="staff" name="role" value="staff" />
                                <label htmlFor="staff" className="ml-2">Staff</label>
                            </div>
                            <button onClick={() => handleOpenViewStaff('staff')} className="text-blue-500 ml-2 px-3">View Permission</button>
                            <div className="mt-2 mb-3">
                                <input type="radio" id="manager" name="role" value="manager" />
                                <label htmlFor="manager" className="ml-2">Manager</label>
                            </div>
                            <button onClick={() => handleOpenManagerPermission('manager')} className="text-blue-500 ml-2 px-3">View Permission</button>
                        </div>
                        <div className="px-4 py-2 mt-6 flex justify-end">
                            <button className="bg-green w-96 text-white px-4 py-2 rounded" onClick={handleCloseInvitePanel}>
                                Send Invite
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export function UpdateGstlin() {
    const [isVisible, setIsVisible] = useState(false)
    const nevigate = useNavigate()
    return (
        <div>
            <div className='m-5 border rounded-lg overflow-hidden'>
                <div className='flex space-x-3 bg-gray-100 p-2'><FaArrowLeftLong onClick={() => nevigate('/helpcenter/restarent')} /> <span className='font-semibold'>Update GSTIN</span></div>
                <div className='p-3'>
                    <div className='font-semibold '>Select GSTIN</div>
                    <div className='py-4'><input type="checkbox" onChange={() => setIsVisible(!isVisible)} class="form-checkbox h-4 w-4 rounded-full text-blue-600" id="javascript" name="fav_language" /> <span>Add New GST</span> </div>
                    {isVisible && <input type="text" className='border-2 rounded focus:outline-none p-2 m-5' placeholder='Enter GSTIN Number' />}
                </div></div>
            <button type='button' className='bg-gray-300 p-1 px-5 rounded-lg text-lg mx-5'>Save</button>
        </div>
    )
}

export function DisplayPicture() {
    const nevigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div>
            <div className='m-5 border rounded-lg space-y-5 overflow-hidden'>
                <div className='flex space-x-3 bg-gray-100 p-2'><FaArrowLeftLong className='cursor-pointer' onClick={() => nevigate('/helpcenter/restarent')} /> <span className='font-semibold'>Update GSTIN</span></div>
                <p className='px-3 text-lg'>To get Your Restaurent display picture update please write to us</p>
                <button type='button' onClick={() => setIsOpen(!isOpen)} className='space-x-2 px-3 pb-3 text-blue-600'><TiTicket className='inline' /> <span>Rise a ticket</span></button>
                <Modal close={true} isOpen={isOpen} width={400} onClose={() => setIsOpen(!isOpen)}>
                    <div className='space-y-5'>
                        <div className='text-lg font-medium'>Please Give Details</div>
                        <div className='text-lg font-semibold p-1 bg-blue-50'>Restaurant : Display Picture</div>
                        <div className='space-y-3'>
                            <p>Describe Your Issue <span className='text-red'>*</span></p>
                            <textarea name="describe" id="describe" className='border w-full p-1 rounded'></textarea>

                        </div>
                        <div className='space-y-3'>
                            <p>Callback Contact Number</p>
                            <textarea name="callback" className='border w-full p-1 rounded' id="callback"></textarea>
                        </div>

                        <p>Attachment</p>
                        <div>
                            <span>
                                <label htmlFor="imageInput">
                                    <div className='flex justify-between text-lg border items-center p-1 rounded'>
                                        <span>   <CiImageOn size={30} className='inline' /> upload image or video</span><FaArrowRightLong />
                                    </div>
                                    <input
                                        id="imageInput"
                                        name="profileImage"
                                        type="file"
                                        accept="image/*,video/*"
                                        className="hidden"
                                        //   onChange={handleChange}
                                        alt="upload your profile"
                                    />
                                </label>
                            </span>
                        </div>
                        <div>
                            <span>
                                <label htmlFor="fileInput">
                                    <div className='flex justify-between text-lg border items-center p-1 rounded'>
                                        <span>  <FaRegFileAlt className='inline' size={30} /> upload file</span><FaArrowRightLong />
                                    </div>
                                    <input
                                        id="fileInput"
                                        name="profileImage"
                                        type="file"

                                        className="hidden"
                                        //   onChange={handleChange}
                                        alt="upload your profile"
                                    />
                                </label>
                            </span>
                        </div>
                        <p>please make sure everything is correct</p>
                        <button type='button' className='block w-full p-2 rounded bg-gray-200'>Rise Ticket</button>

                    </div>
                </Modal>
            </div>
        </div>
    )
}

export function ReviewsAndRating() {
    const nevigate = useNavigate()
    return (
        <div>
            <div className='m-5 border rounded'>
                <div className='flex space-x-3 bg-gray-100 py-5 p-2'><FaArrowLeftLong className='cursor-pointer' onClick={() => nevigate('/helpcenter/restarent')} /> <span className='font-semibold'>Rating,review</span></div>
                <div className='bg-blue-600 flex items-center'>
                    <div className='p-4'> <img className='' src="https://www.fnb-online.com/-/media/fnbonline/images/feature/pagecontent/content-sub-header/5-insurance-copy-half.webp" width={200} alt="" /></div>
                    <div className='text-xl text-white'>Ratings Represent Your Costomer opinion <br /> About Your Food Quality</div>
                </div>
                <div className='mx-5 '>
                    <div className='my-3 flex space-x-3'> <span className='font-semibold'>Your Restaurent Rating</span> <span className='bg-green text-white rounded p-[2px] flex items-center w-fit'>4.2<IoIosStar className='inline' /></span></div>
                    <div className='flex items-center py-1 text-lg text-blue-600 cursor-pointer'><span className='py-1'>view order rating</span><FaCaretRight /></div>
                    <Accordin headtext={' Lorem ipsum dolor sit amet consectetur adipisicing elit.'} description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium.'} />
                    <Accordin headtext={' Lorem ipsum dolor sit amet consectetur adipisicing elit.'} description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium.'} />
                    <Accordin headtext={' Lorem ipsum dolor sit amet consectetur adipisicing elit.'} description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium.'} />
                    <Accordin headtext={' Lorem ipsum dolor sit amet consectetur adipisicing elit.'} description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium.'} />
                    <Accordin headtext={' Lorem ipsum dolor sit amet consectetur adipisicing elit.'} description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium.'} />
                    <Accordin headtext={' Lorem ipsum dolor sit amet consectetur adipisicing elit.'} description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium.'} />
                </div>

            </div>
        </div>
    )
}

export function DeliveryAreaChanges() {
    const navigate = useNavigate()
    return (
        <div>
            <div className='flex space-x-3 bg-gray-100 py-5 p-2'><FaArrowLeftLong className='cursor-pointer' onClick={() => navigate('/helpcenter/restarent')} /> <span className='font-semibold'>Delivery Area Changes</span></div>
            <img src="/delivery.webp" alt="" />
            <div className='m-3'>
                <p className='text-lg'>You can not modify the delivery area of your restaurent</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, adipisci atque sequi a ipsum praesentium provident, in error soluta impedit architecto dignissimos? Quod praesentium ipsum nostrum dolore error odit ducimus.</p>
            </div>
        </div>
    )
}

export function NameAddressLocation() {
    return (
        <div>
            Name Address Location
        </div>
    )
}