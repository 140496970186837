import React from 'react';
import delivery from '../assets/delivery.webp';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, subDays } from "date-fns";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa6";

const Details = () => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [buttonText, setButtonText] = useState("Details from 1st JUl");
  
    const today = new Date();
    const startLimit = subDays(today, 15);
  
    const handleDateChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
  
      if (start && end) {
        setButtonText(
          `${format(start, "yyyy-MM-dd")} to ${format(end, "yyyy-MM-dd")}`
        );
      }
    };


  return (
    <div className="p-4">
      <button className="text-green  text-xl mb-4" onClick={() => window.history.back()}>
        ← Total sales
      </button>
      <div className="flex space-x-4">
        <div className="bg-white shadow rounded p-6 flex-1">
          <h2 className="text-lg font-semibold mb-4">Delivered order Trends</h2>
          <img src={delivery} alt="Delivered order Trends" className="w-full"/>
        </div>
        <div className="bg-white shadow rounded p-6 flex-1">
                  <h2 className="text-lg font-semibold mb-4">Mealtime distribution</h2>
                  
           
        <div className="flex items-center space-x-4 mx-4">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-green rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              {buttonText}
              <FaChevronDown
                className="w-5 h-5 ml-2 -mr-1 text-white"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="right-0 mt-2 origin-top-right absolute bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    minDate={startLimit}
                    maxDate={today}
                  />
                </div>
              </Menu.Items>
            </Transition>
            
          </Menu>
        </div>  
                  
          <img src={delivery} alt="Mealtime distribution" className="w-full"/>
        </div>
      </div>
    </div>
  );
};

export default Details;
