import { useState } from "react";
import React from 'react';

function Charges() {


    const [charges, setCharges] = useState([
        {
            id: 1,
            name: 'Packaging Charge - Fixed',
            value: 10,
            multiItem: 'No',
            applicableOn: 'Order',
            taxes: 'No Taxes Applied',
        },
        // Add more charges here if needed
    ]);

    const handleDelete = (id) => {
        setCharges(charges.filter(charge => charge.id !== id));
    };



    return (
        <div>


            <div className="container mx-auto p-6">
                <h1 className="text-2xl font-semibold text-gray-700 mb-4">Charges</h1>
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
                    <p>This is the peak lunch hour; please avoid making any menu changes to menu between 12 pm and 2 pm to prevent order loss.</p>
                </div>
                <h2 className="text-xl font-medium text-gray-700 mb-4">Delivery Charges</h2>
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">Charges</th>
                            <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">Value</th>
                            <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">Multi Item</th>
                            <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">Applicable On</th>
                            <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">Taxes</th>
                            <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {charges.map(charge => (
                            <tr key={charge.id}>
                                <td className="px-4 py-2 border-b border-gray-200">{charge.name}</td>
                                <td className="px-4 py-2 border-b border-gray-200">{charge.value}</td>
                                <td className="px-4 py-2 border-b border-gray-200">{charge.multiItem}</td>
                                <td className="px-4 py-2 border-b border-gray-200">{charge.applicableOn}</td>
                                <td className="px-4 py-2 border-b border-gray-200">{charge.taxes}</td>
                                <td className="px-4 py-2 border-b border-gray-200 text-right">
                                    <button className="text-red-500 hover:text-red-700" onClick={() => handleDelete(charge.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="mt-4">
                    <button className="bg-green text-white px-4 py-2 rounded">Add Charges</button>
                </div>
            </div>

        </div>
    )
}

export default Charges
