import React from "react";
import "../pages/Orderhistory.css";
import { SlCalender } from "react-icons/sl";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { DateRangePicker } from "react-date-range";
import { useState } from "react";
// import { newDate } from "react-datepicker/dist/date_utils";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { IoClose } from "react-icons/io5";
import { IoDownloadOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { BiError } from "react-icons/bi";
import noshow from '../assets/no-food.webp'

function Orderhistory() {
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleChange = (ranges) => {
    setDate(ranges.selection);
  };

  const handleClick = () => {
    setOpenDate((prev) => !prev);
  };

  // --------Filter start-------------

  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Order Status");
  const [orderStatus, setOrderStatus] = useState([]);
  const [orderType, setOrderType] = useState("");
  const [rating, setRating] = useState("");

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setOrderStatus([...orderStatus, name]);
    } else {
      setOrderStatus(orderStatus.filter((status) => status !== name));
    }
  };

  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };

  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };

  const handleClearAll = () => {
    setOrderStatus([]);
    setOrderType("");
    setRating("");
  };

  const handleApply = () => {
    // Handle apply logic here
    console.log("Order Status:", orderStatus);
    console.log("Order Type:", orderType);
    console.log("Rating:", rating);
    togglePopup();
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case "Order Status":
        return (
          <div>
            {["Preparing", "Ready", "Pickup", "Delivered", "Rejected"].map(
              (status) => (
                <div key={status} className="mt-5 text-xl">
                  <input type="checkbox" id={status} name={status} checked={orderStatus.includes(status)} onChange={handleCheckboxChange} className="mr-2" />
                  <label htmlFor={status}>{status}</label>
                </div>
              )
            )}
          </div>
        );
      case "Order Type":
        return (
          <div>
            <div className="mt-5 text-xl">
              <input type="radio" id="online" name="orderType" value="Online" checked={orderType === "Online"} onChange={handleOrderTypeChange} className="mr-2" />
              <label htmlFor="online">Online</label>
            </div>
            <div className="mt-5 text-xl">
              <input type="radio" id="offline" name="orderType" value="Offline" checked={orderType === "Offline"} onChange={handleOrderTypeChange} className="mr-2" />
              <label htmlFor="offline">Offline</label>
            </div>
          </div>
        );
      case "Ratings":
        return (
          <div>
            {[1, 2, 3, 4, 5].map((ratingValue) => (
              <div key={ratingValue} className="mt-5 text-xl">
                <input type="radio" id={`rating-${ratingValue}`} name="rating" value={ratingValue} checked={rating === ratingValue.toString()} onChange={handleRatingChange} className="mr-2" />
                <label htmlFor={`rating-${ratingValue}`}>
                  {ratingValue} Stars
                </label>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };


  const [orderId, setOrderId] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow only numbers
    if (/^\d*$/.test(value)) {
      setOrderId(value);
    }
  };

  const handleSearch = () => {
    // Simulating search logic, replace with actual search logic
    if (orderId === "12345") {
      setSearchResult({ id: orderId, name: "Sample Order" });
    } else {
      setSearchResult(null);
    }
  };

  return (
    <>
      <div className="">
        <div className="flex justify-between items-center mb-4 border-b-2 p-3 border-gray-300 shadow-md">
          <h3 className="text-xl font-bold">Order History</h3>
          <div className="flex space-x-4 items-center">
            <div className="relative">
              <span onClick={handleClick} className="cursor-pointer calender flex items-center">
                {`${format(date.startDate, " MMM dd yyyy")} to ${format(
                  date.endDate,
                  " MMM dd yyyy"
                )}`}{" "}
                <MdOutlineArrowDropDown className="text-xl ml-1" />
              </span>
              {openDate && (
                <DateRangePicker
                  className="absolute top-full mt-2 z-10"
                  ranges={[date]}
                  onChange={handleChange}
                />
              )}
            </div>
            <button
              onClick={togglePopup}
              className="bg-green hover:bg-green-700 text-white text-lg h-12 w-40 rounded"
            >
              Filter
            </button>
            {isOpen && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-6 rounded shadow-lg relative">
                  <button onClick={togglePopup} className="text-red-500 absolute top-4 right-4">
                    <IoClose className="text-xl" />
                  </button>
                  <div className="flex">
                    <div className="bg-gray-100 p-4">
                      <button onClick={() => setActiveTab("Order Status")} className={`w-full text-left text-xl mb-2 ${activeTab === "Order Status" ? "font-bold" : "text-green"}`}>
                        Order Status
                      </button>
                      <button onClick={() => setActiveTab("Order Type")} className={`w-full text-left text-xl mb-2 ${activeTab === "Order Type" ? "font-bold" : "text-green"}`}>
                        Order Type
                      </button>
                      <button onClick={() => setActiveTab("Ratings")} className={`w-full text-xl text-left ${activeTab === "Ratings" ? "font-bold" : "text-green"}`}>
                        Ratings
                      </button>
                    </div>
                    <div className="flex-grow p-4">
                      {renderActiveTabContent()}
                      <div className="mt-auto flex justify-between pt-4">
                        <button onClick={handleClearAll} className="bg-gray-300 text-black px-4 py-2 rounded mr-2">
                          Clear All
                        </button>
                        <button onClick={handleApply} className="bg-green text-white px-4 py-2 rounded">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <button className="bg-green hover:bg-500-700  text-white text-lg h-12 w-40 rounded flex items-center justify-center">
              <IoDownloadOutline className="text-2xl mr-2" /> Download CSV
            </button>
          </div>
        </div>

        <div className="mb-4 p-2">
          <div className="flex items-center border border-gray-300 rounded-md overflow-hidden w-2/5">
            <input type="text" value={orderId} onChange={handleInputChange} className="px-4 py-2 flex-grow focus:outline-none" placeholder="Search Order ID" />
            <button onClick={handleSearch} className="bg-green hover:bg-green-700 text-white px-4 py-2 flex items-center justify-center focus:outline-none">
              <FaSearch className="mr-2" /> Search
            </button>
          </div>
          {searchResult ? (
            <div className="text-center mt-4">
              <p>Order ID: {searchResult.id}</p>
              <p>Order Name: {searchResult.name}</p>
            </div>
          ) : (
            orderId && (
              <div>
                <div className="text-red flex justify-center mt-8">
                  {/* | <BiError className="mr-2" /> */}
                  <img src={noshow} alt="no-food" className="w-56 h-56" /><br />
                </div>
                <p className="text-red flex justify-center mt-3 text-lg">No order to show</p>
              </div>
            )
          )}
        </div>
      </div>

    </>
  );
}

export default Orderhistory;
