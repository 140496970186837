
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}/api`;

const ApiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// OTP Send API function

export const sendOtp = async (phoneNumber) => {
  try {
    const response = await ApiService.post("/otp/send", { phoneNumber });
    return response.data;
  } catch (error) {
    throw error; // Will be caught by the error handler
  }
};

// OTP Verify API function

export const verifyOtp = async (phoneNumber, otp) => {
  try {
    const response = await ApiService.post("/otp/verify", {
      phoneNumber,
      otp,
      deviceType: "React_Merchant", // Default device type
      appVersion: "1.0.1", // Default app version
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const refreshTokenAPI = async (refreshToken) => {
  try {
    const response = await ApiService.post("/token/refresh", {
      refreshToken,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const token = localStorage.getItem("accessToken");
console.log("Token is there", token);
const restaurant_Id = localStorage.getItem("restaurantId");
console.log("Restaurant Id is also there", restaurant_Id);
const user_Id = localStorage.getItem("userId");
console.log("User Id is also there", user_Id);

export const fetchMenuItems = async () => {
  const token = localStorage.getItem("accessToken");
  const restaurant_Id = localStorage.getItem("restaurantId");
  const user_Id = localStorage.getItem("userId");

  if (!token || !restaurant_Id || !user_Id) {
    console.error("Required data is missing from sessionStorage.");
    throw new Error("Required data is missing from sessionStorage.");
  }

  try {
    const response = await ApiService.post(
      "restaurants/basic_details",
      {
        restaurantId: restaurant_Id,
        mode: "Delivery",
        userLat: 12.892296478812135,
        userLng: 77.64241154926422,
        role: "restaurant_admin",
        user: user_Id,
        context: "res_context",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error in fetchMenuItems:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const logout = async (logoutType) => {
  try {
    // Manually specify the base URL for logout
    const customBaseUrl = `${process.env.REACT_APP_API_ENDPOINT}/api`;
    
  
    const response = await axios.post(`${customBaseUrl}/logout`, { logoutType });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllCampaigns = async (payload) => {
  try {
    const response = await ApiService.post(
      "campaign/fetch_campaigns",
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error fetching campaigns:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const getPredefinedCampaigns = async (payload) => {
  try {
    console.log(token, "Predefined Campaigns");
    const response = await ApiService.post(
      "campaign/predefined_campaigns",
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching predefined campaigns:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const getCampaignPermissions = async (payload) => {
  try {
    const response = await ApiService.post(
      "campaign/campaign_permissions",
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching campaign permissions:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const createCampaign = async (payload) => {
  try {
    const response = await ApiService.post("campaign/create", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating campaign:",
      error.response?.data || error.message
    );
    throw error;
  }
};
