import React from 'react';
import PropTypes from 'prop-types';
import { IoClose } from "react-icons/io5";

const Modal = ({ width, close, isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div style={{ width: width }} className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all m-4">
                <div className="p-4">
                    {close && <button
                        onClick={onClose}
                        className="absolute top-0 right-0 m-2 text-gray-600 hover:text-gray-900"
                    >
                        <IoClose className='size-[30px]' />
                    </button>}
                    {children}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default Modal;
