import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
const Nested = ({ onBack, optionsData, variantsData, itemName, isSamePrice, takeaway, delivery, handleToggleChange, handleTakeawayChange, handleDeliveryChange, }) => {
  const navigate = useNavigate()
  const [options, setOptions] = useState([]);
  const [variants, setVariants] = useState([]);
  const [addOnGroups, setAddOnGroups] = useState([{ name: "" }]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [addOnItems, setAddOnItems] = useState([{ itemName: "", additionalPrice: "" }]);
  const [selectedOption, setSelectedOption] = useState('');
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [addOnGroupsForVariant, setAddOnGroupsForVariant] = useState({});
  const [varientDatas, setVarientDatas] = useState([
    { variantName: "Small" },
    { variantName: "Medium" },
    { variantName: "Large" }
  ]);

  // Fetch data passed from MenuDetails
  useEffect(() => {
    // Assuming optionsData and variantsData are passed from MenuDetails
    if (optionsData) {
      setOptions(optionsData);
    }
    if (variantsData) {
      setVariants(variantsData);
    }
  }, [optionsData, variantsData]);

  const handleVariantClick = (variantName) => {
    setSelectedVariant(variantName); // Set selected variant
    // Initialize the add-on group for the selected variant if not already initialized
    if (!addOnGroupsForVariant[variantName]) {
      setAddOnGroupsForVariant((prevState) => ({
        ...prevState,
        [variantName]: [{ name: '' }]
      }));
    }
  };
  const handleAddOnChange = (e, index, variantName) => {
    const updatedGroups = [...addOnGroupsForVariant[variantName]];
    updatedGroups[index].name = e.target.value;
    setAddOnGroupsForVariant((prevState) => ({
      ...prevState,
      [variantName]: updatedGroups
    }));
  };
  const addAddOnGroup = (variantName) => {
    setAddOnGroupsForVariant((prevState) => ({
      ...prevState,
      [variantName]: [...prevState[variantName], { name: '' }]
    }));
  };
  const handleAddAddOnItem = (addOnName) => {
    // Create a new add-on item object
    const newItem = { itemName: '', additionalPrice: '' };

    // Update the addOnItems state by adding the new item to the correct add-on group
    setAddOnItems((prevState) => ({
      ...prevState,
      [addOnName]: [...(prevState[addOnName] || []), newItem],
    }));
  };

  const handledata = (index) => {
    const datas = optionsData[index].varient
    setVarientDatas(datas)
    console.log(datas)
  }

  const handleOptionClick = (optionName) => {
    setSelectedOption(optionName); // Store selected option name

    // Filter variants associated with the selected option
    const associatedVariants = variants.filter(
      (variant) => variant.optionName === optionName
    );
    setFilteredVariants(associatedVariants);
  };

  // Handle Back Button
  const handleBackClick = () => {
    if (currentPage === 6) {
      setCurrentPage(2); // Go back to the fifth nested page (currentPage === 2)
    } else if (currentPage === 2) {
      setCurrentPage(1); // Go back to the first page
    } else {
      navigate('/menuhead?steps=MenuDetails&step1=3') // Call the parent back function if needed
    }
  };

  // Handle Continue Button
  const handleContinueClick = () => {
    setCurrentPage(2); // Move to the 5th Nested page
  };
  const handleCompleteClick = () => {
    setCurrentPage(6);
  };

  // Function to handle click event
  const handleAddOnClick = (addOnName) => {
    if (!selectedAddOns.includes(addOnName)) {
      setSelectedAddOns((prev) => [...prev, addOnName]); // Add the selected add-on group name
      setAddOnItems((prev) => ({
        ...prev,
        [addOnName]: [{ itemName: '', additionalPrice: '' }] // Initialize with one empty add-on item for the new group
      }));
    }
  };

  // Function to handle changes in Add On Items input fields
  const handleAddOnItemChange = (addOnName, index, event) => {
    const { name, value } = event.target;
    const updatedItems = [...addOnItems[addOnName]];
    updatedItems[index] = { ...updatedItems[index], [name]: value };
    setAddOnItems((prev) => ({ ...prev, [addOnName]: updatedItems }));
  };

  // Function to add another Add On Item
  const handleAddAnotherAddOnItem = (addOnName) => {
    setAddOnItems((prev) => ({
      ...prev,
      [addOnName]: [...addOnItems[addOnName], { itemName: '', additionalPrice: '' }] // Add a new empty object
    }));
  };

  return (
    <div className='px-16 py-16 pt-10 bg-gray-50 h-screen'>
      {currentPage === 1 && (
        <>
          {/* Header with Back Button */}
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
            <button className="bg-green text-white px-4 py-2 rounded">Save Draft</button>
          </div>

          {/* Base Price Input */}
          <div className="flex items-center">
            <BsArrowLeft onClick={handleBackClick} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Set Your Delivery Pricing</span>
          </div>
          <div className="mb-4 flex items-center mt-5">
            <label className="block text-base font-medium text-gray-700 mb-1">Base Price</label>
            <input type="text" className="border border-gray-300 rounded-md p-1 ml-5" placeholder="Enter Base Price" />
          </div>

          {/* Create Option Section */}
          <h2 className="text-lg font-bold my-4">Create Option</h2>
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Options</h2>
            {optionsData.length > 0 ? (
              optionsData.map((option, index) => (
                <div key={index} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white cursor-pointer" onClick={() => handledata(index)}>
                  <input type="text" placeholder="Enter option name" value={option.optionName} className="flex-grow bg-white outline-none" onClick={() => handleOptionClick(option.optionName)} readOnly />
                  <span className="flex items-center">
                    Additional price
                    <input type="text" placeholder="0.00" value={option.additionalPrice} className="w-20 text-center bg-white border border-gray-300 rounded p-1 mx-3" readOnly />
                  </span>
                </div>
              ))
            ) : (
              <p>No options added yet.</p>
            )}
          </div>

          {/* Variants Section */}
          <h2 className="text-lg font-semibold mt-3">
            Variants for <span className="font-normal">{selectedOption}</span>
          </h2>
          <div className="mt-2 flex items-center gap-5">
            {varientDatas.length > 0 ? (
              varientDatas.map((variant, index) => (
                <div key={index} className={`mt-2 border border-gray-300 rounded p-2 bg-white flex w-full cursor-pointer ${selectedVariant === variant.variantName ? 'bg-green-100 text-green-700' : ''}`} onClick={() => handleVariantClick(variant.variantName)}>
                  <span className="block text-base font-medium text-center">
                    {variant.variantName}
                  </span>
                </div>
              ))
            ) : (
              <p>No variants added yet.</p>
            )}
          </div>

          {/* Create Add-On Group Section */}
          {selectedVariant && (
            <>
              <div className="mt-6">
                <h2 className="text-lg font-semibold inline-block">
                  Create Add-on Group for  {' '}
                </h2>
                <span className="text-green font-semibold"> {selectedVariant}</span>
              </div>
              {addOnGroupsForVariant[selectedVariant]?.map((addOn, index) => (
                <div key={index} className="mt-2 flex items-center gap-5">
                  <input type="text" value={addOn.name} onChange={(e) => handleAddOnChange(e, index, selectedVariant)} className="w-full border border-gray-300 p-2 rounded" placeholder="Enter Add-on Group" />
                </div>
              ))}
              <div className="mt-4 flex justify-center">
                <button type="button" onClick={() => addAddOnGroup(selectedVariant)} className="bg-green text-white px-4 py-2 rounded">
                  + Add Another Add-On
                </button>
              </div>
            </>
          )}
          <div className="mt-6 flex justify-center">
            <button className="bg-green text-white px-4 py-2 rounded w-3/12" onClick={handleContinueClick}>
              Continue
            </button>
          </div>
        </>
      )}

      {currentPage === 2 && (
        <>
          {/* 5th Nested Page */}
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
            <button className="bg-green text-white px-4 py-2 rounded">Save Draft</button>
          </div>
          <div className="flex items-center">
            <BsArrowLeft onClick={handleBackClick} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Set Your Delivery Pricing</span>
          </div>
          <div className="mb-4 flex items-center mt-5">
            <label className="block text-base font-medium text-gray-700 mb-1">Base Price</label>
            <input type="text" className="border border-gray-300 rounded-md p-1 ml-5" placeholder="Enter Base Price" />
          </div>
          <h2 className="text-lg font-bold my-4">Create Option</h2>
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Options</h2>
            {options.length > 0 ? (
              options.map((option, index) => (
                <div key={index} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white">
                  <input type="text" placeholder="Enter option name" value={option.optionName} className="flex-grow bg-white outline-none" onClick={() => handleOptionClick(option.optionName)} />
                  <span className="flex items-center">
                    Additonal Price
                    <input type="text" placeholder="0.00" value={option.additionalPrice} className="w-20 text-center bg-white border border-gray-300 rounded p-1 mx-3" />
                  </span>
                </div>
              ))
            ) : (
              <p>No options added yet.</p>
            )}
          </div>
          <h2 className="text-lg font-semibold mt-3">Variants for {selectedOption}</h2>
          <div className="mt-2 flex items-center gap-5 ">
            {varientDatas.length > 0 ? (
              varientDatas.map((variant, index) => (
                <div key={index} className={`mt-2 border border-gray-300 rounded p-2 bg-white flex w-full cursor-pointer ${selectedVariant === variant.variantName ? 'bg-green-100 text-green-700' : ''}`} onClick={() => handleVariantClick(variant.variantName)}>
                  <span className="block text-base font-medium text-center">
                    {variant.variantName}
                  </span>
                </div>
              ))
            ) : (
              <p>No variants added yet.</p>
            )}
          </div>
          <h2 className="text-lg font-semibold mt-3">Created Add On Group</h2>
          <div className="mt-4 flex items-center gap-5">
            {addOnGroups.length > 0 ? (
              addOnGroups.map((addOn, index) => (
                <div key={index} className="mt-2 border border-gray-300 rounded p-2 bg-white flex w-full">
                  <input type="text" value={addOn.name} className="block text-base font-medium w-32 text-center" readOnly onClick={() => handleAddOnClick(addOn.name)} />
                </div>
              ))
            ) : (
              <p>No add-on groups created yet.</p>
            )}
          </div>
          {/* <h2 className="text-lg font-semibold mt-5">Add-on Items for {addOnName}</h2> */}
          <h2 className="text-base font-medium mt-5">Create Add On Items</h2>
          {selectedAddOns.map((addOnName, idx) => (
            <div key={idx}>
              <h2 className="text-lg font-semibold mt-5">Add-on Items for {addOnName}</h2>
              <h2 className="text-base font-medium mt-3">Create Add On Items</h2>
              {addOnItems[addOnName]?.map((item, index) => (
                <div key={index} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white">
                  <input type="text" name="itemName" placeholder="Enter Add On Item Name" value={item.itemName} onChange={(e) => handleAddOnItemChange(addOnName, index, e)} className="flex-grow bg-white outline-none" />
                  <span className="flex items-center">
                    Additional Price
                    <input type="text" name="additionalPrice" placeholder="0.00" value={item.additionalPrice} onChange={(e) => handleAddOnItemChange(addOnName, index, e)} className="w-20 text-center bg-white border border-gray-300 rounded p-1 mx-3" />
                  </span>
                </div>
              ))}
              {/* Button to add another Add On Item for this add-on group */}
              <div className="mt-4 flex justify-center">
                <button type="button" onClick={() => handleAddAnotherAddOnItem(addOnName)} className="bg-green text-white px-4 py-2 rounded">
                  + Add Another Add On Item
                </button>
              </div>
            </div>
          ))}
          <div className="mt-6 flex justify-center">
            <button className="bg-green text-white px-4 py-2 rounded w-3/12" onClick={handleCompleteClick}>
              Completed
            </button>
          </div>
        </>
      )}
      {currentPage === 6 && (
        <>
          {/* Header for 6th nested page */}
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
            <button className="bg-green text-white px-4 py-2 rounded">Save Draft</button>
          </div>
          <div className="flex items-center">
            <BsArrowLeft onClick={handleBackClick} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Categoires</span>
          </div>
          <div className="mb-4 flex items-center mt-5">
            <input type="text" className="border border-gray-300 rounded-md p-2 w-full" value={itemName} readOnly />
          </div>
          <h2 className="text-base font-medium text-gray-700 my-4">Types</h2>
          <div className="mb-4">
            <div className="space-y-2 mb-2">
              <label className="inline-flex items-center">
                <input type="checkbox" className="form-checkbox text-green" checked={takeaway} onChange={handleTakeawayChange} />
                <span className="ml-2">Takeaway</span>
              </label>
            </div>
            <div className='space-y-2'>
              <label className="inline-flex items-center">
                <input type="checkbox" className="form-checkbox text-green" checked={delivery} onChange={handleDeliveryChange} />
                <span className="ml-2">Delivery</span>
              </label>
            </div>
          </div>
          <div className="mb-4 flex items-center">
            <span className="block text-base font-medium text-gray-700 mb-1">Same Price?</span>
            <div className="ml-4">
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only" onChange={handleToggleChange} checked={isSamePrice} />
                <div className={`w-11 h-6 rounded-full ${isSamePrice ? 'bg-green' : 'bg-gray-200'} transition-colors`}>
                  <div className={`dot absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform ${isSamePrice ? 'translate-x-5' : ''}`}></div>
                </div>
              </label>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-between">
            <h2 className="text-lg font-semibold mb-4">Item Customization Completed</h2>
            {/* Copy Customization Button */}
            <button className="bg-green text-white px-4 py-2 rounded w-3/12">
              Copy Customization
            </button>
          </div>
          <div className="mt-6">
            <h2 className="text-lg font-semibold mb-2">Item Description</h2>
            {/* Message Input Box */}
            <textarea className="border border-gray-300 rounded-md p-2 w-full" placeholder="Enter item description here..." rows="3" style={{ minHeight: '100px', maxHeight: '250px', resize: 'vertical' }} />
          </div>
          <div className="mt-6 flex justify-center">
            <button className="bg-green text-white px-4 py-2 rounded w-3/12">Completed</button>
          </div>
        </>
      )}
    </div>
  );
};
export default Nested;