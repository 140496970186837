// import React from "react";
import React, { useState } from "react";
import { FaFilter, FaMapMarkerAlt, FaFileDownload } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

const Reviews = () => {
  const [view, setView] = useState("latest");
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("sort");
  const [sortOption, setSortOption] = useState("");

  const handleViewChange = (view) => {
    setView(view);
  };

  const filterOptions = {
    sort: ["High to low rating", "Low to high rating"],
    date: ["Till Now", "Custom Range",],
    business: ["Business A", "Business B", "Business C"],
    type: ["Type 1", "Type 2", "Type 3"],
  };

  const toggleFilterModal = () => {
    setShowFilter(!showFilter);
  };
  return (
    <>
      <div className="container mx-auto">
        <div className="flex items-center justify-between space-x-4 border-b-2 border-gray-300 shadow px-3">
          <h3 className="text-xl font-bold p-3">Customer Review</h3>
          <div className="flex space-x-4">
            <button onClick={() => handleViewChange("latest")} className="btn bg-green text-white rounded h-10 w-40">
              Latest
            </button>
            <button onClick={() => handleViewChange("detailed")} className="btn bg-green text-white rounded h-10 w-40">
              Detailed Views
            </button>
            <button onClick={toggleFilterModal} className="btn bg-green text-white rounded h-10 w-40 flex items-center justify-center">
              <FaFilter className="mr-1" /> Filter
            </button>
            <button onClick={() => handleViewChange("shopLocation")} className="btn bg-green text-white rounded h-10 w-60 flex items-center justify-center">
              <FaMapMarkerAlt className="mr-1" /> Shop Location
            </button>
            <button onClick={() => alert("Downloading FAQ")} className="btn bg-green text-white rounded h-10 w-40 flex items-center justify-center">
              <FaFileDownload className="mr-1" /> FAQs
            </button>
          </div>
        </div>
        {view === "latest" && (
          <div className="mt-4">
            <h3 className="text-xl font-semibold">Latest Reviews</h3>
          </div>
        )}

        {view === "detailed" && (
          <div className="mt-4">
            <h3 className="text-xl font-semibold">Detailed Reviews</h3>
          </div>
        )}

        {view === "shopLocation" && (
          <div className="mt-4">
            <h3 className="text-xl font-semibold">Shop Location</h3>
            <select className="w-full p-2 mt-2 border rounded">
              <option>Select Shop Location</option>
              <option>Shop 1</option>
              <option>Shop 2</option>
              <option>Shop 3</option>
            </select>
          </div>
        )}

        {showFilter && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded p-6 w-1/3 shadow-lg relative">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Filters</h2>
                <button className="text-red-500" onClick={toggleFilterModal}>
                  <IoClose size={24} />
                </button>
              </div>
              <div className="flex space-x-4">
                <div className="w-1/3">
                  <ul>
                    {Object.keys(filterOptions).map((option) => (
                      <li key={option} className={`cursor-pointer p-2 ${selectedFilter === option ? 'bg-green text-white' : 'bg-gray-200 text-gray-700'} rounded mb-2`} onClick={() => setSelectedFilter(option)}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w-2/3">
                  {selectedFilter === 'sort' ? (
                    <ul>
                      {filterOptions.sort.map((option) => (
                        <li key={option} className="p-2">
                          <input type="radio" name="sort" id={option} value={option} className="mr-2" checked={sortOption === option} onChange={(e) => setSortOption(e.target.value)} />
                          <label htmlFor={option}>{option}</label>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <ul>
                      {filterOptions[selectedFilter].map((option) => (
                        <li key={option} className="p-2">
                          <input type="checkbox" id={option} className="mr-2" />
                          <label htmlFor={option}>{option}</label>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="flex justify-between mt-6">
                <button className="bg-red text-white px-4 py-2 rounded" onClick={() => {
                  setSortOption('');
                  toggleFilterModal();
                }}>Clear all filters</button>
                <button className="bg-green text-white px-4 py-2 rounded" onClick={toggleFilterModal}>Apply</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Reviews;
