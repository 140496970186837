import React from 'react'
import { useRef } from 'react';
import { BsArrowLeft, BsUpload } from 'react-icons/bs';

const NoCustomize = ({ itemName, takeaway, delivery, isSamePrice, isCustomizable, onBack, basePrice }) => {
    const fileInputRef = useRef(null); // Ref to trigger file input

    // Function to handle file input click
    const handleUploadClick = () => {
        fileInputRef.current.click(); // Open the file input dialog
    };
    return (
        <>
            <div className='px-16 py-16 pt-10 bg-gray-50 h-screen'>
                <div className="mb-4 flex items-center justify-between">
                    <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
                    <button className="bg-green text-white px-4 py-2 rounded">
                        Save Draft
                    </button>
                </div>
                <div className="flex items-center">
                    <BsArrowLeft onClick={onBack} className="text-green text-2xl cursor-pointer mx-2" />
                    <span className="font-semibold">Categories</span>
                </div>
                <div>
                    <div className="mb-4 flex items-center mt-5">
                        <input type="text" className="border border-gray-300 rounded-md p-2 w-full" value={itemName} readOnly />
                    </div>
                    <h2 className="text-base font-medium text-gray-700 my-4">Types</h2>
                    <div className="mb-4">
                        <div className="space-y-2 mb-2">
                            <label className="inline-flex items-center">
                                <input type="checkbox" className="form-checkbox text-green" checked={takeaway} />
                                <span className="ml-2">Takeaway</span>
                            </label>
                        </div>
                        <div className='space-y-2'>
                            <label className="inline-flex items-center">
                                <input type="checkbox" className="form-checkbox text-green" checked={delivery} />
                                <span className="ml-2">Delivery</span>
                            </label>
                        </div>
                    </div>
                    <div className="mb-4 flex items-center">
                        <span className="block text-base font-medium text-gray-700 mb-1">Same Price?</span>
                        <div className="ml-4">
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" className="sr-only" checked={isSamePrice} />
                                <div className={`w-11 h-6 rounded-full ${isSamePrice ? 'bg-green' : 'bg-gray-200'} transition-colors`}>
                                    <div className={`dot absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform ${isSamePrice ? 'translate-x-5' : ''}`}></div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="mb-4 flex items-center space-x-4">
                        <span className="text-base font-medium text-gray-700">Customizable?</span>
                        <button className={`px-4 py-2 border rounded-md ${isCustomizable ? 'bg-green text-white' : 'bg-gray-300 text-black'}`}>
                            Yes
                        </button>
                        <button className={`px-4 py-2 border rounded-md ${!isCustomizable ? 'bg-green text-white' : 'bg-gray-300 text-black'}`}>
                            No
                        </button>
                    </div>
                    <div className="mb-4 flex items-center mt-5">
                        <label className="block text-base font-medium text-gray-700 mb-1">
                            Price of the Item
                        </label>
                        <span className="flex items-center mx-5">
                            <input type="number" className="border border-gray-300 rounded-md p-2 w-full" value={basePrice} readOnly />
                        </span>
                    </div>
                    <div className="mt-6">
                        <div className="flex items-center justify-between mb-2">
                            <h2 className="text-lg font-semibold">Item Description</h2>
                            <button className="bg-green text-white rounded-md p-2 flex items-center hover:bg-green-600" onClick={handleUploadClick} >
                                <BsUpload size={20} className="mr-2" />
                                Upload Image
                            </button>
                            {/* Hidden File Input */}
                            <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
                        </div>
                        {/* Message Input Box */}
                        <textarea className="border border-gray-300 rounded-md p-2 w-full" placeholder="Enter item description here..." rows="3" style={{ minHeight: '100px', maxHeight: '250px', resize: 'vertical' }} />
                    </div>
                    <div className="mt-6 flex justify-center">
                        <button className="bg-green text-white px-4 py-2 rounded w-3/12">Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoCustomize