import React from 'react'

const ToggleSwitch = ({ isOn, onToggle }) => (
    <div
        onClick={onToggle}
        className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${isOn ? 'bg-green' : 'bg-gray-300'
            }`}
    >
        <div
            className={`bg-white w-4 h-4 rounded-full shadow-md transform ${isOn ? 'translate-x-6' : 'translate-x-0'
                }`}
        />
    </div>
);

export default ToggleSwitch
