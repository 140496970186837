
import React, { useState } from "react";
import "./Payment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleUp,
  faAngleLeftLong,
  faClose,
  faGripLinesVertical,
  faCalendarAlt,
  faAngleDown,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import nopayout from "../assets/nopayout.webp";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";

const Payouts = () => {
  const navigate = useNavigate();

  const [payoutInfo] = useState({
    date: "08 Jul - 14 Jul, 24",
  });

  const [showBreakup, setShowBreakup] = useState(false);
  const [downloadPop, setDownloadPop] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const showBreakupFun = () => {
    setShowBreakup((current) => !current);
  };

  const downloadPayout = () => {
    setDownloadPop((current) => !current);
  };

  const handleSelect = (ranges) => {
    setSelectedRange(ranges.selection);
  };

  const handleApply = () => {
    setShowPicker(false);
  };

  const handleDownload = () => {
    const data = [
      {
        Sno: "",
        UTRNumber: "",
        OrderID: "",
        OrderType: "",
        OrderDate: "",
        Settlement: "",
        Channel: "",
        Amount: "",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };

  const navigateToOrderHistory = () => {
    navigate("/order");
  };

  return (
    <div className="payouts mt-1">
      <p className="ms-10 pt-3 font-semibold">Current Cycle</p>
      <p className="ms-10 mt-3 font-normal report">
        You are on the <span className="font-bold">weekly payout cycle</span> -
        payouts Monday-Sunday are credited to your account the following
        Wednesday
      </p>
      <table className="mt-5 mx-10 bg-white text-center rounded">
        <thead>
          <tr className="border-b">
            <td className="ps-8 py-5">Time period</td>
            <td className="ps-8 py-5">Orders</td>
            <td className="ps-8 py-5">Payout date</td>
            <td className="ps-8 py-5">Estimated payout</td>
            <td className="ps-8 py-5">Statement</td>
            <td className="ps-8 py-5">Transactions</td>
            <td className="ps-8 py-5"></td>
            <td className="ps-8 py-5"></td>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b">
            <td className="ps-8 py-5">{payoutInfo.date}</td>
            <td className="ps-8 py-5">0</td>
            <td className="ps-8 py-5">-</td>
            <td className="ps-8 py-5 estimation">₹0.00</td>
            <td className="ps-8 py-5 statement">-</td>
            <td className="ps-8 py-5 view">
              <button onClick={navigateToOrderHistory}>View</button>
            </td>
            <td className="ps-8 py-5">
              <button onClick={showBreakupFun}>
                {showBreakup ? "Hide breakup" : "Show breakup"}
              </button>
              <FontAwesomeIcon
                icon={showBreakup ? faAngleUp : faAngleDown}
                className="icon ms-5"
              />
            </td>
          </tr>
        </tbody>
      </table>

      {showBreakup && (
        <div className="past-cycle font-semibold flex justify-between ms-10  me-12 mt-3">
          <p>Past cycles</p>
          <div className="calendar mt-4">
              <button onClick={() => setShowPicker(!showPicker)} className="flex items-center">
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" /> Select Date
              </button>
              {showPicker && (
                <div className="mt-3 relative">
                  <DateRangePicker
                    ranges={[selectedRange]}
                    onChange={handleSelect}
                    className="z-50 absolute"
                  />
                  <button onClick={handleApply} className="mt-2 bg-green text-white">
                    Apply
                  </button>
                </div>
              )}
            </div>
          <button className="border rounded px-3 py-2" onClick={downloadPayout}>
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            Download
          </button>
        </div>
      )}
      {downloadPop && (
        <div className="flex items-center justify-center fixed top-0 left-0 right-0 bottom-0">
          <div className="downloadPop bg-white rounded-md pt-3 px-4">
            <div className="flex justify-between items-center mx-4 mb-3">
              <p className="text-2xl font-semibold">SOA format</p>
              <FontAwesomeIcon icon={faClose} onClick={downloadPayout} />
            </div>
            <hr className="divider" />
            <div className="tabs mt-4">
              <button className="px-4 py-2 font-semibold bg-gray-200">Monthly</button>
              <button className="px-4 py-2 font-semibold bg-gray-200 ml-2">Weekly</button>
            </div>
            <div className="radio-group mt-4">
              <label className="flex items-center">
                <input type="radio" name="date" value="June 2024" className="mr-2" />
                June 2024
              </label>
              {/* Add more radio buttons as needed */}
            </div>
            <div className="actions flex justify-end mt-4">
              <button className="border rounded px-3 py-2 mr-2">Send to email</button>
              <button className="border rounded px-3 py-2" onClick={handleDownload}>Download</button>
            </div>
           
          </div>
        </div>
      )}
    </div>
  );
};

const UTRReports = () => {
  const [showContent14Days, setShowContent14Days] = useState(false);
  const [showContent30Days, setShowContent30Days] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [showContent, setShowContent] = useState(false);

  const handleSelect = (ranges) => {
    setSelectedRange(ranges.selection);
  };

  const handleApply = () => {
    setShowContent(true);
    setShowPicker(false);
  };

  const handleClick14Days = () => {
    setShowContent14Days(true);
    setShowContent30Days(false);
  };

  const handleClick30Days = () => {
    setShowContent30Days(true);
    setShowContent14Days(false);
  };

  const handleDownload = () => {
    const data = [
      {
        Sno: "",
        UTRNumber: "",
        OrderID: "",
        OrderType: "",
        OrderDate: "",
        Settlement: "",
        Channel: "",
        Amount: "",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };

  return (
    <div className="grid grid-cols-4">
      <div className="flex justify-start">
        <div className="px-3">
          <button
            onClick={handleClick14Days}
            className="hover:bg-green hover:text-white border-2 text-black rounded h-8 mt-3 w-44 "
          >
            Last 14 days
          </button>

          {showContent14Days && (
            <div className="mt-4">
              <img
                src={nopayout}
                alt="Payout Image for 14 Days"
                className="w-full h-40"
              />
              <p className="text-center text-gray-500 mt-2">
                No payouts for this date range
              </p>
            </div>
          )}
        </div>

        <div className="px-3">
          <button
            onClick={handleClick30Days}
            className="hover:bg-green hover:text-white text-black border-2 rounded h-8 mt-3 w-44 "
          >
            Last 30 days
          </button>

          {showContent30Days && (
            <div className="mt-4">
              <img
                src={nopayout}
                alt="Payout Image for 30 Days"
                className="w-full h-40"
              />
              <p className="text-center text-gray-500 mt-2">
                No payouts for this date range
              </p>
            </div>
          )}
        </div>

        <div className="px-3">
          <button
            onClick={() => setShowPicker(!showPicker)}
            className="hover:bg-green hover:text-white text-black border-2 rounded h-8 mt-3 w-44"
          >
            Select Date
          </button>

          {showPicker && (
            <div className="mt-3 relative">
              <DateRangePicker
                ranges={[selectedRange]}
                onChange={handleSelect}
                className="z-50 absolute"
              />
              <button
                onClick={handleApply}
                className="mt-2 text-black rounded h-8 w-24"
              >
                Apply
              </button>
            </div>
          )}

          {showContent && (
            <div className="mt-4">
              <img
                src={nopayout}
                alt="Payout Image"
                className="w-full h-auto"
              />
              <p className="text-center text-gray-500 mt-2">
                No payouts for this date range
              </p>
            </div>
          )}
        </div>

        <div className="px-3">
          <button
            onClick={handleDownload}
            className="bg-green text-white rounded h-8 w-44 mt-3"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

const Invoices = () => {
  const [activeTab, setActiveTab] = useState("onlineOrdering");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleDownloadInvoice = (fileName) => {
    const ws = XLSX.utils.json_to_sheet([
      {
        InvoiceNumber: "Z24-TNRO-030334",
        Month: "Feb",
        Type: "Online ordering",
      },
      {
        InvoiceNumber: "Z24-TNRO-026907",
        Month: "Jan",
        Type: "Online ordering",
      },
      {
        InvoiceNumber: "Z24-TNOO-149358",
        Month: "Jan",
        Type: "Online ordering",
      },
    ]);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, fileName);
  };

  const data = [
    {
      invoiceNumber: "Z24-TNRO-030334",
      month: "Feb",
      type: "Online ordering",
      fileName: "Invoice_Feb.xlsx",
    },
    {
      invoiceNumber: "Z24-TNRO-026907",
      month: "Jan",
      type: "Online ordering",
      fileName: "Invoice_Jan_1.xlsx",
    },
    {
      invoiceNumber: "Z24-TNOO-149358",
      month: "Jan",
      type: "Online ordering",
      fileName: "Invoice_Jan_2.xlsx",
    },
  ];

  return (
    <div className="flex flex-col mt-5">
      <div className="flex space-x-4 px-5 mb-4">
        <button
          className={`px-4 py-2 font-semibold ${
            activeTab === "onlineOrdering"
              ? "bg-green rounded text-white"
              : "bg-gray-200"
          }`}
          onClick={() => handleTabClick("onlineOrdering")}
        >
          Online ordering
        </button>
        <button
          className={`px-4 py-2 font-semibold rounded ${
            activeTab === "recovery"
              ? "bg-green rounded text-white"
              : "bg-gray-200"
          }`}
          onClick={() => handleTabClick("recovery")}
        >
          Recovery
        </button>
      </div>
      {activeTab === "onlineOrdering" ? (
        <div className="w-full">
          <h2 className="text-lg font-semibold px-5">
            Online Ordering Invoices
          </h2>
         <div className="px-5 py-5">
         <table className="min-w-full border-collapse border border-gray-200">
            <thead>
              <tr className="bg-green text-white">
                <th className="border border-gray-200 px-4 py-2">
                  Invoice number
                </th>
                <th className="border border-gray-200 px-4 py-2">Month</th>
                <th className="border border-gray-200 px-4 py-2">Type</th>
                <th className="border border-gray-200 px-4 py-2">
                  Download invoice
                </th>
                <th className="border border-gray-200 px-4 py-2">
                  Download Credit/Debit Note
                </th>
                <th className="border border-gray-200 px-4 py-2">
                  Download TCS
                </th>
              </tr>
              <tr className="bg-slate-50 text-black">
                <th className="border border-gray-200 px-4 py-2" colSpan="6">
                  Financial year: 2024
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="bg-white border-b border-gray-200">
                  <td className="border border-gray-200 px-4 py-2 text-center">
                    {item.invoiceNumber}
                  </td>
                  <td className="border border-gray-200 px-4 py-2 text-center">
                    {item.month}
                  </td>
                  <td className="border border-gray-200 px-4 py-2 text-center">
                    {item.type}
                  </td>
                  <td className="border border-gray-200 px-4 py-2 text-center">
                    <button
                      className="bg-green h-8 w-32 rounded text-white"
                      onClick={() => handleDownloadInvoice(item.fileName)}
                    >
                      <i className="fas fa-download"></i> Download
                    </button>
                  </td>
                  <td className="border border-gray-200 px-4 py-2 text-center">
                    -
                  </td>
                  <td className="border border-gray-200 px-4 py-2 text-center">
                    -
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      ) : (
        <div className="w-full max-w-5xl text-center">
          <h2 className="text-lg font-semibold mb-4">Recovery Invoices</h2>
          <img
            src={nopayout}
            alt="No invoices available"
            className="mx-auto h-20 mt-4"
          />
          <p>No invoices available</p>
        </div>
      )}
    </div>
  );
};

const Taxes = () => {
  const handleDownloadTax = (fileName) => {
    const ws = XLSX.utils.json_to_sheet([
      { Serial: 1, Quarter: "Q2", DateRange: "01 Jul - 30 Sep" },
      { Serial: 2, Quarter: "Q1", DateRange: "01 Apr - 30 Jun" },
    ]);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, fileName);
  };

  const data = [
    {
      serial: 1,
      quarter: "Q2",
      dateRange: "01 Jul - 30 Sep",
      fileName: "TDS_Q2.xlsx",
    },
    {
      serial: 2,
      quarter: "Q1",
      dateRange: "01 Apr - 30 Jun",
      fileName: "TDS_Q1.xlsx",
    },
  ];

  return (
    <div className="flex  items-center mt-5">
      <div className="w-full">
        <h2 className="text-lg font-semibold px-5">TDS certificates</h2>
       <div className="px-5 py-5">
       <table className="min-w-full border-collapse border border-gray-200">
          <thead>
            <tr className="bg-green text-white">
              <th className="border border-gray-200 px-4 py-2">S.No.</th>
              <th className="border border-gray-200 px-4 py-2">Quarter</th>
              <th className="border border-gray-200 px-4 py-2">Date range</th>
              <th className="border border-gray-200 px-4 py-2">Download TDS</th>
            </tr>
            <tr className="bg-slate text-black">
              <th className="border border-gray-200 px-4 py-2" colSpan="4">
                Financial year: 2024
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="bg-white border-b border-gray-200">
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {item.serial}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {item.quarter}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {item.dateRange}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  <button
                    className="bg-green text-white h-6 w-28 rounded"
                    onClick={() => handleDownloadTax(item.fileName)}
                  >
                    <i className="fas fa-download"></i> Download
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       </div>
      </div>
    </div>
  );
};

const Payment = () => {
  const [paymentButton, setPaymentButton] = useState("Payouts");

  const handleButtonClick = (buttonName) => {
    setPaymentButton(buttonName);
  };

  const [payoutInfo] = useState({
    date: "17 Jun - 23 Jun, 24",
  });

  const [showBreakup, setShowBreakup] = useState(false);
  const showBreakupFun = () => {
    setShowBreakup((current) => !current);
  };

  const [downloadPop, setDownloadPop] = useState(false);
  const downloadPayout = () => {
    setDownloadPop((current) => !current);
  };

  return (
    <div className="payment">
      <div className="border-b-2 border-gray-300 shadow">
      <h3 className="heading text-xl font-bold p-3">Payout</h3>
      </div>
      <hr className="divider" />
      <div className="payment-btns ms-10 mt-5">
        <button
          className={`mr-16 pb-2 font-semibold  ${
            paymentButton === "Payouts" && "payment-btn"
          }`}
          onClick={() => handleButtonClick("Payouts")}
        >
          Payouts
        </button>
        <button
          className={`mr-16 pb-2 font-semibold  ${
            paymentButton === "utrReports" && "payment-btn"
          }`}
          onClick={() => handleButtonClick("utrReports")}
        >
          UTR Reports
        </button>
        <button
          className={`mr-16 pb-2 font-semibold  ${
            paymentButton === "inVoices" && "payment-btn"
          }`}
          onClick={() => handleButtonClick("inVoices")}
        >
          Invoices
        </button>

        <button
          className={`mr-16 pb-2 font-semibold  ${
            paymentButton === "taxes" && "payment-btn"
          }`}
          onClick={() => handleButtonClick("taxes")}
        >
          Taxes
        </button>
      </div>

      <hr className="divider" />

      {paymentButton === "Payouts" && (
        <Payouts
          payoutInfo={payoutInfo}
          showBreakup={showBreakup}
          showBreakupFun={showBreakupFun}
          downloadPayout={downloadPayout}
          downloadPop={downloadPop}
        />
      )}
      {paymentButton === "utrReports" && <UTRReports />}
      {paymentButton === "inVoices" && <Invoices />}
      {paymentButton === "taxes" && <Taxes />}
    </div>
  );
};

export default Payment;

