import React, { useState, useEffect } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { FaLeaf, FaBox, FaFileInvoiceDollar, FaInfoCircle, FaSearch, FaTimes } from "react-icons/fa";
import { MdOutlineAccessTime } from "react-icons/md";
import SimpleBar from "simplebar-react";
import axios from "axios";
import DeliveryMenu from "./DeliveryMenu";
import { fetchMenuItems } from "../../API/Api";
import Packages from "./Packages";
import Help from "./Help";
import GstDeclaration from "./GstDeclaration";
import Charges from "./Charges";
import MenuDetails from "./MenuDetails";
import Nested from './Nested';
import { useLocation, useNavigate } from "react-router-dom";
import { setLocalItem } from "../../API/Localstorage.helpers";
function useQuary() {
  return new URLSearchParams(useLocation().search);
}

function MenuHead() {
  const quary = useQuary()
  const navigate = useNavigate()
  const [menuDataForApi, setMenuDataForApi] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [open, setOpen] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState("Delivery Menu");
  const [restaurantInfo, setRestaurantInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const token = localStorage.getItem("accessToken");
  console.log("Token", token);

  useEffect(()=>{
    if(!token) {
      navigate("/login")
    }
  },[token, navigate])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchMenuItems();
        setRestaurantInfo({
          name: data.businessName,
          address: data.addressLine2,
          phone: data.phone,
          resId: data.restaurantIdRef,
          registrationStatus: data.registrationStatus,
          lastLogin: formatDate(data.createdAt),
          lastUpdated: formatDate(data.updatedAt),
          imageUrl: data.restaurantDetails.imageUrl,
        });
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
        console.error("Error fetching restaurant info:", error.message); // Log the error
      }
    };
    fetchData();
  }, [token]);

  const handleMenuClick = (menuTitle) => {
    setSelectedMenu(menuTitle);
  };

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleItemClick = (item) => {
    navigate('/menuhead?steps=MenuDetails&step1=1')
    setLocalItem('MenuDetailsItem', item)
    setSelectedItem(item); // Set selected item to display MenuDetails
  };

  const handleBack = () => {
    navigate('/menuhead')
    setSelectedItem(null); // Reset selectedItem to go back to the main view
  };

  const headerItems = [
    {
      type: 'input',
      placeholder: 'Search by category, item name...',
      className: 'bg-gray-100 outline-none flex-grow',
      icon: <FaSearch className="text-gray-500" />,
      value: searchInput,
      onChange: handleInputChange,
    },
    {
      type: 'button',
      text: 'All Changes Saved',
      className: 'bg-green text-white px-4 py-2 rounded-md',
    },
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const menus = [
    { title: "Delivery Menu", icon: <FaLeaf /> },
    { title: "Packages", icon: <FaBox /> },
    { title: "GST Declaration", icon: <FaFileInvoiceDollar /> },
    { title: "Charges", icon: <MdOutlineAccessTime /> },
    { title: "Help", icon: <FaInfoCircle /> },
  ];

  const handleSaveChanges = () => {
    if (deletedItems.length === 0) {
      alert('Nothing to delete');
      return;
    }
    setItemsToDelete(deletedItems);
    setShowConfirmModal(true);
  };

  const handleConfirmDeletion = async () => {
    if (deleteConfirmation.trim() !== 'DELETE') {
      alert('Please type DELETE to confirm deletion.');
      return;
    }

    setIsSaving(true);

    const apiBody = {
      role: "admin",
      restaurantId: "cly8ckqph1mqunn2glt6t00bu",
      mode: "Takeaway",
      user_id: "clxydzwnw000013sckg2u8o0x",
      menuCategories: menuDataForApi.map(category => ({
        id: category.id,
        sortOrder: category.sortOrder,
        menuSubCategories: category.menuSubCategories.map(subCategory => ({
          id: subCategory.id,
          sortOrder: subCategory.sortOrder,
          menuItems: subCategory.menuItems.map(item => ({
            id: item.id,
            sortOrder: item.sortOrder,
          })),
        })),
      })),
      deletedItems: deletedItems,
    };

    try {
      const response = await axios.post("https://api.thegenie.in/api/restaurants/update_sort_order", { api_body: apiBody }, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error saving changes:", error);
    } finally {
      setIsSaving(false);
      setShowDeleteModal(false);
      setShowConfirmModal(false)
      setDeleteConfirmation('');
      setDeletedItems([]);
    }
  };

  return (
    <div className="menu-head">
      <div className="flex">
        <SimpleBar
          style={{ height: "100vh" }}
          className={`bg-light-grey p-4 duration-300 border-r border-black relative ${open ? "w-64" : "w-20"}`}
        >
          <div className="flex items-center justify-between">
            <div className="text-green text-xl font-bold">Genie</div>
            <div className={`text-black text-sm font-semibold ${open ? "block" : "hidden"}`}>MENU DASHBOARD</div>
          </div>
          {open && (
            <>
              <img
                src={restaurantInfo.imageUrl}
                alt="Restaurant"
                className="w-full h-32 object-cover rounded mt-4"
              />
              <div className="text-dark mt-4">
                <h1 className="text-lg font-semibold">{restaurantInfo.name}</h1>
                <p className="text-sm">{restaurantInfo.address}</p>
                <p className="text-sm">{restaurantInfo.phone}</p>
                <p className="text-sm mt-2">RES ID: {restaurantInfo.resId}</p>
                <a href="#" className="text-sm text-blue-400 underline">{restaurantInfo.registrationStatus}</a>
                <p className="text-sm mt-2">Last login: {restaurantInfo.lastLogin}</p>
              </div>
            </>
          )}
          <div className="text-gray-400 mt-4">
            <ul className="mt-4">
              {menus.map((menu, index) => (
                <li
                  key={index}
                  className={`flex items-center gap-x-4 p-2 rounded-md mt-2 cursor-pointer hover:bg-green text-black hover:text-white ${selectedMenu === menu.title ? "bg-green text-white" : "text-green hover:bg-green hover:text-white"}`}
                  onClick={() => handleMenuClick(menu.title)}
                >
                  <span className="text-xl">{menu.icon}</span>
                  <span className={`text-sm ${!open && "hidden"}`}>{menu.title}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="absolute bottom-4 text-gray-500 text-xs">
            <p>Last updated on</p>
            <p>{restaurantInfo.lastLogin}</p>
          </div>

          <button
            onClick={() => setOpen(!open)}
            className="absolute bg-green text-white rounded-full text-xl -right-3 top-1/2 transform -translate-y-1/2 cursor-pointer p-1"
          >
            <BsArrowLeftShort className={`${open ? "" : "rotate-180"}`} />
          </button>
        </SimpleBar>
        <SimpleBar style={{ height: "100vh" }} className="w-full">
          {/* Conditional Rendering */}
          {quary.get('steps') === 'MenuDetails' ? (
            <MenuDetails onBack={handleBack} />
          ) : (
            <>
              <header className="bg-white shadow p-4 flex justify-between items-center">
                {headerItems.map((item, index) => (
                  <div key={index} className={`flex items-center ${item.type === 'input' ? 'bg-gray-100 rounded-md px-3 py-2 w-1/2' : ''}`}>
                    {item.type === 'input' && (
                      <>
                        <input
                          type="text"
                          placeholder={item.placeholder}
                          className={item.className}
                          value={item.value}
                          onChange={item.onChange}
                        />
                        {item.icon}
                      </>
                    )}
                    {item.type === 'button' && (
                      <button className={item.className}
                        onClick={handleSaveChanges}
                        disabled={isSaving}>
                        {isSaving ? "Saving..." : "All Changes Saved"}
                      </button>
                    )}
                  </div>
                ))}
              </header>
              {selectedMenu === "Delivery Menu" && (
                <DeliveryMenu
                  setMenuDataForApi={setMenuDataForApi}
                  onItemClick={handleItemClick}
                />
              )}
              {selectedMenu === "Packages" && <Packages />}
              {selectedMenu === "GST Declaration" && <GstDeclaration />}
              {selectedMenu === "Charges" && <Charges />}
              {selectedMenu === "Help" && <Help />}
            </>
          )}
        </SimpleBar>
      </div >

      {showConfirmModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex justify-between items-center border-b pb-2 mb-4">
              <h3 className="text-lg font-semibold">{itemsToDelete.length > 0 ? "Are you sure you want to delete these items?" : "Nothing to delete"}</h3>
              <button onClick={() => { setShowConfirmModal(false); if (itemsToDelete.length === 0) handleConfirmDeletion(); }} className="text-gray-500 hover:text-gray-800">
                <FaTimes />
              </button>
            </div>
            {itemsToDelete.length > 0 && (
              <div className="mb-4">
                {itemsToDelete.map((item, index) => (
                  <div key={index} className="flex items-center mb-2 px-4">
                    <input type="checkbox" className="mr-2" onChange={(e) => {
                      const checked = e.target.checked;
                      setItemsToDelete(prevState => prevState.map((el, idx) => idx === index ? { ...el, checked } : el));
                    }} />
                    {item.type === 'item' && <span>{item.name} - {item.basePrice}</span>}
                    {item.type !== 'item' && <span>{item.name}</span>}
                  </div>
                ))}
              </div>
            )}
            <div className="flex justify-end">
              {itemsToDelete.length > 0 && (
                <>
                  <button
                    onClick={() => setShowConfirmModal(false)}
                    className="bg-green text-white px-4 py-2 rounded-md mr-2"
                  >
                    No
                  </button>
                  <button
                    onClick={() => setShowDeleteModal(true)}
                    className="bg-red text-white px-4 py-2 rounded-md"
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )
      }

      {
        showDeleteModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex justify-between items-center border-b pb-2 mb-4">
                <h3 className="text-lg font-semibold">Type DELETE to confirm deletion</h3>
                <button onClick={() => setShowDeleteModal(false)} className="text-gray-500 hover:text-gray-800">
                  <FaTimes />
                </button>
              </div>
              <input
                type="text"
                value={deleteConfirmation}
                onChange={(e) => setDeleteConfirmation(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="DELETE"
              />
              {deleteConfirmation.trim() !== 'DELETE' && (
                <p className="text-red-500 mb-4">Please type DELETE to confirm deletion.</p>
              )}
              <div className="flex justify-end">
                <button
                  onClick={handleConfirmDeletion}
                  className={`bg-red text-white px-4 py-2 rounded-md ${deleteConfirmation.trim() !== 'DELETE' ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={deleteConfirmation.trim() !== 'DELETE'}
                >
                  Confirm Deletion
                </button>
              </div>
            </div>
          </div>
        )
      }
    </div >
  );
}

export default MenuHead;