import React, { useEffect, useState } from 'react';
import ImageGalleryContent from '../Menupages/ImageGalleryContent';
import { fetchMenuItems } from '../../API/Api';
import { FaCaretDown, FaCaretRight,FaRupeeSign, FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign, faImage, faLeaf, faEdit, faTrash, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ReactSortable } from "react-sortablejs";
import Sortable, { Swap } from "sortablejs";
import ToggleSwitch from './ToggleSwitch';
import AddOnsContent from './AddOnsContent';
import { useLocation, useNavigate } from 'react-router-dom';

// Mount the plugins
Sortable.mount(new Swap());

function DeliveryMenu({ setMenuDataForApi, setDeletedItems, onItemClick }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('items');
  const [menuData, setMenuData] = useState([]);
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [activeSubCategoryId, setActiveSubCategoryId] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [expandedSubCategories, setExpandedSubCategories] = useState(new Set());
  const [toggleStates, setToggleStates] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newSubCategoryName, setNewSubCategoryName] = useState('');
  const [newItemName, setNewItemName] = useState('');
  const [newItemPrice, setNewItemPrice] = useState('');
  const [editingItem, setEditingItem] = useState(null);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const data = await fetchMenuItems();
        setMenuData(data.menuCategories || []);
        console.log("Menu Data:", data.menuCategories);
        navigate(location.pathname, { state: data }); // Store the API response in location state
      } catch (error) {
        console.error("Error fetching menu items:", error.response?.data || error.message);
      }
    };
    fetchMenu();
  }, [navigate, location.pathname]);

  useEffect(() => {
    setMenuDataForApi(menuData);
  }, [menuData, setMenuDataForApi]);

  const updateSortOrder = (list) => {
    return list.map((item, index) => ({
      ...item,
      sortOrder: index + 1 // Assign sort order starting from 1
    }));
  };

  const handleCategoryClick = (categoryId) => {
    setActiveCategoryId(activeCategoryId === categoryId ? null : categoryId);
    setActiveSubCategoryId(null);
    setExpandedCategories(prev => {
      const newSet = new Set(prev);
      newSet.has(categoryId) ? newSet.delete(categoryId) : newSet.add(categoryId);
      return newSet;
    });
  };

  const handleSubCategoryClick = (subCategoryId) => {
    setActiveSubCategoryId(activeSubCategoryId === subCategoryId ? null : subCategoryId);
    setExpandedSubCategories(prev => {
      const newSet = new Set(prev);
      newSet.has(subCategoryId) ? newSet.delete(subCategoryId) : newSet.add(subCategoryId);
      return newSet;
    });
  };

  const handleCategoryListChange = (newState) => {
    const updatedCategories = updateSortOrder(newState);
    setMenuData(updatedCategories);
  };

  const handleSubCategoryListChange = (newState) => {
    const updatedCategories = menuData.map((category) =>
      category.id === activeCategoryId
        ? { ...category, menuSubCategories: updateSortOrder(newState) }
        : category
    );
    setMenuData(updatedCategories);
  };

  const handleItemListChange = (newState) => {
    const updatedCategories = menuData.map((category) =>
      category.id === activeCategoryId
        ? {
          ...category,
          menuSubCategories: category.menuSubCategories.map((subCat) =>
            subCat.id === activeSubCategoryId
              ? { ...subCat, menuItems: updateSortOrder(newState) }
              : subCat
          ),
        }
        : category
    );
    setMenuData(updatedCategories);
  };

  const selectTab = (content) => {
    setActiveTab(content);
  };

  const handleMouseEnter = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleMouseLeave = () => {
    setHoveredItemId(null);
  };

  const handleToggle = (itemId) => {
    setToggleStates((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
    if (!toggleStates[itemId]) {
      setSelectedItem(itemId);
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleAddCategory = () => {
    const newCategory = {
      id: Date.now().toString(),
      name: newCategoryName,
      sortOrder: menuData.length + 1,
      menuSubCategories: [],
    };
    setMenuData([...menuData, newCategory]);
    setNewCategoryName('');
  };

  const handleAddSubCategory = () => {
    const updatedCategories = menuData.map((category) =>
      category.id === activeCategoryId
        ? {
          ...category,
          menuSubCategories: [
            ...category.menuSubCategories,
            {
              id: Date.now().toString(),
              name: newSubCategoryName,
              sortOrder: category.menuSubCategories.length + 1,
              menuItems: [],
            },
          ],
        }
        : category
    );
    setMenuData(updatedCategories);
    setNewSubCategoryName('');
  };

  const handleAddItem = () => {
    const updatedCategories = menuData.map((category) =>
      category.id === activeCategoryId
        ? {
          ...category,
          menuSubCategories: category.menuSubCategories.map((subCat) =>
            subCat.id === activeSubCategoryId
              ? {
                ...subCat,
                menuItems: [
                  ...subCat.menuItems,
                  {
                    id: Date.now().toString(),
                    name: newItemName,
                    basePrice: newItemPrice,
                    sortOrder: subCat.menuItems.length + 1,
                  },
                ],
              }
              : subCat
          ),
        }
        : category
    );
    setMenuData(updatedCategories);
    setNewItemName('');
    setNewItemPrice('');
  };

  const handleEditCategory = (categoryId) => {
    const category = menuData.find((category) => category.id === categoryId);
    setNewCategoryName(category.name);
    setEditingItem({ type: 'category', id: categoryId });
  };

  const handleEditSubCategory = (subCategoryId) => {
    const subCategory = menuData
      .find((category) => category.id === activeCategoryId)
      .menuSubCategories.find((subCategory) => subCategory.id === subCategoryId);
    setNewSubCategoryName(subCategory.name);
    setEditingItem({ type: 'subcategory', id: subCategoryId });
  };

  const handleEditItem = (itemId) => {
    const item = menuData
      .find((category) => category.id === activeCategoryId)
      .menuSubCategories.find((subCategory) => subCategory.id === activeSubCategoryId)
      .menuItems.find((item) => item.id === itemId);
    setNewItemName(item.name);
    setNewItemPrice(item.basePrice);
    setEditingItem({ type: 'item', id: itemId });
  };

  const handleSaveEdit = () => {
    if (editingItem) {
      const updatedCategories = menuData.map((category) => {
        if (editingItem.type === 'category' && category.id === editingItem.id) {
          return { ...category, name: newCategoryName };
        }
        if (editingItem.type === 'subcategory' && category.id === activeCategoryId) {
          return {
            ...category,
            menuSubCategories: category.menuSubCategories.map((subCat) =>
              subCat.id === editingItem.id ? { ...subCat, name: newSubCategoryName } : subCat
            ),
          };
        }
        if (editingItem.type === 'item' && category.id === activeCategoryId) {
          return {
            ...category,
            menuSubCategories: category.menuSubCategories.map((subCat) =>
              subCat.id === activeSubCategoryId
                ? {
                  ...subCat,
                  menuItems: subCat.menuItems.map((item) =>
                    item.id === editingItem.id
                      ? { ...item, name: newItemName, basePrice: newItemPrice }
                      : item
                  ),
                }
                : subCat
            ),
          };
        }
        return category;
      });
      setMenuData(updatedCategories);
      setEditingItem(null);
      setNewCategoryName('');
      setNewSubCategoryName('');
      setNewItemName('');
      setNewItemPrice('');
    }
  };

  const handleDeleteCategory = (categoryId) => {
    const updatedCategories = menuData.filter((category) => category.id !== categoryId);
    setMenuData(updatedCategories);
    setDeletedItems((prev) => [...prev, { type: 'category', id: categoryId, name: menuData.find(category => category.id === categoryId).name }]);
  };

  const handleDeleteSubCategory = (subCategoryId) => {
    const updatedCategories = menuData.map((category) =>
      category.id === activeCategoryId
        ? {
          ...category,
          menuSubCategories: category.menuSubCategories.filter(
            (subCat) => subCat.id !== subCategoryId
          ),
        }
        : category
    );
    setMenuData(updatedCategories);
    setDeletedItems((prev) => [
      ...prev,
      {
        type: 'subcategory',
        id: subCategoryId,
        name: menuData
          .find((category) => category.id === activeCategoryId)
          .menuSubCategories.find((subCategory) => subCategory.id === subCategoryId).name,
      },
    ]);
  };

  const handleDeleteItem = (itemId) => {
    const updatedCategories = menuData.map((category) =>
      category.id === activeCategoryId
        ? {
          ...category,
          menuSubCategories: category.menuSubCategories.map((subCat) =>
            subCat.id === activeSubCategoryId
              ? { ...subCat, menuItems: subCat.menuItems.filter((item) => item.id !== itemId) }
              : subCat
          ),
        }
        : category
    );
    setMenuData(updatedCategories);
    setDeletedItems((prev) => [
      ...prev,
      {
        type: 'item',
        id: itemId,
        name: menuData
          .find((category) => category.id === activeCategoryId)
          .menuSubCategories.find((subCategory) => subCategory.id === activeSubCategoryId)
          .menuItems.find((item) => item.id === itemId).name,
        basePrice: menuData
          .find((category) => category.id === activeCategoryId)
          .menuSubCategories.find((subCategory) => subCategory.id === activeSubCategoryId)
          .menuItems.find((item) => item.id === itemId).basePrice,
      },
    ]);
  };

  return (
    <div className="flex flex-col">
      <div className="flex border-b-2 border-gray-300 shadow">
        <div
          className={`py-4 px-4 text-center font-semibold text-lg cursor-pointer ${activeTab === 'items' ? 'border-b-4 border-green text-green' : 'text-gray-700'}`}
          onClick={() => selectTab('items')}
        >
          Items
        </div>
        <div
          className={`py-4 px-4 text-center font-semibold text-lg cursor-pointer ${activeTab === 'add-ons' ? 'border-b-4 border-green text-green' : 'text-gray-700'}`}
          onClick={() => selectTab('add-ons')}
        >
          Add-ons
        </div>
        <div
          className={`py-4 px-4 text-center font-semibold text-lg cursor-pointer ${activeTab === 'image-gallery' ? 'border-b-4 border-green text-green' : 'text-gray-700'}`}
          onClick={() => selectTab('image-gallery')}
        >
          Image Gallery
        </div>
      </div>

      <>
        {activeTab === 'add-ons' && <AddOnsContent />}
        {activeTab === 'image-gallery' && <ImageGalleryContent />}
      </>

      {activeTab === 'items' && (
        <>
          <div className="mx-4 my-4 mt-2 p-4 bg-green rounded-md">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faCaretDown} className="mr-2 text-white" />
              <span className="text-xl font-semibold text-white">Starters</span>
            </div>
            <div className="flex items-center mt-2 text-white">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faLeaf} className="mr-2" />
                <span className="text-lg">Paneer Tikka</span>
              </div>
              <div className="flex items-center mx-8">
                <FontAwesomeIcon icon={faImage} className="mr-2" />
                <FontAwesomeIcon icon={faIndianRupeeSign} className="mr-1" />
                <span className="text-lg">200</span>
              </div>
            </div>
          </div>
          <div className="items-center my-2 mx-3">
            {/* Menu Categories */}
            <div>
              <ReactSortable
                list={menuData}
                setList={handleCategoryListChange}
                animation={200}
                swapThreshold={0.65}
              >
                {menuData.map((category) => (
                  <div key={category.id} className='border-b border-gray-200'>
                    <div className='flex items-center p-3 hover:bg-gray-100'>
                      <button
                        onClick={() => handleCategoryClick(category.id)}
                        className="text-left flex items-center py-2 px-1 w-full"
                      >
                        {expandedCategories.has(category.id) ?
                          <FaCaretDown className='text-2xl' /> :
                          <FaCaretRight className='text-2xl' />
                        }
                        {category.name}
                        <p className="ml-auto mx-3">{category.menuSubCategories.reduce((acc, subCat) => acc + subCat.menuItems.length, 0)} items</p>
                      </button>
                      <div className="ml-2 flex">
                        <button onClick={() => handleEditCategory(category.id)} className="mr-2 text-amber">
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button onClick={() => handleDeleteCategory(category.id)} className='text-amber'>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </div>
                    {activeCategoryId === category.id && (
                      <div className="ml-4">
                        <ReactSortable
                          list={category.menuSubCategories}
                          setList={handleSubCategoryListChange}
                          animation={200}
                          swapThreshold={0.65}
                        >
                          {category.menuSubCategories.map((subCategory) => (
                            <div key={subCategory.id}>
                              <div className='flex items-center p-3 hover:bg-gray-100'>
                                <button
                                  onClick={() => handleSubCategoryClick(subCategory.id)}
                                  className="text-left flex items-center py-2 w-full"
                                >
                                  {expandedSubCategories.has(subCategory.id) ?
                                    <FaCaretDown className='text-2xl' /> :
                                    <FaCaretRight className='text-2xl' />
                                  }
                                  {subCategory.name ? subCategory.name : category.name}
                                  <span className="ml-auto mx-3">{subCategory.menuItems.length} items</span>
                                </button>
                                <div className="ml-2 flex">
                                  <button onClick={() => handleEditSubCategory(subCategory.id)} className="mr-2 text-amber">
                                    <FontAwesomeIcon icon={faEdit} />
                                  </button>
                                  <button onClick={() => handleDeleteSubCategory(subCategory.id)} className='text-red-300'>
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </div>
                              </div>
                              {activeSubCategoryId === subCategory.id && (
                                <div className="ml-4">
                                  <table className="w-full text-left bg-white">
                                    <tbody>
                                      <ReactSortable
                                        list={subCategory.menuItems}
                                        setList={handleItemListChange}
                                        animation={200}
                                        swapThreshold={0.65}
                                      >
                                        {subCategory.menuItems.map((item) => (
                                          <tr
                                            key={item.id}
                                            className="hover:bg-gray-50"
                                            onMouseEnter={() => handleMouseEnter(item.id)}
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <td className="p-3">
                                              <span className="text-base text-gray-700 cursor-pointer" onClick={() => onItemClick(item)}>
                                                {item.name}
                                              </span>
                                            </td>
                                            <td className="p-3">
                                              <span className="text-base text-gray-700 flex items-center">
                                                <FaRupeeSign className="mr-1" />
                                                {item.basePrice}
                                              </span>
                                            </td>
                                            <td className="p-3 flex space-x-2">
                                              <button onClick={() => handleEditItem(item.id)} className="text-blue-500">
                                                <FontAwesomeIcon icon={faEdit} />
                                              </button>
                                              <button onClick={() => handleDeleteItem(item.id)} className="text-red-500">
                                                <FontAwesomeIcon icon={faTrash} />
                                              </button>
                                            </td>
                                            <td className="p-3">
                                              {hoveredItemId === item.id && (
                                                <ToggleSwitch
                                                  isOn={toggleStates[item.id] || false}
                                                  onToggle={() => handleToggle(item.id)}
                                                />
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </ReactSortable>
                                    </tbody>
                                  </table>

                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      placeholder="New Item Name"
                                      value={newItemName}
                                      onChange={(e) => setNewItemName(e.target.value)}
                                      className="mr-2 p-2 border border-gray-300 rounded"
                                    />
                                    <input
                                      type="text"
                                      placeholder="New Item Price"
                                      value={newItemPrice}
                                      onChange={(e) => setNewItemPrice(e.target.value)}
                                      className="mr-2 p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                      onClick={editingItem && editingItem.type === 'item' ? handleSaveEdit : handleAddItem}
                                      className={`bg-green text-white px-3 py-2 rounded-md ${editingItem && editingItem.type === 'item' ? 'bg-blue-500' : ''}`}
                                    >
                                      {editingItem && editingItem.type === 'item' ? 'Save Edit' : 'Add Item'}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </ReactSortable>
                        <div className="mt-2 mb-3">
                          <input
                            type="text"
                            placeholder="New SubCategory Name"
                            value={newSubCategoryName}
                            onChange={(e) => setNewSubCategoryName(e.target.value)}
                            className="mr-2 p-2 border border-gray-300 rounded"
                          />
                          <button
                            onClick={editingItem && editingItem.type === 'subcategory' ? handleSaveEdit : handleAddSubCategory}
                            className={`bg-green text-white px-3 py-2 rounded-md ${editingItem && editingItem.type === 'subcategory' ? 'bg-blue-500' : ''}`}
                          >
                            {editingItem && editingItem.type === 'subcategory' ? 'Save Edit' : 'Add SubCategory'}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </ReactSortable>
              <div className="mt-4">
                <input
                  type="text"
                  placeholder="New Category Name"
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  className="mr-2 p-2 border border-gray-300 rounded"
                />
                <button
                  onClick={editingItem && editingItem.type === 'category' ? handleSaveEdit : handleAddCategory}
                  className={`bg-green text-white px-3 py-2 rounded-md ${editingItem && editingItem.type === 'category' ? 'bg-blue-500' : ''}`}
                >
                  {editingItem && editingItem.type === 'category' ? 'Save Edit' : 'Add Category'}
                </button>
              </div>
            </div>
            {showModal && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white rounded-lg shadow-lg">
                  <div className="flex justify-between items-center border-b pb-2 mb-4 p-6">
                    <h3 className="text-lg font-semibold">Turn-on time for selected items</h3>
                    <button onClick={closeModal} className="text-gray-500 hover:text-gray-800">
                      <FaTimes />
                    </button>
                  </div>
                  <p className="mb-4 text-lg font-semibold px-4">Auto turn on items</p>
                  <div className="mb-4 ">
                    <label className="flex items-center mb-2 px-4 pb-3 border-b border-gray-300">
                      <input type="radio" name="auto-turn-on" value="option1" className="mr-2" />
                      2 Hours
                    </label>
                    <label className="flex items-center mb-2 px-4 pb-3 border-b border-gray-300">
                      <input type="radio" name="auto-turn-on" value="option2" className="mr-2" />
                      4 Hours
                    </label>
                    <label className="flex items-center mb-2 px-4 pb-3 border-b border-gray-300">
                      <input type="radio" name="auto-turn-on" value="option3" className="mr-2" />
                      Next business day
                    </label>
                    <label className="flex items-center mb-2 px-4 pb-5 border-b-8 border-gray-300">
                      <input type="radio" name="auto-turn-on" value="option4" className="mr-2" />
                      Custom date & time (upto 7 days)
                    </label>
                  </div>
                  <p className="mb-4 text-lg font-semibold px-4">Manually turn on</p>
                  <label className="flex items-center mb-2 px-4 font-medium text-base">
                    <input type="radio" name="auto-turn-on" value="option4" className="mr-2" />
                    I will turn it on myself
                  </label>
                  <p className='px-4 text-gray-500 text-sm pb-4'>this item will not be visible to customer on the Zomato app till you switch on it.</p>
                  <div className='text-center'>
                    <button onClick={closeModal} className="bg-green text-white w-96 mb-4 py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300">CONFIRM</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default DeliveryMenu;
