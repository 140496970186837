import React, { useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './Outlet.css';

const OutletTiming = () => {
  const videoSrc = 'https://www.w3schools.com/html/mov_bbb.mp4';
  const initialSlots = [
    { day: 'Monday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
    { day: 'Tuesday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
    { day: 'Wednesday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
    { day: 'Thursday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
    { day: 'Friday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
    { day: 'Saturday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
    { day: 'Sunday', isOpen: false, timeSlots: [{ startTime: null, endTime: null }] },
  ];

  const [slots, setSlots] = useState(initialSlots);
  const [checked, setChecked] = useState(false);
  const [copyChecked, setCopyChecked] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteSlotInfo, setDeleteSlotInfo] = useState(null);
  const [showCloseOutletPopup, setShowCloseOutletPopup] = useState(false);
  const [currentDayIndex, setCurrentDayIndex] = useState(null);

  const handleDeletePopup = (dayIndex, slotIndex) => {
    setDeleteSlotInfo({ dayIndex, slotIndex });
    setShowDeletePopup(true);
  };

  const confirmDelete = () => {
    if (deleteSlotInfo) {
      deleteTimeSlot(deleteSlotInfo.dayIndex, deleteSlotInfo.slotIndex);
    }
    setShowDeletePopup(false);
    setDeleteSlotInfo(null);
  };
  const handleOutletToggle = (dayIndex, checked) => {
    if (!checked) {
      setCurrentDayIndex(dayIndex);
      setShowCloseOutletPopup(true);
    } else {
      handleToggle(dayIndex);
    }
  };

  const confirmCloseOutlet = () => {
    if (currentDayIndex !== null) {
      handleToggle(currentDayIndex);
    }
    setShowCloseOutletPopup(false);
    setCurrentDayIndex(null);
  };

  const handleAccordionToggle = (index) => {
    const newSlots = [...slots];
    newSlots[index].isOpen = !newSlots[index].isOpen;
    setSlots(newSlots);
  };

  const handleTimeChange = (dayIndex, slotIndex, type, time) => {
    const newSlots = [...slots];
    newSlots[dayIndex].timeSlots[slotIndex][type] = time;
    setSlots(newSlots);
  };

  const addTimeSlot = (dayIndex) => {
    const newSlots = [...slots];
    newSlots[dayIndex].timeSlots.push({ startTime: null, endTime: null });
    setSlots(newSlots);
  };

  const deleteTimeSlot = (dayIndex, slotIndex) => {
    const newSlots = [...slots];
    newSlots[dayIndex].timeSlots.splice(slotIndex, 1);
    setSlots(newSlots);
  };

  const handleSave = (index) => {
    console.log('Saving data for', slots[index].day, ':', slots[index]);
  };

  const handleToggle = () => {
    setChecked(!checked);
  };
  const handleCopyToggle = () => {
    setCopyChecked(!copyChecked);
  };


  return (
    <>
      <div className="flex flex-col md:flex-row border-b-2 border-gray-300">
        <div className="p-4 content-center">
          <video controls className="w-full rounded-md">
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="p-4">
          <h6 className="text-lg mb-3 font-bold font-sans">
            How to update online ordering operational Timings | Grow with Genie
          </h6>
          <p className="text-base font-sans leading-7 text-gray-600">
            Want to change the operational timings for online ordering? Watch
            how you can easily do so using the Restaurant Partner App. Download
            App Android- hhtps:/{" "}
          </p>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 pb-3 p-4">
        <h6 className="text-base font-bold font-sans text-green">
          Genie Delivery
        </h6>
      </div>
      <div className='p-4'>
        <div>
          {slots.map((slot, dayIndex) => (
            <div key={dayIndex} className={`mb-4 pb-2 pt-2 px-5 text-base rounded-md ${slot.isOpen ? 'bg-green' : 'bg-zinc-200'}`}>
              <button className={`p-2 rounded w-full text-left ${slot.isOpen ? 'text-white' : 'text-green'}`} onClick={() => handleAccordionToggle(dayIndex)}>
                {slot.day}
              </button>
              {slot.isOpen && (
                <div className="p-4 bg-white">
                  {slot.timeSlots && slot.timeSlots.map((timeSlot, slotIndex) => (
                    <div key={slotIndex} className="flex items-center space-x-2 mb-2 border-b-2 border-gray-300 pb-4">
                      <div className="flex items-center space-x-2 ">
                        <p className="mt-5 font-bold">Slot {slotIndex + 1}</p>
                        <div>
                          <p className="text-center text-gray-700 font-semibold">Start Time</p>
                          <DatePicker selected={timeSlot.startTime} onChange={(time) => handleTimeChange(dayIndex, slotIndex, 'startTime', time)} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="h:mm aa" timeCaption="Time" placeholderText="Start Time" className="border p-2 rounded w-32 mt-3" />
                        </div>
                        <span className="mt-5">to</span>
                        <div>
                          <p className="text-center text-gray-700 font-semibold">End Time</p>
                          <DatePicker selected={timeSlot.endTime} onChange={(time) => handleTimeChange(dayIndex, slotIndex, 'endTime', time)} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="h:mm aa" timeCaption="Time" placeholderText="End Time" className="border p-2 rounded w-32 mt-3" />
                        </div>
                      </div>
                      <button onClick={() => handleDeletePopup(dayIndex, slotIndex)} className="text-gray-500 mt-5 px-3">
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  ))}
                  <div className="flex justify-end">
                    <button onClick={() => addTimeSlot(dayIndex)} className="text-green mb-4">+ Add time slot</button>
                  </div>
                  <div className="flex items-center space-x-4 mb-4">
                    <input type="checkbox" className="bg-green" checked={copyChecked} onChange={handleCopyToggle} />
                    <span>Copy above timings to all days</span>
                    <label htmlFor="toggle" className="flex items-center space-x-2 cursor-pointer">
                      <input id="toggle" type="checkbox" className="hidden" checked={checked} onChange={(e) => handleOutletToggle(dayIndex, e.target.checked)} />
                      <div className={`relative w-12 h-6 rounded-full shadow-inner ring-1 ring-gray-400 ${checked ? 'bg-green' : 'bg-red'}`}>
                        <div className={`dot absolute w-6 h-6 bg-white rounded-full shadow-md ring-1 ring-gray-400 transition-transform duration-300 ${checked ? 'transform translate-x-6' : 'transform translate-x-0'}`}></div>
                      </div>
                      <span>Outlet Open</span>
                    </label>
                    <div className="flex justify-end">
                      <button onClick={() => handleSave(dayIndex)} className={`px-4 py-2 text-white rounded ${copyChecked ? 'bg-green' : 'bg-gray-300'}`} disabled={!copyChecked}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          {showDeletePopup && (
            <DeletePopup
              onClose={() => setShowDeletePopup(false)}
              onDelete={confirmDelete}
            />
          )}
          {showCloseOutletPopup && (
            <CloseOutletPopup
              onClose={() => setShowCloseOutletPopup(false)}
              onConfirm={confirmCloseOutlet}
              day={slots[currentDayIndex]?.day}
            />
          )}
        </div>
      </div>
    </>
  );
};
const DeletePopup = ({ onClose, onDelete }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white rounded-md text-center w-1/4">
      <FontAwesomeIcon icon={faTrash} className="text-red mb-2 mt-8 text-4xl" />
      <p className="text-xl font-semibold p-5 leading-7 border-b-2 border-gray-300">Deleting all time slots would mean your outlet is closed for entire day. Are you sure you want to keep your outlet closed?</p>

      <div className=" space-x-4">
        <div className=" border-b-2 border-gray-300 p-3">
          <button onClick={onDelete} className="text-blue-500 text-center rounded text-lg font-medium">Delete</button>
        </div>
        <div className="p-3">
          <button onClick={onClose} className="text-gray-600 text-center  rounded text-lg font-medium">Cancel</button>
        </div>
      </div>
    </div>
  </div>
);
const CloseOutletPopup = ({ onClose, onConfirm, day }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white rounded-md w-1/4">
      <h2 className="font-bold p-4 border-b-2 border-gray-300 text-lg">Close outlet?</h2>

      <h3 className="font-semibold px-3 text-lg">Do you want to keep your outlet close on {day}?</h3>
      <p className="text-base px-3 border-b-2 border-gray-300 mt-2">Your restaurant will be closed on every {day} and will not receive any orders.</p>

      <div className="flex justify-end space-x-4 mt-5 px-3 mb-3">
        <button onClick={onClose} className="bg-gray-300 px-4 py-2 rounded">Cancel</button>
        <button onClick={onConfirm} className="bg-green text-white px-4 py-2 rounded">Yes</button>

      </div>
    </div>
  </div>
);


export default OutletTiming;
