import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faEdit, faStar, faTimes, faMapMarkerAlt, faCaretRight, faMicrophone, faIdBadge } from '@fortawesome/free-solid-svg-icons';
import outlet from '../assets/outlet.webp';
import './Outlet.css';
import { useNavigate } from 'react-router-dom';
const Aboutoutlet = () => {
    const [imageSrc, setImageSrc] = useState(outlet);
    const [isEditing, setIsEditing] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const data = {
        restaurantName: "Happy Bites",
        deliveryRating: 4.2,
        address: "123 Main Street, City, Country",
        diningRating: "-",
        deliveryReviewCount: 365,
        mapLocation: "https://www.google.com/maps/search/?api=1&query=123+Main+Street,+City,+Country", // Replace with actual map location
        restaurantId: "123456",
        activeSince: "23 Oct 2020",
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
                setIsEditing(false);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleRejectClick = () => {
        alert("Image has been rejected!");
    };
    const handleReject = () => {
        setShowPopup(true);
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    const navigate = useNavigate();
    const sendOutlet = () => {
        navigate("/outletname")
    }
    return (
        <>
            <div className="relative">
                <img src={imageSrc} alt="Uploaded" className="w-full h-72" />
                <button className="absolute top-0 right-0 m-2 p-2 bg-white text-black border border-gray-300 rounded" onClick={handleEditClick}>Edit Image</button>
                {isEditing && (
                    <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-75">
                        <input type="file" accept="image/*" onChange={handleImageChange} className="mb-4" />
                        <button className="p-2 bg-white text-black border border-gray-300 rounded" onClick={() => setIsEditing(false)}> Close</button>
                    </div>
                )}
                <div className="absolute bottom-0 left-0 right-0 flex justify-between items-center p-2">
                    <label htmlFor="file-upload" className="cursor-pointer  flex flex-col items-center p-2 text-sky-700 bg-white border border-gray-300 rounded">
                        <FontAwesomeIcon icon={faCamera} className="mb-3" />
                        Upload Image
                    </label>
                    <input id="file-upload" type="file" accept="image/*" onChange={handleImageChange} className="hidden" />
                    <button className="p-2 bg-red text-white rounded" onClick={handleRejectClick} >Rejected</button>
                </div>
            </div>
            <div className="grid grid-cols-2 items-center justify-between px-4">
                <div className="flex items-center">
                    <h3 className="text-base font-bold mr-2">{data.restaurantName}</h3>
                    <FontAwesomeIcon icon={faEdit} className="text-sky-700 cursor-pointer mx-3" />
                </div>
                <div className="flex justify-end items-center">
                    <button className="flex items-center bg-green text-white px-1 py-1 rounded mr-2">
                        <FontAwesomeIcon icon={faStar} className="mr-2" />
                        {data.deliveryRating}
                    </button>
                    <span className="text-gray-700 text-sm">{data.deliveryReviewCount} <br /> Delivery Review</span>
                </div>
            </div>
            <div className="grid grid-cols-2 items-center justify-between px-4 mt-1">
                <div className="flex items-center">
                    <p className="text-gray-700 text-sm">{data.address}</p>
                    <FontAwesomeIcon icon={faEdit} className="text-sky-700 cursor-pointer mx-3" />
                </div>
                <div className="flex justify-end items-center ">
                    <button className="flex items-center bg-gray-500 text-white px-1 py-1 rounded mr-2">
                        <FontAwesomeIcon icon={faStar} className="mr-2" />
                        {data.diningRating}
                    </button>
                    <span className="text-gray-700 text-sm">Dining Reviews</span>
                </div>
            </div>

            <button className="px-3 mx-4 mt-2 py-1 bg-red text-white rounded mb-2" onClick={handleReject}>Rejected</button>
            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-slate-900 opacity-90 index">
                    <div className="bg-white p-4 rounded shadow-lg w-96">
                        <div className="flex justify-between items-center mb-4 border-b-2 border-gray-400 pb-2w-full ">
                            <h2 className="text-base font-bold">Cuisine Rejected</h2>
                            <FontAwesomeIcon icon={faTimes} className="text-gray-700 cursor-pointer" onClick={handleClose} />
                        </div>
                        <p className='leading-4'>Comments: Restaurant is not serving the selected cuisine</p>
                    </div>
                </div>
            )}
            <div className='border-gray-300 border-b-2 border-t-2 px-4 pb-3 pt-3'>
                <div className=' flex justify-between mt-2 mb-2'>
                    <div className="">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="text-black text-sm  mr-2" />

                        <span className="text-black text-sm">Address : {data.address}</span><FontAwesomeIcon icon={faEdit} className="text-sky-700 cursor-pointer mx-3" onClick={sendOutlet} />
                    </div>
                    <div className="">
                        <a href={data.mapLocation} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-sm">
                            View On Map
                            <FontAwesomeIcon icon={faCaretRight} className="ml-1 text-sm" />
                        </a>
                    </div>
                </div>
            </div>
            <div className='border-gray-300 border-b-2 px-4'>
                <div className='mt-2 mb-2'>
                    <div className="flex flex-col mt-1">
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faMicrophone} className="text-gray-700 mr-2" />
                            <span className="text-gray-700">Record Pickup instruction (Please allow microphone access)</span>
                        </div>
                        <p className="text-gray-700 mt-2 leading-5 text-xs">Help the delivery partner to reach your location esaily. (e.g take a right from gate no.1). We will show this to each new delivery partner.</p>
                        <a href="https://support.example.com/microphone-access" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline mt-1 text-sm">
                            How to allow Microphone access
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex items-center  mt-3 pb-3 px-4">
                <FontAwesomeIcon icon={faIdBadge} className="text-black mr-2" />
                <span className="text-black">Restaurant ID: {data.restaurantId} | Active since: {data.activeSince}</span>
            </div>
        </>
    )
}

export default Aboutoutlet